<app-navbar></app-navbar>

<!-- All the data into single container start-->
<mat-drawer-container class="example-container h-100">
  <!-- Back to supervisor dashboard button start-->
  <mat-drawer mode="side" opened>
    <div class="row h-100">
      <div class="example-container col-md-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label><b>Grade</b></mat-label>
          <mat-select>
            <mat-option *ngFor="let gradeAvailable of AllGrade_Available" (click)="GradeValue(gradeAvailable)" [value]="gradeAvailable" color="accent">
                {{gradeAvailable}} 
            </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="fill" disabled>
          <mat-label><b>Subject</b></mat-label>
          <mat-select>
            <mat-option *ngFor="let subjectAvailable of AllSubject_Available" (click)="SelectingSubject(subjectAvailable)" [value]="subjectAvailable" color="accent" style="text-align: center;">
                {{subjectAvailable}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-card-actions>
        <button type="button" (click)="Backtodashboard()" class="btn btn-info">Back To Dashboard</button>
      </mat-card-actions>
    </div>
    </div>
  </mat-drawer>
  <!-- Back to supervisor dashboard button End-->


  <mat-drawer-content>
    <!-- <div class="row h-20">
        <div class="col-sm-6 col-md-6">
          <br>  
          <div style="margin-left: 2.33%;">
              <h1>Grade</h1>
          </div>
         <hr>
            <div class="columns">
                <button disabled style="margin-left: 30px;margin-top: 4px;" type="button" *ngFor="let gradeAvailable of AllGrade_Available" class="btn btn-primary">{{gradeAvailable}}</button>
            </div>
        </div>
        <div class="col-sm-6 col-md-6">
          <br>  
          <div style="margin-left: 2.33%;">
              <h1>Subject</h1>
          </div>
         <hr>
            <div class="columns">
                <button disabled style="margin-left: 30px;margin-top: 4px;" type="button" *ngFor="let SubjectAvailable of AllSubject_Available" class="btn btn-secondary">{{SubjectAvailable}}</button>
            </div>
        </div>
    </div> -->
    <div class="row h-50">
      <div class="col-md-12">
        <br>  
      <div style="margin-left: 10%;">
        <h1>Teachers Name and Alloted Grade</h1>
    </div>
     <hr>
     <div *ngFor="let name of TeacherName" style="padding-left: 20px; padding-bottom: 10px;">
      <!-- <button type="button" style="margin-right: 200px;padding-right: 20px;max-width: 20%;width: 20%;" (click)="Teacher_grade_Allotment(gradeallotment,name)" class="btn btn-warning">{{name.Teacher_Name}}</button>
      <button type="button" style="margin-left: 20px;" *ngFor="let grade of name.Grade_Allocated" class="btn btn-success" (click)="Show_Alloted_subject(alloted_subject,grade,name)">{{grade}}</button> -->
      <button title="Click on the teacher's name and allot grade and subject" class="namecontent btn btn-warning" type="button" (click)="Teacher_grade_Allotment(gradeallotment,name)">{{name.Teacher_Name}}</button>
      <button title="Click on button to view alloted grade and subject to the teacher" class="gradecontent btn btn-success" type="button" *ngFor="let grade of name.Grade_Allocated" (click)="Show_Alloted_subject(alloted_subject,grade,name)">{{grade}}</button>
    </div>
      </div>
    </div>
  <!-- <div class="row h-50">
    <div class="col-sm-4 col-md-4">
      <br>  
      <div style="margin-left: 10%;">
        <h1>Teachers Name</h1>
    </div>
     <hr>
     <div *ngFor="let name of TeacherName" style="padding-left: 20px; padding-bottom: 10px;">
      <button type="button" (click)="Teacher_grade_Allotment(gradeallotment,name)" class="btn btn-warning">{{name.Teacher_Name}}</button>
    </div>
    </div>

    <div class="col-md-8">
        <br>  
        <div style="margin-left: 5%;">
          <h1>Allotted Grade Of Teachers</h1>
        </div>
        <hr>
        <div *ngFor="let alotgrade of Grade_Allocated">
          <div *ngFor="let grade of alotgrade" class="inline" style="padding-left: 20px; padding-bottom: 10px;">
              <button type="button" class="btn btn-success" (click)="Show_Alloted_subject(alloted_subject,grade,name)">{{grade}}</button>
               </div>
          </div>
      </div>
    </div> -->
  </mat-drawer-content>
  </mat-drawer-container>

<hr style="margin-bottom: 0;">
<footer class="page-footer font-small bg-dark-gray">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2020 Copyright:
      NerdNerdy.com
    </div>
    <!-- Copyright -->
  </footer>

  <!-- For Allotment of the teacher grade wise Start -->
<ng-template #gradeallotment let-modal> 
  <div class="row">
    <div class="modal-content">
      <div class="modal-header"> 
          <button type="button" class="close"
            aria-label="Close" (click)= 
            "modal.dismiss('Cross click')" class="btn btn-danger"> 
            Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center" style="padding: 1px;">Grade wise allotment of the teacher</h1> -->
      </div>
      <!-- <h1 class="modal-title w-100 text-center" style="padding: 5px;">Teacher Name :- {{Selected_TeacherName}}</h1> -->
          <!-- <h1 class="modal-title w-100 text-center" style="padding: 15px;">Grade wise allotment of the teacher</h1> -->
          <!-- <mat-card>
            <div class="col-sm-12 col-md-12">
              <div style="margin-left: 15%;">
                  <h1>Allotted Grade Of {{Selected_TeacherName}}</h1>
              </div>
                <div class="columns">
                    <button style="margin-left: 30px;" type="button" *ngFor="let alotgrade of Alloted_Grade" class="btn btn-primary">{{alotgrade}}</button>
                </div>
            </div>
          </mat-card> -->
          <mat-card>
                <div class="col-sm-12 col-md-12">
                  <!-- <br>   -->
                  <div style="margin-left: 15%;">
                      <h1>Allot grade and subject to {{Selected_TeacherName}}</h1>
                  </div>
                        <ul class="list-group">
                
                          <!-- Parent Item -->
                          <li class="list-group-item" *ngFor="let gradeAvailable of AllGrade_Available">
                
                            <!-- Parent Checkbox -->
                            <input type="checkbox" value="{{gradeAvailable}}"
                              (change)="parentCheck($event)" [checked]="compGrade_Available_to_Alotted(gradeAvailable)"/>
                            {{gradeAvailable}}
                            <!-- Parent Arrow -->
                            <span (click)="expandCollapse(gradeAvailable)">
                              <i class="fa fa-angle-up" *ngIf="gradeAvailable.isClosed;else isCollapsed"></i>
                              <!-- <i class="fa fa-angle-up" aria-hidden="true"></i> -->
                              <ng-template #isCollapsed>
                                <i class="fas fa-angle-down"></i>
                                <!-- <i class="fa fa-angle-down"></i> -->
                              </ng-template>
                            </span>
                            <div class="child-list" [hidden]="gradeAvailable.isClosed">
                
                              <ul class="list-group level-two">
                                <!-- Child Item -->
                                <li class="list-group-item level-two" *ngFor="let SubjectAvailable of AllSubject_Available">
                                  <!-- Child Checkbox -->
                                  <input type="checkbox" 
                                    value="{{SubjectAvailable}}" (change)="childCheck(gradeAvailable,SubjectAvailable,$event)" 
                                    [checked]="compSubject_Available_to_Alotted(gradeAvailable,SubjectAvailable)"/>
                                  {{SubjectAvailable}}
                                </li>
                
                              </ul>
                
                            </div>
                          </li>
                        </ul>
                      
                </div>
                <!-- <hr> -->
              <mat-card-actions>
                <button (click)="Save_Allotment_Grade();modal.close('grade click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
                <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>Cancel</b></button>
            </mat-card-actions>
          </mat-card>
      </div> 
</div>
</ng-template>
<!-- For Allotment of the teacher grade wise End -->

<!-- For showing the alloted suject Start -->
<ng-template #alloted_subject let-modal>   
  <div class="row">
    <div class="modal-content">
      <div class="modal-header"> 
          <button type="button" class="close"
            aria-label="Close" (click)= 
            "modal.dismiss('Cross click')" class="btn btn-danger"> 
            Close
        </button> 
        
      </div>
          <mat-card>
            <div class="col-sm-12 col-md-12">
              <!-- <br>   -->
              <div style="margin-left: 15%;">
                  <h1>Allotted Grade To {{Selected_TeacherName}}</h1>
              </div>
              <div class="columns">
                    <button style="margin-left: 30px;" type="button" class="btn btn-primary">{{Teacher_Alot_Grade}}</button>
                </div>
             <!-- <hr> -->
            </div>
          </mat-card>
          <mat-card>
                <div class="col-sm-12 col-md-12">
                  <!-- <br>   -->
                  <div style="margin-left: 15%;">
                      <h1>Allotted Subject To {{Selected_TeacherName}}</h1>
                  </div>
                  <div class="columns">
                    <button style="margin-left: 30px;" type="button" *ngFor="let alotsubject of Subject_Allocated" class="btn btn-primary">{{alotsubject}}</button>
                </div>
                </div>
          </mat-card>
      </div> 
</div>
  </ng-template>
<!-- For showing the alloted suject Start -->