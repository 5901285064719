import { Component, OnInit } from '@angular/core';
import { QpDataService } from '../qp-data.service';
import {AuthorizationService} from '../authorization.service';
import { HttpParams } from '@angular/common/http';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';


@Component({
  selector: 'app-teacher-grade-allotment',
  templateUrl: './teacher-grade-allotment.component.html',
  styleUrls: ['./teacher-grade-allotment.component.css']
})
export class TeacherGradeAllotmentComponent implements OnInit {

  Teacher_id = "";
  AllGrade_Available = "";
  TeacherName = "";
  Grade_Allocated = [];
  getting_grade = "";
  Selected_TeacherName = "";
  teacherid = "";
  grade = "";
  AllSubject_Available : any; 
  // Teacher_Grade_Available:any;
  Alloted_Grade:any;
  saveGradevalue : boolean;
  Selection_Grade = [];
  new_array = [];
  Different_ValueArray = [];
  Combination_bothgrade = [];
  unchecked_grade = "";
  grade_delete:any;
  subject:any;
  unchecked_subject = "";
  Selection_Subject = [];

  data: any;
  // Subject_selected:any= {};
  Subject_selected:any= {};
  Alloted_Subject:any;
  Subject_new_array = [];

  Subject_array = [];

  Final_subject_RightJoin:any= {};
  Teacher_Alot_Grade = "";
  getting_subject = "";
  Subject_Allocated = [];
  checkgrade_allot:boolean = true;
  checksubject_allot:boolean = true;
  School_id="";

  constructor(private service:QpDataService, private auth:AuthorizationService,private modalService: NgbModal,private router: Router) 
  { 
  
  }

  ngOnInit(): void {
    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != "")
      {
        // this.Teacher_id = result ;
        this.Teacher_id = result["User"];
        this.School_id = result["school"];
        console.log("Success id",this.Teacher_id,this.School_id);
            let allgrade = new HttpParams();
            allgrade = allgrade.append('School_id', this.School_id);
            this.service.Teacher_Adminget(allgrade).subscribe(response => {
            this.AllGrade_Available = response["Items"]["0"]["Grade_Available"];
            this.AllSubject_Available = response["Items"]["0"]["Subject_Availble"];
            console.log("All Grade/SUbject is coming for superdashboard",this.AllGrade_Available,this.AllSubject_Available);
            });

            // Show all the teacher name related to that school 
            let params = new HttpParams();
            params = params.append('School_id', this.School_id);
            params = params.append('Teacher_id', null);
            params = params.append('Grade', null);
            this.service.getteacherdata(params).subscribe(response => {
            this.TeacherName = response["Items"];
            // this.Grade_Allocated = response["Items"]["Grade_Allocated"];
            for(var i=0;i <= this.TeacherName.length -1 ;i++)
            {
              this.getting_grade = this.TeacherName[i]["Grade_Allocated"];
              // this.getting_subject = this.TeacherName[i]["Subject_Allocated"];
              this.Grade_Allocated.push(this.getting_grade);
              // this.Subject_Allocated.push(this.getting_subject);
              console.log("teacher grade",this.Grade_Allocated,this.getting_grade);
            }
            
            console.log("all teacher name is coming for superdashboard",this.TeacherName,this.Grade_Allocated);
            });  
      }
    });
  }
  
// Show all the teacher name related to that school Start
// A_T_N(Teacher_data)
Teacher_grade_Allotment(gradeallotment,Teacher_data) 
{
  this.modalService.open(gradeallotment);// open(Teacher_data.grade_alloted)
  this.Selected_TeacherName = Teacher_data.Teacher_Name;
  this.teacherid = Teacher_data.Teacher_id;
  this.Alloted_Grade = Teacher_data.Grade_Allocated;
  this.Alloted_Subject = Teacher_data.Subject_Allocated;
  this.Selection_Grade = []; ///extra logic for checking the array is null or not
  //logic for checking the grade is available in teacher data or not End
  console.log("array is null or not",this.Selection_Grade,this.Alloted_Grade,this.teacherid,this.Selected_TeacherName,this.Alloted_Subject);
}
// Show all the teacher name related to that school End

// Save the grade alloted to the teacher Start
Save_Allotment_Grade()
{
  this.Comparision_Different_Array();
    console.log("Checkbox value", this.teacherid,this.Final_subject_RightJoin);
      var post = {"School_id":this.School_id,"Teacher_id":this.teacherid,"grade":this.new_array,
                  "working_on":"grade_allotment","subject":this.Alloted_Subject};
      console.log("insert of post request", post);

      //Step 1 :- for checking the Available subscriptions is there or not by using patch method
      var patch_data = {"School_id":this.School_id,"Teacher_id":this.teacherid,"subject":Object.assign({}, this.Final_subject_RightJoin)};
            console.log("data of patch is coming", patch_data);
      this.service.Teacher_Admin_patch(patch_data).subscribe(response => 
        {
          console.log("patch data is coming for superdashboard",response);
        var alotment = response["alottment"];
        var Requested_subscribtion = response["Requested_subscribtion"];
        console.log("alotment and required length",alotment,Requested_subscribtion);
        //Step 2 :- if grade and subject subscription is avalaible then it will show "allowed" then if condition is used
        if(alotment == "allowed")
        {
          // Step 2.1 :- geting the length of Requested_subscribtion
          var put_request = {"School_id":this.School_id,"Teacher_id":this.teacherid,
          "Requested_subscribtion":Requested_subscribtion,"subject":Object.assign({}, this.Final_subject_RightJoin)};
          console.log("put request data is coming",put_request);
          this.service.Teacher_Admin_put(put_request).subscribe(response => {
            console.log("put data is coming for superdashboard",response);
          });

          this.service.Teacher_LessonScore_Update(post).subscribe(response => {
            console.log("all teacher name is coming for superdashboard",response);
            }); 
            // window.location.reload();

        }
        //Step 3 :- if grade and subject subscription is not avalaible then it will show 
        //"not-allowed" then else condition show error msg
        else if(alotment == "not-allowed")
        {
          alert("Do not having any subscription!!");
        }

      });
      // window.location.reload();
}
// Save the grade alloted to the teacher Start

//Back to dashboard start
Backtodashboard()
{
  this.router.navigate(['/supervisiour-dashboard']);
}
//Back to dashboard End

//Comparision of two array for finding the different value Start
Comparision_Different_Array()
  {         
    let right_difference = this.Selection_Grade.filter(x => ! this.Alloted_Grade.includes(x));

    console.log(" right difference",right_difference);
    this.new_array = this.Alloted_Grade.concat(right_difference);
    // this.Teacher_Grade_Available.concat(right_difference);
    console.log("Both checkbox and grade available data",this.Alloted_Grade,this.new_array);

    console.log("data format of alloted and checkbox",this.Alloted_Subject,this.Subject_selected);
    
       this.Final_subject_RightJoin = [];
       for(var i=0; i<= this.new_array.length -1; i++)
       {
         console.log(this.new_array.length);
        // Case 1: grade Exsist in both Alotted and Selected Subjects 
        
        if((this.new_array[i] in this.Alloted_Subject) && (this.new_array[i] in this.Subject_selected))
        {
          //    Action : Update the array of alotted_subjects
          console.log("key exsist in both");
          
          let subject_RightJoin = this.Subject_selected[this.new_array[i]].filter( subject => ! this.Alloted_Subject[this.new_array[i]].includes(subject));
          
          console.log(subject_RightJoin,"this subject is to be added",typeof subject_RightJoin);
          
          this.Final_subject_RightJoin[this.new_array[i]] = subject_RightJoin;//
          console.log("Final Subject",this.Final_subject_RightJoin);

          this.Alloted_Subject[this.new_array[i]] =this.Alloted_Subject[this.new_array[i]].concat(subject_RightJoin);
          console.log(this.Alloted_Subject, "updated for If condition grade and subject");
        }
        // Case 2 : grade Exsist only in Selected Grade and not in alotted grade
        else if((this.new_array[i] in this.Subject_selected) && !(this.new_array[i] in this.Alloted_Subject)) 
        {
          //    Action : Update the Alotted grade directly
          this.Alloted_Subject[this.new_array[i]] = Object.values(this.Subject_selected[this.new_array[i]]);
          //update the different value of grade and subject
          this.Final_subject_RightJoin[this.new_array[i]] = Object.values(this.Subject_selected[this.new_array[i]]);
          console.log(this.Alloted_Subject, "updated for else condition",this.Final_subject_RightJoin);
        }
        // Case 3 : Key only exsist in in alotted grade -> Action no action required
        // Case 4 : Not in  both no action required
       }
  }
  //Comparision of two array for finding the different value End

  //Show the alloted grade subject start
  Show_Alloted_subject(alloted_subject,grade,name)
  {
    this.modalService.open(alloted_subject); 
    this.Teacher_Alot_Grade = grade;
    this.Selected_TeacherName = name.Teacher_Name;
    this.Alloted_Subject = name.Subject_Allocated;
    console.log("name",this.Selected_TeacherName,this.Alloted_Subject);
    this.Subject_Allocated = [];
    // for(var i=0;i <= this.Selected_TeacherName.length;i++)
    //     {
    //       this.getting_subject = this.TeacherName[i]["Subject_Allocated"];
          // if(this.getting_subject != "undefined" && this.Teacher_Alot_Grade == grade)
          if(this.Teacher_Alot_Grade == grade)
          {
          // let subject_Join = Object.values(this.getting_subject[grade]);
          // this.Subject_Allocated.push(subject_Join);
          this.Subject_Allocated = Object.values(this.Alloted_Subject[grade]);
          console.log("teacher subject",this.Subject_Allocated);
          }
          else
          {
            alert("Subject is not alotted");
          }
      // }
    
    // let subject_Join = Object.values(this.Alloted_Subject[grade]);
    // let subject_Join = Object.values(this.TeacherName["Su"]);
    // console.log("Subject",subject_Join);
  
  }
  //Show the alloted grade subject End


// trail for the checkbox 
//Click event on parent checkbox  
parentCheck(gradeAvailable) {
  if (gradeAvailable.target.checked == true) 
    {
      this.grade = gradeAvailable.target.value;
      this.Selection_Grade.push(this.grade);
      console.log("Check Box grade value",this.Selection_Grade);
      console.log("check box value",this.grade);
    } 
    else if (gradeAvailable.target.checked == false) 
    {
      this.unchecked_grade = gradeAvailable.target.value;
      this.Selection_Grade = this.Selection_Grade.filter(item => item !== this.unchecked_grade);
      console.log("Check Box false condition data",this.unchecked_grade,this.Selection_Grade);

    }

}

//Click event on child checkbox  
childCheck(parentNode_Grade, childNode_Subject, childnode_event) {
  
  console.log("Checkbox is check",parentNode_Grade,childNode_Subject,
  childnode_event.target.checked,childnode_event.target.value,typeof parentNode_Grade,typeof childnode_event.target.value);
  // Step 1:- getting the cehckbox selected grade and subject or checking the both condition is true only
  if(childnode_event.target.checked == true)
  {
    // Step 2:-finding the gradeAvailable lenght 
    // Found a checked node along with parent node
    // create a map array variable to get all the values
    if(parentNode_Grade in this.Subject_selected){
      // parentNode aleardy exsists => push new selected subject into it
      console.log(this.Subject_selected)
      this.Subject_selected[parentNode_Grade].push(childnode_event.target.value); // this.Subject_selected["UKG"],this.Subject_selected.UKG
    }
    else{
      // parent node does not exsist , create and push
      this.Subject_selected[parentNode_Grade] = [];
      console.log(this.Subject_selected, this.Selection_Subject[parentNode_Grade]);
      this.Subject_selected[parentNode_Grade].push(childnode_event.target.value);
    }
    // Create of the format var Final_map = {parentNode  UKG:[childnode_Subject,"Maths"]} ,={UKG:[]}// Object[key
     
  }
  else if (childnode_event.target.checked == false) 
  {
    // Filter out the expression for new unchecked value for map
    // go Final_map[parentNode_Grade].filter(item => item !== event.target.value)

    // remove from the array
    this.Subject_selected[parentNode_Grade] = this.Subject_selected[parentNode_Grade].filter( subject =>
      subject !== childnode_event.target.value);
      console.log("Checkbox Selected and unselected",this.Subject_selected);
  }
  
}
// function chidlCheck(parentCheck){}

//Expand/Collapse event on each parent
expandCollapse(gradeAvailable){
  // gradeAvailable.isClosed = !gradeAvailable.isClosed;
}

// Compare selected teacher's alotted grade with available grade to identify already alotted grades
compGrade_Available_to_Alotted(gradeAvailable){
  return (this.Alloted_Grade.indexOf(gradeAvailable)>-1);
}

// Compare the teacher alloted subject with available subject
compSubject_Available_to_Alotted(gradeAvailable,SubjectAvailable){
  if(this.Alloted_Subject[gradeAvailable]){
    return (this.Alloted_Subject[gradeAvailable].indexOf(SubjectAvailable)>-1);
  }
  return false;
}


}
