import { Component, OnInit } from '@angular/core';
import {Hero} from '../hero';
import { HEROES } from '../mock-heroes';
import {qtype2} from '../hero';
import { QuestionType2 } from '../mock-heroes';
import {qtype3} from '../hero';
import { QuestionType3 } from '../mock-heroes';

@Component({
  selector: 'app-questions-data',
  templateUrl: './questions-data.component.html',
  styleUrls: ['./questions-data.component.css']
})
export class QuestionsDataComponent implements OnInit {
  n = HEROES;
  selectedHero: Hero;
  editable:boolean = false;

  question_type2 = QuestionType2;
  selectedQuestion : qtype2;

  question_type3 = QuestionType3;
  selectedFillQuestion : qtype3;
  
  Question = [];

  editing_question = "";
  constructor() { }

  ngOnInit(): void {
  }

}
