<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="row">
    
    <mat-sidenav-container class="col-md-12">
      <!-- <mat-sidenav #sidenav mode="side" [(opened)]="opened" style="overflow: hidden;"> -->
        <mat-sidenav class="sidenavbaralign" #sidenav mode="side" style="overflow: hidden;">
        <!-- Setup Collapse menu for Question Bank -->
        <div class="row">
        <mat-accordion class="example-headers-align" multi >
          <mat-expansion-panel [expanded]="true">
            <!-- <mat-expansion-panel-header>
              <mat-panel-title>
                Student Report Card
              </mat-panel-title>
            </mat-expansion-panel-header> -->
            <mat-form-field class="col-md-12" appearance="fill">
              <mat-label class="col-md-12">Grade</mat-label>
              <mat-select>
                <mat-option *ngFor="let alloted_grade of Grade_Allocated" (click)="Change_Subject_Acc_Grade(alloted_grade)" [value]="alloted_grade" color="accent">
                    {{alloted_grade}} 
                </mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-12" appearance="fill" disabled>
              <mat-label class="col-md-12">Subject</mat-label>
              <mat-select>
                <mat-option *ngFor="let gradewise_subject of Gradewise_Subject" (click)="SelectingSubject(gradewise_subject)" [value]="gradewise_subject" color="accent" style="text-align: center;">
                    {{gradewise_subject}}
                </mat-option>
            </mat-select>
            </mat-form-field>
            <button class="btn col-md-12" mat-stroked-button (click)="NewResult()" color="Warning">Create New Exam Result</button>
            <mat-form-field class="col-md-12">
              <mat-label class="col-md-12">Previous Exam Results</mat-label>
              <mat-select>
                 <mat-option *ngFor="let card of Reportcard" style="align-content: center;" [value]="card.Subject_id" (click)="changeReportcard(card.Subject_id)">RP-: {{card.Type_Of_Exam}}</mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            
            <button class="btn col-md-12" mat-stroked-button (click)="PreviousStudentResult(contenteidt)" color="Warning">Add Prefilled Data</button>
            <!-- <button class="btn col-md-12" mat-stroked-button color="warn" (click)="DownloadExcel()">Download Reportcard</button>              
            <button class="btn col-md-12" mat-stroked-button (click)="SaveReportcard(reportcardchoose)">Save The Reportcard</button> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      </mat-sidenav>
    
      <mat-sidenav-content class="col-md-12 ">
        <button mat-button color="primary" class="btn-font" (click)="sidenav.toggle();checkSideNavOpen();">
          {{ showsidenavbar ? 'More Options >': '< Less Options' }}
        </button>
        <!-- <button mat-button color="primary" (click)="sidenav.toggle();checkSideNavOpen();"><b>{{button_content}}</b></button> -->
        <div class = "row">
          <div class="col-md-2"></div>
          <div class="col-sm-12 col-md-12">
            <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <!-- used to filter the data which was in the spreadsheet  -->
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-2">
              <mat-card-actions>
                  <button class="btn btn-warning" style="align-content: right;" (click)="DownloadExcel()">Download Reportcard</button>
                </mat-card-actions>
              </div>
              <div class="col-md-3">
                <mat-card-actions>
                    <button class="btn btn-success" (click)="SaveReportcard(reportcardchoose)">Save The Reportcard</button>
                  </mat-card-actions>
                </div>
                <div class="col-md-3">
                  <mat-card-actions>
                    <button class="btn btn-primary" (click)="PreviousStudentResult(contenteidt)">Choose From Previous Result</button>
                    </mat-card-actions>
                  </div> -->
                  <div class="col-md-6">
                  <mat-chip-list aria-label="Fish selection">
                    <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
                    <mat-chip color="accent" selected>{{Subject_Selected}}</mat-chip>
                    <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-top: 5px;">Class Performance Video</button>
                  </mat-chip-list>
                  
                </div>
                
              </div>
              <mat-card class="content-inline">
                <div class="form-row">
                  <mat-label class="col-sm-2 col-form-label">Type Of Exam</mat-label>
                      <input class="form-control col-sm-8" type="text" [(ngModel)]="Selected_Exam_type"/> 
                      &nbsp;
                  <mat-label class="col-sm-2 col-form-label">Subject</mat-label>
                  <input class="form-control col-sm-8" type="text" [(ngModel)]="Selected_Subject"/> 
              </div>
              </mat-card>
              <br>
              
                <div *ngIf="delete_row" style="color: red;">Successfully deleted the row.</div>
                <div *ngIf="add_row" style="color: green;">Successfully add the row.</div>
                <!-- <div *ngIf="delete_column" style="color: red;">Successfully deleted the column.</div> -->
                <div *ngIf="add_column" style="color: green;">Successfully add the column.</div>
                <!-- mat table start -->
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                  <!-- this container will show the row name start from here -->
                    <ng-container matColumnDef=" ">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let row; let rIndex = index;">
              
                          <a class="header-value"> {{header}} </a>
                          <!--Input tag the only show up when editpointer col and row equal to the location of the cell-->
                          <!-- this "delete" button is to delete the Row -->
                          
                          <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
                          <button mat-icon-button (click)="deleteRow(rIndex)">
                            <mat-icon>delete</mat-icon>
                          </button>

                          <!-- this "add" button is to add the Row -->
                          <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
                          <button mat-icon-button (click)="addRow(rIndex)">
                            <mat-icon>add</mat-icon>
                          </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        {{rIndex + 1}}
                      </mat-cell>
                      <!-- used for the header of class average and the average calculation is from mat footer cell-->
                      <!-- <mat-footer-cell *matFooterCellDef style="width: 100%; min-width: 100%;" class="text-center">Class Average </mat-footer-cell> -->
                      <mat-footer-cell *matFooterCellDef style="width: 100%;min-width: 100%;margin-right: -30px;" class="text-center">Class Average </mat-footer-cell>
                    </ng-container>
                    <!-- this container will show the row name End from here -->

                    <!-- this container will show the Column name start from here -->
                    <ng-container *ngFor="let header of displayedColumns  | slice:1:displayedColumns.length; index as cindex" 
                    [cdkColumnDef]="header">
                      <mat-header-cell *matHeaderCellDef>
                        <a class="header-value"> {{header}} </a>

                        <!-- this "delete" button is to delete the column -->
                        
                        <!-- &nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-icon-button (click)="deleteColumn(cindex)">
                          <mat-icon>delete</mat-icon>
                        </button> -->
                        <!-- this "add" button is to add the column -->
                        
                        <!-- &nbsp;&nbsp;&nbsp;&nbsp;
                        <button mat-icon-button (click)="addColumn(cindex)">
                          <mat-icon>add</mat-icon>
                        </button> -->
                        <!-- this "A" button is to calculate the average of the row -->
                        
                      </mat-header-cell>
              
                      <!-- when switch function is called then the value of editpointer and trucker will change accordingly
                      ngClass will change the color of the background of the edited cell-->
                      <mat-cell *cdkCellDef="let element; index as rindex" (click)="switchToInput(rindex, cindex)"
                      [ngClass]="{'activeCell': editor.editPointer.col === cindex && editor.editPointer.row === rindex}">
                          <!-- input and ngIf direacter to switch between to editing mode 
                          if editpointer col and row equal to cindex and rindex than switch to input-->
                          <a  *ngIf="editor.editPointer.col != cindex || editor.editPointer.row != rindex" class="cell-value"> {{element[cindex]}} </a>
                          <input matInput (click)="Average(cindex)" *ngIf="editor.editPointer.col === cindex && editor.editPointer.row === rindex" [(ngModel)]="element[cindex]" #value="ngModel" name="value"> 
                      </mat-cell>
                      <!-- To show the average calculation from this -->
                      <!-- <button class="btn-warning" (click)="Average(cindex)" style="margin-left: 10px;">Average</button> -->
                      <mat-footer-cell *matFooterCellDef> {{ CalculationAverage }} </mat-footer-cell>
                    </ng-container>
                    <!-- this container will show the Column name End from here -->
                    

                    <!-- trail start -->
                    <ng-container matColumnDef="header-row-first-group">
                      <th mat-header-cell *matHeaderCellDef 
                          [style.text-align]="center"
                          [attr.colspan]="1" style="background-color: rgb(41, 43, 155);">
                      </th>
                    </ng-container>
                    
                    <!-- Header row second group -->
                    <!-- <ng-container matColumnDef="header-row-second-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"> <b style="color: white;">Type Of Exam</b> </th>
                    </ng-container> -->

                    <!-- Header row third group -->
                    <ng-container matColumnDef="header-row-third-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"> <b style="color: white;">Student Name</b> </th>
                    </ng-container>

                    <!-- Header row four group -->
                    <!-- <ng-container matColumnDef="header-row-four-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"> <b style="color: white;">Subject</b> </th>
                    </ng-container> -->

                    <!-- Header row five group -->
                    <ng-container matColumnDef="header-row-five-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"> <b style="color: white;">Total Mark</b> </th>
                    </ng-container>

                    <!-- Header row six group -->
                    <ng-container matColumnDef="header-row-six-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"> <b style="color: white;">Mark Obtained</b> </th>
                    </ng-container>

                    <!-- Header row seven group -->
                    <!-- <ng-container matColumnDef="header-row-seven-group">
                      <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="background-color: rgb(41, 43, 155);"></th>
                    </ng-container> -->
                  
                    <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-third-group',
                    'header-row-five-group','header-row-six-group']"></tr>

                    <!-- trail End -->
              
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
                </mat-table>
            <!-- </div> -->
            <!-- mat table End -->
                <br>
            <div class="col-md-12">
              <button class="btn btn-success" style="margin-right: 50px;" (click)="DownloadExcel()">Download Exam Result</button> 
              <button class="btn btn-secondary" (click)="SaveReportcard(reportcardchoose)">Save Exam Result</button>
              <!-- <button class="btn col-md-4" mat-stroked-button color="warn" style="margin-right: 50px;" (click)="DownloadExcel()">Download Reportcard</button>              
              <button class="btn col-md-4" mat-stroked-button (click)="SaveReportcard(reportcardchoose)">Save The Reportcard</button> -->
            </div>

            <!-- Score Table start -->
        <br>
          <div class="row">
          <mat-card>
            <div>
              <div>
                <div class="alert alert-info"><b>Class Performance Table</b></div>
                <div class="table-responsive">
                  <table class="table" bordered="true">
                    <thead>
                      <tr style="height: 40px;">
                        <th class="mat-header-cell" scope="col"><b>Very Superior</b></th>
                        <td class="mat-cell" title="Click to view the very superior student name" (click)="StudentRName(StudentVS)" style="cursor: hand;cursor: pointer;">
                          {{calculationVerySuprior}}
                        </td>
                      </tr>
                      <tr style="height: 40px;">
                        <th class="mat-header-cell" scope="col"><b>Superior</b></th>
                        <td class="mat-cell" title="Click to view the superior student name" (click)="StudentSName(StudentS)" style="cursor: hand;cursor: pointer;">
                          {{calculationSuprior}}
                        </td>
                    </tr>
                    </thead>
                      <tbody>
                        <tr style="height: 40px;">
                            <th class="mat-header-cell" scope="col"><b>Above Average</b></th>
                            <td class="mat-cell">
                              {{calculationAboveAverage}}
                            </td>
                          </tr>
                        <tr style="height: 40px;">
                          <th class="mat-header-cell" scope="col"><b>Average</b></th>
                          <td class="mat-cell">
                            {{calculationAverage}}
                          </td>
                        </tr>
                        <tr style="height: 40px;">
                            <th class="mat-header-cell" scope="col"><b>Below Average</b></th>
                            <td class="mat-cell" title="Click to view the below average student name" (click)="StudentBAName(StudentBA)" style="cursor: hand;cursor: pointer;">
                              {{calculationBelowAverage}}
                            </td>
                          </tr>
                        <tr style="height: 40px;">
                          <th class="mat-header-cell" scope="col"><b>Slow</b></th>
                          <td class="mat-cell" title="Click to view the slow student name" (click)="StudentSlowName(StudentSlow)" style="cursor: hand;cursor: pointer;">
                            {{calculationSlow}}
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <p><b>The Class Performance Table highlights the number of students falling under different performance range.</b></p>
              </div>
            </div>
          </mat-card>
            <!-- Score Table start -->
            &nbsp;&nbsp;&nbsp;&nbsp;
            <!-- Reference Table for checking where the student stand start -->
            <mat-card>
            <div>
              <div>
                <div class="alert alert-info"><b>Reference Table</b></div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr style="height: 40px;width: 40px;">
                        <th *ngFor="let head of headElements" scope="col">{{head}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr mdbTableCol *ngFor="let el of elements" style="height: 40px;width: 40px">
                        <th scope="row">{{el.id}}</th>
                        <td>{{el.Percentage}}</td>
                        <td>{{el.Name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p><b>The Reference Table is given to corelate the data.</b></p>
              </div> 
            </div>
            </mat-card>
            <!-- Reference Table for checking where the student stand start -->
            
            &nbsp;&nbsp;&nbsp;&nbsp;
            <!-- chart of student performance start -->
            
              <mat-card style="margin-top: 2%;">
                 <div class="table-responsive">
                    <!-- Student Performance Overview -->
                    <div class="alert alert-info"><b>Student-Based Class Performance</b></div>
                    <p style="color: black;"><b>The graph highlights the number of students falling under different performance range.</b></p>
                    <p style="margin-left: 20px;font-style:italic;font-size: medium;">Identify high achievers for acceleration.</p>
                    <p style="margin-left: 20px;font-size: medium;font-style: italic;">Identify under achievers to give them support.</p>
                    <br>
                    <chart [type]="type" [data]="data" [options]="options" style="height: 400px;width: 500px"></chart>
                    <br>
                  
              </div>
            </mat-card>

            <!-- <mat-card style="margin-top: 2%;">
              <div class="table-responsive">
                 <div class="alert alert-info"><b>Student Based Class Performance Analysis</b></div>
                 <p style="color: black;"><b>The graph highlights the number of students falling under different performance range.</b></p>
                 <p style="margin-left: 20px;font-style:italic;font-size: medium;">Identify high achievers for acceleration.</p>
                 <p style="margin-left: 20px;font-size: medium;font-style: italic;">Identify under achievers to give them support.</p>
                 <br>
                 <chart [type]="type1" [data]="data1" [options]="options1" style="height: 400px;width: 500px"></chart>
                 <br>
           </div>
         </mat-card> -->
          
         <!-- chart of student performance start -->
          </div>
        </div>
       <div class="col-md-2"></div>
        <div class="col-md-12">
            <hr style="margin-bottom: 0;">
          <footer class="page-footer font-small bg-dark-gray">
              <!-- Copyright -->
              <div class="footer-copyright text-center py-3">© 2020 Copyright:
                NerdNerdy.com
              </div>
              <!-- Copyright -->
            
            </footer>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  <!-- For choosing the value from previous report card Start -->
    <ng-template #contenteidt let-modal> 
      <div class="row"> 
      <div class="modal-content">
        <div class="modal-header"> 
          <button type="button" class="close"
          aria-label="Close" (click)= 
          "modal.dismiss('Cross click')" class="btn btn-danger"> 
          Close
      </button> 
        </div>
      
        <!-- <h1 class="modal-title w-100 text-center" style="padding: 15px;">Choose Data From Previous Report Card</h1> -->
        <h1 class="modal-title w-100 text-center" style="padding: 15px;">Tick the checkbox to add the prefilled data from previous exam results</h1>
        <mat-card>
            <form> 
              <br>
                <mat-form-field appearance="fill">
                  <mat-label class="md-col-8">Student Exam Result</mat-label>
                  <select matNativeControl required (change)="selectChangeHandler($event)">
                      <option [value]="null" style="align-content: center;"> ---- Exam Result ---- </option>
                      <option *ngFor="let card of StudentReportCard" value={{card.Subject_id}} style="align-content: center;">{{card.Type_Of_Exam}}</option>
                  </select>
                </mat-form-field>
              <br>
              <div style="padding-left: 20px; padding-bottom: 10px;">
              <!-- <input type="checkbox"  class="form-check-input" [value]="TypeExamChecked" name="TypeExamChecked" [(ngModel)]="TypeExamChecked"><b>Type Of Exam</b><br> -->
              <input type="checkbox"  class="form-check-input" [value]="StudentNameChecked" name="StudentNameChecked" [(ngModel)]="StudentNameChecked"> <b>Student Name</b><br>
              <!-- <input type="checkbox"  class="form-check-input" [value]="SubjectChecked" name="SubjectChecked" [(ngModel)]="SubjectChecked"> <b>Subject</b><br> -->
              <input type="checkbox"  class="form-check-input" [value]="TotalMarkChecked" name="TotalMarkChecked" [(ngModel)]="TotalMarkChecked"> <b>Total Mark</b><br>
              <input type="checkbox"   class="form-check-input" [value]="MarkObtChecked" name="MarkObtChecked" [(ngModel)]="MarkObtChecked"> <b>Mark Obtained</b><br>
            </div>
            </form> 
            <mat-card-actions>
              <button (click)="AddStudentData();modal.close('Add click')" class="btn btn-success"><b>Add</b></button>&nbsp;&nbsp;
              <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
          </mat-card-actions>
        </mat-card>
        </div> 
      </div>
      </ng-template>
  <!-- For choosing the value from previous report card Start -->

  <!--Very Superior Student Name Start -->
  <ng-template #StudentVS let-modal>   
    <div class="modal-content">
      <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div>
      <h1 class="modal-title w-100 text-center">Very Superior Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentName; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
            <!-- <button class="btn btn-danger" (click) = "modal.dismiss('Cross click')">Close</button> -->
            <!-- <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>Cancel</b></button> -->
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Very Superior Student Name End -->

<!--Superior Student Name Start -->
  <ng-template #StudentS let-modal>   
    <div class="modal-content">
      <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div>
      <h1 class="modal-title w-100 text-center">Superior Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentNameSuperior; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Superior Student Name End -->

<!--Below Average Student Name Start -->
  <ng-template #StudentBA let-modal>   
    <div class="modal-content">
      <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div>
      <h1 class="modal-title w-100 text-center">Below Average Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentNameBA; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Below Average Student Name End -->

<!--Slow Student Name Start -->
  <ng-template #StudentSlow let-modal>   
    <div class="modal-content">
      <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div>
      <h1 class="modal-title w-100 text-center">Slow Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentSlName; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Slow Student Name End -->

<!-- For choosing the value from Grade Start -->
<ng-template #gradecontent let-modal class="modal">   
  <div class="modal-content">
    <div class="modal-header"> 
    <h1 class="modal-title w-100 text-center">Welcome back, {{Teacher_Name}}</h1>
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-info"> 
      Back
  </button> 
    </div>
    <!-- <h2 class="modal-sub-title text-center" style="color:black;">Select a grade and subject for creating respective QuestionBank , Lesson Plans, Courses and more !!</h2> -->
    <mat-card>
        <form> 
          <br>
        <div *ngIf="show_alloted_grade">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted grade/grades</h2>
          <mat-card>
            <div *ngFor="let alloted_grade of Grade_Allocated" class="text-center">
              <mat-card-actions>
                  <button (click)="Change_Subject_Acc_Grade(alloted_grade)" class="btn btn-warning"><b>{{alloted_grade}}</b></button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>
        <div *ngIf="show_gradewise_subject">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted subject/subjects</h2>
          <mat-card>
            <mat-card-actions>
              <!-- <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button> -->
              <label><B>Allotted Grade :-</B></label>&nbsp;&nbsp;
              <button class="btn btn-warning" disabled style="align-content: right;">
                {{Selected_Grade_Lable}}</button>
            </mat-card-actions>
            
            <div *ngFor="let gradewise_subject of Gradewise_Subject">
              <mat-card-actions>
                <label><B>Allotted Subject :-</B></label>&nbsp;&nbsp;
                  <button (click)="SelectingSubject(gradewise_subject);modal.close('Subject click')" class="btn btn-success"><b>{{gradewise_subject}}</b></button>
                  <br>
                  <h4>Click above<img src="assets/arrow.png" height="45" width="auto"> to enter the Student performance.</h4>
                </mat-card-actions>
            </div>
          </mat-card>
          <br>
          <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button>
        </div>
          <br>
        </form> 
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from grade Start -->

<!-- For choosing the value from previous report card Start -->
<ng-template #reportcardchoose let-modal style="align-content: center;">   
  <div class="modal-content">
    <div class="modal-header"> 
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-danger"> 
      Close
  </button> 
    </div>
    <h1 class="modal-title w-100 text-center">Are you sure you want to save in the same Report Card else 
      you want save in a new Report Card</h1>
    <mat-card>
        <mat-card-actions>
          <button (click)="SaveSameReportCard();modal.close('Yes click')" class="btn btn-success"><b>Save in the same Report Card</b></button>&nbsp;&nbsp;
          <button (click)="SaveNewReportCard();modal.dismiss('Cross click')" class="btn btn-danger"><b>Save in the new Report Card</b></button>
      </mat-card-actions>
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from previous report card Start -->

<!-- For view how to use Report Card Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/TeacherPro+++Class+Performance.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>
<!-- For view how to use Report Card End-->


  </div>
