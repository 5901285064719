import { Component, OnInit } from '@angular/core';
import {NgbModal}  from '@ng-bootstrap/ng-bootstrap';  

@Component({
  selector: 'app-jumphats',
  templateUrl: './jumphats.component.html',
  styleUrls: ['./jumphats.component.css']
})
export class JumphatsComponent implements OnInit {

  showiframe : boolean;
  showdiv : boolean;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  // For play the video of how to use Worksheet Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Worksheet End


}
