<app-navbar></app-navbar>
<div class="container-fluid" style="padding-left: 15px; padding-right: 15px;">
  <div class="row">
    
    <mat-sidenav-container class="col-md-12">
      <!-- <mat-sidenav #sidenav mode="side" [(opened)]="opened" style="overflow: hidden;"> -->
        <mat-sidenav class="sidenavbaralign" #sidenav mode="side" style="overflow: hidden;">
        <!-- Setup Collapse menu for Question Bank -->
        <div class="row">
        <mat-accordion class="example-headers-align" multi >
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Question Bank
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="col-md-12" appearance="fill">
              <mat-label class="col-md-12">Grade</mat-label>
              <mat-select>
                <mat-option *ngFor="let alloted_grade of Grade_Allocated" (click)="Change_Subject_Acc_Grade(alloted_grade)" [value]="alloted_grade" color="accent">
                    {{alloted_grade}} 
                </mat-option>
            </mat-select>
              <!-- <mat-select>
                 <mat-option *ngFor="let alloted_grade of Grade_Allocated" style="align-content: center;" [value]="gradeAvailable" (click)="SelectingGrade(gradeAvailable)">{{alloted_grade}}</mat-option>
              </mat-select> -->
            </mat-form-field>
            <mat-form-field class="col-md-12" appearance="fill" disabled>
              <mat-label class="col-md-12">Subject</mat-label>
              <mat-select>
                <mat-option *ngFor="let gradewise_subject of Gradewise_Subject" (click)="SelectingSubject(gradewise_subject)" [value]="gradewise_subject" color="accent" style="text-align: center;">
                    {{gradewise_subject}}
                </mat-option>
            </mat-select>
              <!-- <mat-select>
                 <mat-option *ngFor="let alloted_subject of Subject_Allocated" style="align-content: center;" [value]="alloted_subject" (click)="SelectingSubject(alloted_subject)">{{alloted_subject}}</mat-option>
              </mat-select> -->
            </mat-form-field>
           <br>
           <button class="col-md-12" mat-stroked-button (click)="NewQuestionPaper()" color="Warning">Create a New Question-Paper</button>
           <br>
           <mat-form-field class="col-md-12">
            <mat-label class="col-md-12">Previous Question Paper</mat-label>
            <mat-select>
               <mat-option *ngFor="let paper of Allpaper" style="align-content: center;" [value]="paper.Question_paper_id" (click)="changePaper(paper.Question_paper_id)">QP-: {{paper.Subject}} -- {{paper.Date}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <button class="col-md-12" mat-stroked-button (click)="open(content)" color="primary">Add a New Question</button>
          <button class="btn col-md-12" mat-stroked-button style="color: green;" (click)="Savepaper(paperchoose)">Save a question paper</button>
          <button class="btn col-md-12" mat-stroked-button color="warn" (click)="downloadPDF()">Download Paper in Pdf</button>
          
          <!-- <button class="btn col-md-12" mat-stroked-button color="warn" (click)="downloadWord()">Download Paper in Word File</button> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
        <!-- Button to Download as Pdf -->
        <!-- button to Add New Questions -->
        <!-- Button to generate a question paper based on you stored questions --> 
      </mat-sidenav>
    
      <mat-sidenav-content class="col-md-12 ">
        <button mat-button color="primary" class="btn-font" (click)="sidenav.toggle();checkSideNavOpen();">
          {{ showsidenavbar ? 'More Options >': '< Less Options' }}
        </button>
        <!-- <button mat-button color="primary" (click)="sidenav.toggle();checkSideNavOpen();"><b>{{button_content}}</b></button> -->
        <!-- <div class = "row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
          <mat-card>
            <mat-card-title class="text-left">
              <h1><b>Notes</b></h1>
                <h5>Step 1 :- Click on edit tab and fill the details.</h5>
                <h5>Step 2 :- Update the exam detail.</h5>
                <h5>Step 3 :- Create the questions paper from the given button(Add a New Question).</h5>
            </mat-card-title>
          </mat-card>
        </div>
        <div class="col-md-2"></div>
      </div>
      <br> -->
      <!-- <div class="myDIV">
        <button class="btn-outline-danger">Notes</button>
      </div>
      <div class="hide">
      <div class= "row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
              <mat-card>
                <mat-card-title class="text-left">
                  <h1><b>Notes</b></h1>
                    <h4>Step 1 :- Click on edit tab and fill the details.</h4>
                    <h4>Step 2 :- Save the exam detail.</h4>
                    <h4>Step 3 :- Create the questions paper from the given button(Add a New Question).</h4>
                </mat-card-title>
              </mat-card>
              
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
        <br> -->
        <br><br>
        <mat-chip-list aria-label="Fish selection" class="chip-list">
          <!-- <label><B>Selected Grade & Subject :-</B></label>&nbsp;&nbsp; -->
        <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
        <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
      </mat-chip-list>
        <br>
        <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-top: 5px;">Question paper Video</button>
        <div class = "row">
          <div class="col-md-2"></div>
          <div class="col-sm-12 col-md-8">
             <ul style="list-style-type:none;">
                 <!-- Adding cards to add School Details -->
                 <mat-card>
                    <div *ngIf="Header">
                        <h1 class="text-center"><b>{{SchoolName}}</b></h1>
                        <h3 class="text-center"><b>Class Test: {{Paper_H1}}</b></h3>
                        <h4 class="text-center">Date: {{date}}</h4>
                        <div class="row">
                          <div class="col-md-3 header-text">Maximum Time: {{Max_time}}</div>
                          <div class="col-md-6"></div>
                          <div class="col-md-3 header-text">Max Marks: {{TestMarks}}</div>
                        </div>
                    </div>
                   <!-- Editable -->
                   <div *ngIf= "EditingHeader" class="row">
                     <div class="col-md-12">
                        <input id="Header" class="form-control" placeholder="School Name" value="{{SchoolName}}" type="text" [(ngModel)]="SchoolName" name="schoolName">
                        <!-- <div *ngIf="SchoolName.invalid && (SchoolName.dirty || SchoolName.touched)" class="alert alert-danger">
                          <div *ngIf="SchoolName.errors.required">
                            School Name is required.
                          </div>
                        </div> -->
                        </div>
                        <div class="col-md-12">
                        <input id="SubHeasing" class="form-control" placeholder="Subject Name" value="{{Paper_H1}}" type="text" [(ngModel)]="Paper_H1" name="Paper_H1">
                        </div>
                        <div class="col-md-12">
                          <input id="SubHeasing" class="form-control" placeholder="Date" value="{{date}}" type="text" [(ngModel)]="date" name="date">
                        </div>
                        <div class="col-md-5">
                          <input id="max_time" class="form-control" placeholder="Maximum Time" value="{{Max_time}}" type="text" [(ngModel)]="Max_time" name="Max_time">
                      </div>
                      <div class="col-md-2"></div>
                      <div class="col-md-5">
                          <input id="SubHeasing"class="form-control" placeholder="Maximum Marks" type="text" [(ngModel)]="TestMarks" name="TestMarks">
                      </div>
                </div>
                  <mat-card-footer class="row" style="margin-left: 0;">  
                    <hr style="margin-bottom: 0;">             
                    <mat-card-actions>
                    <button (click)="headerChange()"  class="btn btn-warning"><b>Edit</b></button>&nbsp;
                    <button *ngIf="EditingHeader" (click)="ChangedHeader()" class="btn btn-success"><b>Save</b></button>&nbsp;
                    <button type="button" *ngIf="EditingHeader" (click)="CloseEditingHeader()" class="btn btn-info"><b>Back</b></button> 
                  </mat-card-actions>
                </mat-card-footer>          
              </mat-card>
                 <br>
              <li *ngFor="let ques of Question">
                <!-- Short Answer Type Question -->
                <div *ngIf="ques.qtype == 1">
                  <mat-card>
                    <div class="row">
                      <div class="col-md-9"></div>
                        <mat-card-header class="col-md-3">
                          <mat-card-title class="text-right">{{ques.mark}} mark(s)</mat-card-title>
                        </mat-card-header>
                      </div>
                        <hr style="margin-top: 0;"> 
                          <mat-card-title *ngIf="type1question">
                            <h2>Q) {{ques.question}}</h2>
                          </mat-card-title>
                      <mat-card-footer class="row" style="margin-left: 0;">  
                        <hr style="margin-bottom: 0;">             
                        <mat-card-actions><button (click)="deleter(ques)" class = "btn btn-danger"><b>Delete</b></button>&nbsp;
                        <button (click)="edit(contenteidt,ques)" class = "btn btn-warning"><b>Edit</b></button>&nbsp;
                      </mat-card-actions>
                    </mat-card-footer>
                        <br>
                </mat-card>
                <br>
              </div>
              <!-- Multiple Choice Question -->
            <div *ngIf="ques.qtype == 2">
               <mat-card>
                  <!-- marks -->
                        <div class="row">
                          <div class="col-md-9"></div>
                        <mat-card-header class="col-md-3">
                          <mat-card-title class="text-right">{{ques.mark}} mark(s)</mat-card-title>
                        </mat-card-header>
                      </div>
                        <hr style="margin-top: 0;"> 
                      <mat-card-title *ngIf="type2question">
                        <h2>Q) {{ques.question}}</h2>
                      <h3>a. {{ques.option1}}</h3>
                      <h3>b. {{ques.option2}}</h3>
                      <h3>c. {{ques.option3}}</h3>
                    </mat-card-title>
                      <mat-card-footer class="row" style="margin-left: 0;">  
                        <hr style="margin-bottom: 0;">    
                      <mat-card-actions><button (click)="deleter(ques)" class = "btn btn-danger"><b>Delete</b></button>&nbsp;
                      <button (click)="edit2(contenteidt2,ques)" class = "btn btn-warning"><b>Edit</b></button>&nbsp;
                    </mat-card-actions>
                  </mat-card-footer>
                      <br> 
              </mat-card>
              <br>
            </div>
            <!-- Math the following Types of Question -->
            <div *ngIf="ques.qtype == 3">
              <mat-card>
                <div class="row">
                  <div class="col-md-9"></div>
                <mat-card-header class="col-md-3">
                  <mat-card-title class="text-right">{{ques.mark}} mark(s)</mat-card-title>
                </mat-card-header>
              </div>
                <hr style="margin-top: 0;"> 
                <mat-card-title *ngIf="type3question">
                  <h2>Q) {{ques.question}}</h2>
                  <div class="row">
                    <div class="col-md-6">
                        <h3>a. {{ques.matchop1}}</h3>
                      <h3>b. {{ques.matchop2}}</h3>
                      <h3>c. {{ques.matchop3}}</h3>
                      <h3>d. {{ques.matchop4}}</h3>
                    </div>
                    <div class="col-md-6">
                      <h3> {{ques.matchop5}}</h3>
                      <h3> {{ques.matchop6}}</h3>
                      <h3> {{ques.matchop7}}</h3>
                      <h3> {{ques.matchop8}} </h3>
                    </div>
                  </div>
                </mat-card-title>
              <mat-card-actions>
                  <button (click)="deleter(ques)" class = "btn btn-danger"><b>Delete</b></button>&nbsp;
                  <button (click)="edit3(contenteidt3,ques)" class = "btn btn-warning"><b>Edit</b></button> &nbsp;
              </mat-card-actions>
               <br> 
            </mat-card>
            <br>
          </div>
            </li>
          </ul>
          <!-- Download/ Create questions options -->
          <div class="row">
          <div class="col-lg-4"></div>
                <div class="col-md-4" >
                  
                  <button style="text-align: left;" class="btn btn-lg btn-outline-primary" 
                  (click)="open(content)"> 
                  Add a New Question
              </button> 
                </div>
          <div class="col-lg-4"></div>
                <br>
           </div>
           <br>
           <div class="row">
            <div class="col-lg-4"></div>
                  <div class="col-md-4" >
                <button style="text-align: right;" class="btn btn-lg btn-outline-success" 
                    (click)="Savepaper(paperchoose)"> 
                    Save a question paper
                </button> 
                  </div>
            <div class="col-lg-4"></div>
                  <br>
             </div>
             <br>
           <div class="row">
            <div class="col-lg-4"></div>
                  <div class="col-md-4" >
                <button style="text-align: right;" class="btn btn-lg btn-outline-danger" 
                    (click)="Deletepaper(deletequestionpaper)"> 
                    Delete a question paper
                </button> 
                  </div>
            <div class="col-lg-4"></div>
                  <br>
             </div>
          </div>
          <div class="col-md-2">
            
          </div>
          <div class="col-md-12">
            <hr style="margin-bottom: 0;">
          <footer class="page-footer font-small bg-dark-gray">

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2020 Copyright:
      NerdNerdy.com
    </div>
    <!-- Copyright -->
  </footer>
</div>
</div>
  
      <!-- Accept different types of  question-->
      <ng-template #content let-modal> 
        <div class="modal-content">
          <div class="modal-header"> 
                <button type="button" class="close" style="margin-right: 50%;"
                        aria-label="Close" (click)= 
                        "modal.dismiss('Cross click')" class="btn btn-info"> 
                        Back
                </button> 
                <button type="button" class="close"
                        aria-label="Close" (click)= 
                        "modal.dismiss('Cross click')" class="btn btn-danger"> 
                        Close
                </button> 
            </div>  
                <mat-card>
                  <form> 
                    <mat-card-actions class="text-center">
                    <button type="button" (click)="QuestionTypeInput(2)" class="btn btn-primary">{{buttonNameoption}}</button>
                    <br>
                    <br>
                    <button type="button" (click)="QuestionTypeInput(1)" class="btn btn-primary">{{buttonName}}</button>
                    <br>
                    <br>
                    <button type="button" (click)="QuestionTypeInput(3)" class="btn btn-primary">Match the Following</button>
                  </mat-card-actions>
                    <br>
                    <input type="text" class="form-control" id="mark" style="width: 100%;" *ngIf="markoptain" placeholder="Give marks">
                    <br>
                    <!-- For Multiple type Questions -->
                    <textarea class="form-control" type="text" id="newquestion" *ngIf="TextareaShow" style="width: 100%;" placeholder="Write a question"></textarea>
                    <br>
                    <input type="text" class="form-control" id="newoption1" *ngIf="optiontype1" placeholder="option1">
                    <br>
                    <input type="text" class="form-control" id="newoption2" *ngIf="optiontype2" placeholder="option2">
                    <br>
                    <input type="text" class="form-control" id="newoption3" *ngIf="optiontype3" placeholder="option3">
                    <!-- For Match th following type -->
                    <div class="row">
                      <div class="col-md-6">
                    <input type="text" class="form-control" id="newoption1" *ngIf="matchop1" placeholder="option1" [(ngModel)]="smatchop1" name="matchop1">
                    <br>
                    <input type="text" class="form-control" id="newoption2" *ngIf="matchop2" placeholder="option2" [(ngModel)]="smatchop2" name="matchop2">
                    <br>
                    <input type="text" class="form-control" id="newoption3" *ngIf="matchop3" placeholder="option3" [(ngModel)]="smatchop3" name="matchop3">
                    <br>
                    <input type="text" class="form-control" id="newoption4" *ngIf="matchop4" placeholder="option3" [(ngModel)]="smatchop4" name="matchop4">
                  </div>
                  <div class="col-md-6">
                    <input type="text" class="form-control" id="newoption5" *ngIf="matchop5" placeholder="option1" [(ngModel)]="smatchop5" name="matchop5">
                    <br>
                    <input type="text" class="form-control" id="newoption6" *ngIf="matchop6" placeholder="option2" [(ngModel)]="smatchop6" name="matchop6">
                    <br>
                    <input type="text" class="form-control" id="newoption7" *ngIf="matchop7" placeholder="option3" [(ngModel)]="smatchop7" name="matchop7">
                    <br>
                    <input type="text" class="form-control" id="newoption8" *ngIf="matchop8" placeholder="option3" [(ngModel)]="smatchop8" name="matchop8">
                  </div>
                  </div>
                  </form> 
                  <mat-card-actions>
                  <button (click)="Create();modal.close('Save click')" class = "btn btn-success" *ngIf="showtext"><b>Save</b></button>
                  <button (click)="Createoption();modal.close('Save click')" class = "btn btn-success" *ngIf="showOption"><b>Save</b></button>
                  <button (click)="CreateMatchQtype();modal.close('Save click')" class = "btn btn-success" *ngIf="saveMatchQtype"><b>Save</b></button>
                </mat-card-actions>
          </mat-card>
        </div> 
      </ng-template> 

          <!-- for edit the question of type 1 Start-->
          <ng-template #contenteidt let-modal> 
            <div class="modal-content">
            <div class="modal-header"> 
              <button type="button" class="close"
              aria-label="Close" (click)= 
              "modal.dismiss('Cross click')" class="btn btn-danger"> 
              Close
             </button> 
            </div> 
            <h1 class="modal-title w-100 text-center">Long & Short Question</h1>
            <mat-card>
                <form> 
                  <br>
                  <input id="typemark" value="{{markedit}}" type="text" class="form-control" width="20px">
                  <br>
                  <textarea id="typequestion" class="form-control" type="text" style="height: 200px; width: 100%;">{{editing_question}}</textarea>
                  <br>
                </form> 
                <mat-card-actions>
                  <button (click)="update();modal.close('Update click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
                  <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>Cancel</b></button>
              </mat-card-actions> 
            </mat-card>
          </div>
        </ng-template>
     <!-- for edit the question of type 1 End-->

     <!-- for edit the question of type 2 Start-->
     <ng-template #contenteidt2 let-modal> 
      <div class="modal-content">
      <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
       </button> 
      </div> 
      <h1 class="modal-title w-100 text-center">Option Type Question</h1>
      <mat-card>
          <form> 
            <br>
            <input id="typemark" value="{{markedit}}" type="text" class="form-control" width="20px">
            <br>
            <textarea id="typequestion" class="form-control" type="text" style="height: 200px; width: 100%;">{{editing_question}}</textarea>
            <br>
            <input type="text" class="form-control" id="option1" value="{{optiontype1dit}}">
            <br>
            <input type="text" class="form-control" id="option2" value="{{optiontype2dit}}">
            <br>
            <input type="text" class="form-control" id="option3" value="{{optiontype3dit}}">
          </form> 
          <mat-card-actions>
            <button (click)="update();modal.close('Update click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
            <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>Cancel</b></button>
        </mat-card-actions>
      </mat-card>
      </div>
  </ng-template>
<!-- for edit the question of type 2 End-->

<!-- for edit the question of type 3 Start-->
<ng-template #contenteidt3 let-modal> 
  <div class="modal-content">
  <div class="modal-header"> 
    <button type="button" class="close"
    aria-label="Close" (click)= 
    "modal.dismiss('Cross click')" class="btn btn-danger"> 
    Close
</button> 
  </div> 
  <h1 class="modal-title w-100 text-center">Match the Following</h1>
  <mat-card>
      <form> 
        <br>
        <input id="typemark" value="{{markedit}}" type="text" class="form-control" width="20px">
        <br>
        <textarea id="typequestion" class="form-control" type="text" style="height: 200px; width: 100%;">{{editing_question}}</textarea>
        <br>
        <div class="row">
          <div class="col-md-6">
              <input type="text" class="form-control" id="matchnewoption1" value="{{optionmatchop1}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption2" value="{{optionmatchop2}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption3" value="{{optionmatchop3}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption4" value="{{optionmatchop4}}">
          </div>
          <!-- &nbsp;&nbsp; -->
          <div class="col-md-6">
              <input type="text" class="form-control" id="matchnewoption5" value="{{optionmatchop5}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption6" value="{{optionmatchop6}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption7" value="{{optionmatchop7}}">
              <br>
              <input type="text" class="form-control" id="matchnewoption8" value="{{optionmatchop8}}">
          </div>
      </div>
    </form> 
      <mat-card-actions>
        <button (click)="update();modal.close('Update click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
        <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>Cancel</b></button>
    </mat-card-actions>
  </mat-card> 
</div>
</ng-template>
<!-- for edit the question of type 2 End-->

<!-- For choosing the value from previous report card Start -->
<ng-template #paperchoose let-modal style="align-content: center;">   
  <div class="modal-content">
    <div class="modal-header"> 
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-danger"> 
      Close
  </button> 
    </div>
    <h1 class="modal-title w-100 text-center">Save your question paper in the same file or create a copy</h1>
    <mat-card>
        <mat-card-actions>
          <button (click)="SaveSamePaperData();modal.close('Yes click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
          <button (click)="SaveNewPaperData();modal.dismiss('Cross click')" class="btn btn-danger"><b>Save as a separate copy</b></button>
      </mat-card-actions>
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from previous report card Start -->

 <!-- For choosing the value from Grade Start -->
 <ng-template #gradecontent let-modal class="modal">   
  <div class="modal-content">
    <div class="modal-header"> 
    <h1 class="modal-title w-100 text-center">Welcome back, {{Teacher_Name}}</h1>
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-info"> 
      Back
  </button> 
    </div>
    <!-- <h2 class="modal-sub-title text-center" style="color:black;">Select a grade and subject for creating respective QuestionBank , Lesson Plans, Courses and more !!</h2> -->
    <mat-card>
        <form> 
          <br>
        <div *ngIf="show_alloted_grade">
          <mat-card>
            <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted grade/grades</h2>
            <div *ngFor="let alloted_grade of Grade_Allocated" class="text-center">
              <mat-card-actions>
                  <button (click)="Change_Subject_Acc_Grade(alloted_grade)" class="btn btn-warning"><b>{{alloted_grade}}</b></button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>
        <div *ngIf="show_gradewise_subject">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted subject/subjects</h2>
          <mat-card>
            <mat-card-actions>
              <!-- <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button> -->
              <label><B>Allotted Grade :-</B></label>&nbsp;&nbsp;
              <button class="btn btn-warning" disabled style="align-content: right;">
                {{Selected_Grade_Lable}}</button>
            </mat-card-actions>
            
            <div *ngFor="let gradewise_subject of Gradewise_Subject">
              <mat-card-actions>
                <label><B>Allotted Subject :-</B></label>&nbsp;&nbsp;
                  <button (click)="SelectingSubject(gradewise_subject);modal.close('Subject click')" class="btn btn-success"><b>{{gradewise_subject}}</b></button>
                  <br>
                  <h4>Click above<img src="assets/arrow.png" height="45" width="auto"> to enter the question bank.</h4>
                </mat-card-actions>
            </div>
            
          </mat-card>
        </div>
          <br>
        </form> 
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from grade Start -->

<!-- For Deleting the Question paper Start -->
<ng-template #deletequestionpaper let-modal style="align-content: center;">   
  <div class="modal-content">
    <div class="modal-header"> 
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-danger"> 
      Close
  </button> 
    </div>
    <h1 class="modal-title w-100 text-center">Are you sure you want to delete the question paper.</h1>
    <mat-card>
        <mat-card-actions>
          <button (click)="DeleteQuestionpaper();modal.close('Yes click')" class="btn btn-success"><b>Yes</b></button>&nbsp;&nbsp;
          <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>No</b></button>
      </mat-card-actions>
    </mat-card>
    </div> 
  </ng-template>
<!-- For Deleting the Question paper card Start -->

      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<!-- For view how to use Question paper Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/TeacherPro%20Question%20Paper.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>
<!-- For view how to use Question paper End-->

