import { Component, OnInit, TemplateRef, ViewChild, ElementRef,Injectable,OnChanges } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import * as XLSX from 'xlsx'; // package was installed for the excel file "npm install xlsx."
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap';  
import {  DatePipe  } from '@angular/common';  
import * as fs from 'file-saver';  
import * as moment from 'moment' 
import { Workbook } from 'exceljs'; // package was installed for the excel file "npm install exceljs."
 

//used in the spreadsheet for row and columns start
export let SheetHeader = [ 
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        '],
  +['        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ', '        ']
];
//used in the spreadsheet for row and columns start

@Component({
  selector: 'app-newreportcard',
  templateUrl: './newreportcard.component.html',
  styleUrls: ['./newreportcard.component.css']
})

export class NewreportcardComponent implements OnInit {

  //used in the spreadsheet for naming row and columns start
    displayedColumns: string[] = [' ', 'A', 'B', 'C',];
    displayedColumnsName: string[] = [' ', 'Student Name', 'Total Mark', 'Mark Obtained'];
    dataSource = new MatTableDataSource(SheetHeader);
    
  //used in the spreadsheet for naming row and columns End
  localStorage: Storage;
 
  //used for Calculation of the Score Table start
 CalculationAverage: number=0;
 private sum=0; 
 calculationBelowAverage: number = 0;
  calculationAboveAverage: number = 0;
  calculationVerySuprior: number = 0;
  calculationSuprior: number = 0;
  calculationAverage: number = 0;
  calculationSlow: number = 0;
  //used for Calculation of the Score Table End

  delete_row:boolean= false;
  add_row:boolean= false;
  // delete_column:boolean= false;
  add_column:boolean= false;

  StudentPer = [];
  ExamType = [];
  StudentName = [];
  Subject = [];
  // TotalMark = [];
  // MarkObtained = [];
  TotalMark : any;
  MarkObtained : any;
  Subjectid = "";
  Teacher_id = "";
  SchoolName = "";
  Reportcard = [];
  StudentReportCard = [];
  StudentNameSuperior = [];
  StudentNameBA = [];
  StudentSlName = [];
  SName = [];
  Calculation_mark : any;

  // opened: boolean = false;
  button_content:string = "< Less Options";
  today= new Date().toDateString();
  subject_id = "";
  Selected_Exam_type :any;
  Selected_Subject :any;
  showsidenavbar = true;

  // type = 'line';
  type = 'bar';
  data = {
  // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
  labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
    datasets: [
      {
        label: "Student",
        // data: [" ",this.calculationVerySuprior, this.calculationSuprior, this.calculationAboveAverage, this.calculationBelowAverage, this.calculationAverage, this.calculationSlow],
        data: [" ",this.calculationVerySuprior,this.calculationAboveAverage, this.calculationBelowAverage, this.calculationSlow],
        // data: [0,0,0],
        backgroundColor: 'rgb(10, 14, 214)',
        borderColor: 'rgb(10, 14, 214)',
      }
    ],  
  };

  options = {
  responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero:true,
              max:30,
              stepSize: 3,
          },
          scaleLabel: {
            display: true,
            labelString: 'No. Of Students'
          }
          
      }],
    }
  };

  //used for Reference Table start
  elements: any = [
    {id: 1, Percentage: '95+ Above', Name: 'Very Superior'},
    {id: 2, Percentage: '85 - 95', Name: 'Superior'},
    {id: 3, Percentage: '70 - 85', Name: 'Above Average'},
    {id: 4, Percentage: '50 - 70', Name: 'Average'},
    {id: 5, Percentage: '30 - 50', Name: 'Below Average'},
    {id: 6, Percentage: '30+ Below', Name: 'Slow'},
  ];

  headElements = ['S.NO.', 'Classification Score in %', ' '];
  //used for Reference Table End

  TypeExamChecked : boolean = false;
  StudentNameChecked: boolean = false;
  SubjectChecked: boolean = false; 
  TotalMarkChecked: boolean = false; 
  MarkObtChecked: boolean = false;
  checkbox: boolean = false;
  Teacher_Name = ""; // used in the modal (gradecontent)
 Grade_Allocated = ""; // for getting the grade value in modal (gradecontent)

 Gradewise_Subject = [];
 Subject_Allocated = [];

show_alloted_grade:boolean = true;
show_gradewise_subject:boolean = false;
Selected_Grade_Lable = "";
Subject_Selected = "";

  constructor(private service:QpDataService, private auth:AuthorizationService,private modalService: NgbModal) {}
  
@ViewChild('gradecontent', { static: true }) content: TemplateRef<any>;
  
  ngOnInit(){
    this.deleteColumn(this.displayedColumns.length-1);
    let gradevalue = localStorage.getItem("GradeSource"); // Storing the grade value from local storage.
    let subjectvalue = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Selected_Grade_Lable = localStorage.getItem("GradeSource");
    this.Subject_Selected = localStorage.getItem("SubjectSource");
    this.Selected_Subject = localStorage.getItem("SubjectSource");
    console.log("Choosing grade is coming", gradevalue);
    if(gradevalue != undefined && subjectvalue != undefined)
    {
    let key = this.dataSource.data;
    localStorage.setItem('dataSource', JSON.stringify(key));
    console.log("set the data in local", JSON.stringify(key));
    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != "")
      {
        // this.Teacher_id = result ;
        this.Teacher_id = result["User"];
        this.SchoolName = result["school"];
        console.log("Teacher id & School Name",this.Teacher_id,this.SchoolName);

        let params = new HttpParams();
        params = params.append('Teacher_id', this.Teacher_id);
        params = params.append('Subject_id', null);
        params = params.append('Grade', gradevalue);
        params = params.append('Subject', subjectvalue);
        console.log("data format", params,typeof params);
        this.service.StudentPerformanceget(params).subscribe(response => { 
          console.log("checking the data",response);
          this.Reportcard = response["Items"];
          // this.StudentReportCard = response["Items"];
          // this.dataSource.data = response["Items"];
          // let key1 = this.Reportcard;
          // localStorage.setItem('StudentdataSource', JSON.stringify(key1));
          // console.log("The data of student is coming.", JSON.stringify(key1));
          console.log(typeof this.Reportcard,"paper getting", this.Reportcard,this.dataSource.data);
        });

        // for changing the grade 
        let dropdowngrade = new HttpParams();
        dropdowngrade = dropdowngrade.append('School_id', this.SchoolName);
        dropdowngrade = dropdowngrade.append('Teacher_id', this.Teacher_id);
        this.service.getteacherdata(dropdowngrade).subscribe(response => {
        this.Grade_Allocated = response["Item"]["Grade_Allocated"];
        this.Subject_Allocated = response["Item"]["Subject_Allocated"];
        console.log("all grade is coming in dropdown", this.Grade_Allocated,this.Subject_Allocated);
        });
      }
      (err) => {
        console.log(err);
      }
    });
  }
    else if(gradevalue == null)
    {
      this.auth.getUserAttributes().subscribe(result => {        
        console.log("Success",result);
        if(result != ""){
          this.Teacher_id = result["User"];
          this.SchoolName = result["school"];
          console.log("result of user",this.Teacher_id);
          this.modalService.open(this.content);
          let params = new HttpParams();
          params = params.append('School_id', this.SchoolName);
          params = params.append('Teacher_id', this.Teacher_id);
          this.service.getteacherdata(params).subscribe(response => {
          // this.Teacher_Name = response["Item"]["Teacher_Name"];
          this.Teacher_Name = response["Item"]["Teacher_Name"];
          this.Grade_Allocated = response["Item"]["Grade_Allocated"];
          this.Subject_Allocated = response["Item"]["Subject_Allocated"];
          console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
          });
        }
      });
    }
  }

  //Delete a Column start
  deleteColumn(cindex) {

    // keep the length of columns to loop the data with later
    const columnsLength = this.displayedColumns.length;

    // prevent deleting all headers.
    if ( this.displayedColumns.length === 3 ) //if we change the displaycolumn number then at load time value is 
    //same of dis-column and number then in spread sheet the value we enter give an error. 
    {
      console.log('Cannot delete all headers.');
      // alert('You can not delete the headers.');
      return;
    }
    
    //delete specific header
    this.displayedColumns.splice(cindex + 1, 1);

    // adjust header's names.
    for ( let i = 0; i < this.displayedColumns.length-1; i++ ) {
      this.displayedColumns[i+1] = String.fromCharCode(65 + i);
    }

    let newRow = [];
    const newDataSource = [];
    // loop dataSource and copy every value exept the one in deleted column
    for (let i = 0; i < this.dataSource.data.length; i++) {
      for (let j = 0; j < columnsLength; j++) {
        if (j !== cindex) { //if doesn't equal the deleted header than copy
          newRow.push(this.dataSource.data[i][j]);
        }
      }
      newDataSource.push(newRow);
      newRow = [];
    }

    this.dataSource.data = newDataSource;
    // this.delete_column = true;
  }
  //Delete a Column End

  //Add a Column start
  addColumn(cindex) {

    // add header to displayedColumn
    if ( cindex !== 0 ) {
      this.displayedColumns.splice(cindex, 0, undefined);
    } else { //if first header then we need to make sure we don't delete the header with rows number
      this.displayedColumns.splice(cindex + 1, 0, undefined);
    }

    // adjust header.
    for ( let i = 0; i < this.displayedColumns.length - 1; i++ ) {
      this.displayedColumns[i + 1] = String.fromCharCode(65 + i);
    }

    // insert rows for new header
    let tempColArray = new Array();
    let dataSourceTemp = new Array();
    for (let r = 0; r < this.dataSource.data.length; r++) {
      for (let c = 0; c < this.displayedColumns.length - 1; c++) {
        if (cindex === c) { // if equal new header index then add new cell too
          tempColArray.push('        ');
          tempColArray.push(this.dataSource.data[r][c]);
        } else {
          tempColArray.push(this.dataSource.data[r][c]);
        }
      }
      dataSourceTemp.push(tempColArray);
      tempColArray = new Array();
    }
    this.dataSource.data = dataSourceTemp;
    this.add_column = true;
    this.delete_row = false;
    this.add_row = false;
  }

  //Add a Column End

  //delete a row start
  deleteRow(rIndex) {
    //prevent user from deleting all rows
    if ( this.dataSource.data.length === 1 ) {
      console.log('Cannot delete all rows.' );
      return;
    }

    const tempDataSource = new Array()
    // insert empty cells for new row
    // look for the right row to edit
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (rIndex !== i) { // when not the index if deleted row then copy
        tempDataSource.push(this.dataSource.data[i]);
      }
    }
    this.dataSource.data = tempDataSource;
    this.delete_row = true;
    this.add_column = false;
    this.add_row = false;
  }
  //delete a row End

  //Add a row Start
  addRow(rIndex) {

    const newArr = new Array(this.displayedColumns.length);

    let tempDataSource = new Array()

    // insert empty cells for new row
    // look for the right row to edit
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (rIndex === i) { // if row index equal the index of clicked add button then add new header
        tempDataSource.push(this.dataSource.data[i]);
        tempDataSource.push(newArr);
      } else {
        tempDataSource.push(this.dataSource.data[i]);
      }
    }
    this.dataSource.data = tempDataSource;
    this.add_row = true;
    this.add_column = false;
    this.delete_row = false;

  }

  //Add a row End

  editor = {
    //use to change the switch to input
    editPointer: {
      col: -1,
      row: -1,
    },
  };

  switchToInput(rindex, cindex) {
    console.log(this.dataSource.data);
    this.editor.editPointer.col = cindex;
    this.editor.editPointer.row = rindex;
  }

//Search Start

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}
//Search End

  //Download the excel file start 
  DownloadExcel()
  {  
    var  outputData = []; var i;
    // var header="Student Name"+"\t"+"Total Mark"+"\t"+"Mark Obtained"+"\n";
    for (i in this.dataSource.data){
      outputData.push(this.dataSource.data[i]);
    }

    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(outputData);

    /* generate workbook and add the worksheet */
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    /* save to file */
    // XLSX.writeFile(wb, 'ReportCard.xlsx');
    const titleschoolname = this.SchoolName;
    const title ="Type Of Exam" + ":" + " " + this.Selected_Exam_type;
    const subTitle ="Subject" + ":" + " " + this.Selected_Subject;
    const header = ['Student Name', 'Total Mark', 'Mark Obtained'];  
        // Create workbook and worksheet  
        const workbook = new Workbook();  
        const worksheet = workbook.addWorksheet();  
        // Cell Style : Fill and Header 
        const titleschoolnameRow = worksheet.addRow([titleschoolname]);
        titleschoolnameRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
        titleschoolnameRow.alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.addRow([]);
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 10, bold: true }; 
        titleRow.alignment = { vertical: 'top', horizontal: 'left' };
        const subTitleRow = worksheet.addRow([subTitle]);
        subTitleRow.font = { name: 'Corbel', family: 4, size: 10, bold: true }; 
        subTitleRow.alignment = { vertical: 'top', horizontal: 'left' };
        worksheet.mergeCells('A1:D2');
    // Blank Row
    worksheet.addRow([]);
        var TodayDate = new Date();  
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();  
        var FileName = "StudentReportCard" + MMDDYY;  
        const headerRow = worksheet.addRow(header);  
        headerRow.eachCell((cell, number) => {  
            cell.fill = {  
                type: 'pattern',  
                pattern: 'solid',  
                fgColor: {  
                    argb: 'FFFFFFFF'  
                },  
                bgColor: {  
                    argb: 'FFFFFFFF'  
                },  
            };  
            cell.font = {  
                color: {  
                    argb: '00000000',  
                },  
                bold: true  
            }  
            cell.border = {  
                top: {  
                    style: 'thin'  
                },  
                left: {  
                    style: 'thin'  
                },  
                bottom: {  
                    style: 'thin'  
                },  
                right: {  
                    style: 'thin'  
                }  
            };  
        });  
        outputData.forEach(d => {  
            const row = worksheet.addRow(d);  
            row.fill = {  
                type: 'pattern',  
                pattern: 'solid',  
                fgColor: {  
                    argb: 'FFFFFFFF'  
                }  
            };  
            row.font = {  
                color: {  
                    argb: '00000000',  
                },  
                bold: false  
            }  
            row.eachCell((cell, number) => {  
                cell.border = {  
                    top: {  
                        style: 'thin'  
                    },  
                    left: {  
                        style: 'thin'  
                    },  
                    bottom: {  
                        style: 'thin'  
                    },  
                    right: {  
                        style: 'thin'  
                    }  
                };  
            });  
        });  
        outputData.forEach(column => {
          column.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
        });
        worksheet.getColumn(1).width = 40;  
        worksheet.getColumn(2).width = 20;  
        worksheet.getColumn(3).width = 20;   
        workbook.xlsx.writeBuffer().then((data: any) => {  
            const blob = new Blob([data], {  
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  
            });  
            fs.saveAs(blob, FileName + '.xlsx');  
        });  

  }
  //Download the excel file End

  //Average of a Column start
  Average(cindex) {

    var Count = 0;
    this.CalculationAverage = 0;
    this.sum = 0;
    this.calculationAboveAverage =0;
    this.calculationBelowAverage =0;
    this.calculationVerySuprior =0;
    this.calculationSuprior =0;
    this.calculationAverage =0;
    this.calculationSlow =0;

     //condition start from here with using the loop in it and using try and catch 
    for ( let i = 0; i < this.dataSource.data.length; i++ ) {
      console.log("datasource lenght",this.dataSource.data.length);
      var name = this.dataSource.data[i][cindex];
      var parse = Number.parseInt(name);
      console.log("lenght",name,parse);
      let cindexvalue = this.dataSource.data[i][1];
      localStorage.setItem('index', JSON.stringify(cindexvalue));
      console.log("set the data in local", JSON.stringify(cindexvalue));
      
      
      try {
        //we used if condition because the column it empty or not converted to the number 
        //then it will give the "NaN" value .
        if (parse.toString() != "NaN") 
        {
          //for checking the value of Mark obtained should be smaller then Total mark
         let cindexmarkobtained = this.dataSource.data[i][2];
         console.log("column 1 & 2 value",typeof cindexvalue,typeof cindexmarkobtained);
        if(cindex == "2")
        {
         if(cindexmarkobtained > cindexvalue)
         {
          if(cindex == "2")
          {
              this.sum+= parse;
              Count ++;
              console.log("count",Count);
          }
          let index3 = JSON.parse(localStorage.getItem("index"));
          console.log("index value",index3);
          //used in previous but wrong calculation Start
          // var lenght = this.dataSource.data.length;
          // this.CalculationAverage = Number(this.sum/index3*lenght); //cindex value * total length/count*100
          this.CalculationAverage = Number(this.sum/Count); //cindex value * total length/count*100
          console.log("Average of the Student",this.CalculationAverage,Count);
          //used in previous but wrong calculation end

          // this.CalculationAverage = Number((this.sum/(Count)).toFixed(2)); //cindex value * total length/count*100
          //  console.log("adcre",this.CalculationAverage);
          if(cindex == "2")
          {
            var Calculate = (100/index3);
            // var name2 = Calculate*parse;
            var name2 = Calculate*name;
            console.log("column 4 value", name2);
            
            if(name2 >= 95 && name2 <= 100)
            {
              this.calculationVerySuprior ++; 
            }
            else if(name2 >= 85 && name2 < 95)
              {
                this.calculationSuprior ++;
            }
            else if(name2 >= 70 && name2 < 85)
              {
                this.calculationAboveAverage ++;
            }
            else if(name2 >= 50 && name2 < 70)
              {
                this.calculationAverage ++;
            }
            else if(name2 >= 30 && name2 < 50)
              {
                this.calculationBelowAverage ++;
            }
            else if(name2 <=30)
              {
                this.calculationSlow ++;
            }
          }
        }
        else
        {
          alert("Mark obtained should be less than Total mark.");
        }
      }
   }
    } 
      catch (parse) {
          if (parse == NaN) {
              console.log("abc","Error!");
          }
      }
    }
    
    this.data = {
      // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
      labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
    datasets: [
      {
        label: "Student",
        // data: [" ",this.calculationVerySuprior, this.calculationSuprior, this.calculationAboveAverage, this.calculationBelowAverage, this.calculationAverage, this.calculationSlow],
        data: [" ",this.calculationVerySuprior,this.calculationAboveAverage, this.calculationBelowAverage, this.calculationSlow],
        // data: [0,0,0],
        backgroundColor: 'rgb(10, 14, 214)',
        borderColor: 'rgb(10, 14, 214)',
      }
    ],  
  };
    this.options;
}

  //Average of Column End

  //Save the Report card Start
  SaveReportcard(reportcardchoose)
  {
    if(this.subject_id != "")
    {
      this.modalService.open(reportcardchoose); //showing modal from here
    }
    else if (this.subject_id == "" || this.subject_id == null)
      {
    //for checking the type of exam textbox value should not be null
    console.log("Exam type",this.Selected_Exam_type);
      if(this.Selected_Exam_type != undefined)
      {
        let Grade = localStorage.getItem("GradeSource");
        var Teacher_id = this.Teacher_id;
        var Subject_id = Teacher_id + "_" + Math.floor( Math.random()*100 );
        var Type_Of_Exam = this.Selected_Exam_type;
        var Subject = this.Selected_Subject;
        var newQobj = this.dataSource.data;
        var Date = this.today;
        var StudentAvgPer = {"AboveAverage":this.calculationAboveAverage,"BelowAverage":this.calculationBelowAverage,"VerySuprior":this.calculationVerySuprior,
        "Suprior":this.calculationSuprior,"Average":this.calculationAverage,"Slow":this.calculationSlow,"CalculationAverage": this.CalculationAverage}
        this.StudentPer.push(newQobj,StudentAvgPer);
        var StudentRecord = this.StudentPer;
        
        //Used to find the Student Name
    for ( let i = 0; i < this.dataSource.data.length; i++ ) 
      {
        var name = this.dataSource.data[i];
        this.TotalMark = name[1];
        this.MarkObtained = name[2];
        // let index3 = JSON.parse(localStorage.getItem("index"));
        var Calculate = (100/this.TotalMark);
        var name2 = Calculate*this.MarkObtained;
        console.log("Calculate",Calculate,name2);
    // Very Superior Student Name
        if(name2 >= 95)
        {
          if(this.StudentName.length != this.calculationVerySuprior)
          {
          this.StudentName.push(name[0]); 
          console.log("Student Name", this.StudentName , this.StudentName.length);
          }
        }
        //Superior Student Name
        if(name2 >= 90 && name2 < 95)
          {
            if(this.StudentNameSuperior.length != this.calculationSuprior)
            {
            this.StudentNameSuperior.push(name[0]); 
            console.log("Student Name", this.StudentNameSuperior , this.StudentNameSuperior.length);
            }
          }
      //Below Average Student Name
          if(name2 >= 30 && name2 < 50)
          {
            if(this.StudentNameBA.length != this.calculationBelowAverage)
            {
            this.StudentNameBA.push(name[0]); 
            console.log("Student Name", this.StudentNameBA , this.StudentNameBA.length);
            }
          }
       //Slow Student Name
          if(name2 <=30)
            {
              if(this.StudentSlName.length != this.calculationSlow)
              {
              this.StudentSlName.push(name[0]); 
              console.log("Student Name", this.StudentSlName , this.StudentSlName.length);
              }
            }
      }
      var Very_Superior = this.StudentName;
      var Superior = this.StudentNameSuperior;
      var Below_Average = this.StudentNameBA;
      var Slow = this.StudentSlName;
      console.log("name of the student V.S", Very_Superior,Superior,Below_Average,Slow);
      console.log("Exam Type or Subject", Type_Of_Exam,Subject);
    let post = {Teacher_id,Subject_id,StudentRecord,Date,Grade,Very_Superior,Superior,Below_Average,Slow,Type_Of_Exam,Subject};
        // let post = {Teacher_id,Subject_id,StudentRecord,Date,Grade};
        this.service.StudentPerformancecreate(post).subscribe(() => {
          alert("Record has been added.")
          });

          this.Reportcard_score_update(); // updating the score of Report card in teacher score table
          // this.Ngonchange();

      //for updating the data when we click on save button
    console.log("Subject value",Subject_id);
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', Subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
      
          this.dataSource.data = response["Item"]["Student_Result"][0];
          this.calculationAboveAverage= response["Item"]["Student_Result"][1]["AboveAverage"];
          this.calculationBelowAverage= response["Item"]["Student_Result"][1]["BelowAverage"];
          this.calculationVerySuprior= response["Item"]["Student_Result"][1]["VerySuprior"];
          this.calculationSuprior= response["Item"]["Student_Result"][1]["Suprior"];
          this.calculationAverage= response["Item"]["Student_Result"][1]["Average"];
          this.calculationSlow= response["Item"]["Student_Result"][1]["Slow"];
          this.CalculationAverage= response["Item"]["Student_Result"][1]["CalculationAverage"];
          this.subject_id = response["Item"]["Subject_id"];
          this.Selected_Exam_type = response["Item"]["Type_Of_Exam"];
          this.Selected_Subject = response["Item"]["Subject"];
          this.data = {
            // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
            labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
          datasets: [
            {
              label: "Student",
              // data: [" ",this.calculationVerySuprior, this.calculationSuprior, this.calculationAboveAverage, this.calculationBelowAverage, this.calculationAverage, this.calculationSlow],
              data: [" ",this.calculationVerySuprior,this.calculationAboveAverage, this.calculationBelowAverage, this.calculationSlow],
              // data: [0,0,0],
              backgroundColor: 'rgb(10, 14, 214)',
              borderColor: 'rgb(10, 14, 214)',
            }
          ],  
        };
          this.options;
    });
  }
    else if(this.Selected_Exam_type == undefined)
    {
      alert("Please fill the type of exam");
    }
  }
    
  }

  //Save the Report card End

  //Used in modal for save the report card in same Start
  SaveSameReportCard()
  {
    //for checking the type of exam textbox value should not be null
    if(this.Selected_Exam_type != "")
    {
      let Grade = localStorage.getItem("GradeSource");
      var Teacher_id = this.Teacher_id;
      var Subject_id = this.subject_id;
      var newQobj = this.dataSource.data;
      var Date = this.today;
      var Type_Of_Exam = this.Selected_Exam_type;
      var Subject = this.Selected_Subject;
      var StudentAvgPer = {"AboveAverage":this.calculationAboveAverage,"BelowAverage":this.calculationBelowAverage,"VerySuprior":this.calculationVerySuprior,
      "Suprior":this.calculationSuprior,"Average":this.calculationAverage,"Slow":this.calculationSlow,"CalculationAverage": this.CalculationAverage}
      this.StudentPer.push(newQobj,StudentAvgPer);
      var StudentRecord = this.StudentPer;
      //Used to find the Student Name
    for ( let i = 0; i < this.dataSource.data.length; i++ ) 
      {
        var name = this.dataSource.data[i];
        this.TotalMark = name[1];
        this.MarkObtained = name[2];
        // let index3 = JSON.parse(localStorage.getItem("index"));
        var Calculate = (100/this.TotalMark);
        var name2 = Calculate*this.MarkObtained;
        console.log("Calculate",Calculate,name2);
        if(name2 >= 95)
        {
          if(this.StudentName.length != this.calculationVerySuprior)
          {
          this.StudentName.push(name[0]); 
          console.log("Student Name", this.StudentName , this.StudentName.length);
          }
        }
        //Superior Student Name
        if(name2 >= 90 && name2 < 95)
          {
            if(this.StudentNameSuperior.length != this.calculationSuprior)
            {
            this.StudentNameSuperior.push(name[0]); 
            console.log("Student Name", this.StudentNameSuperior , this.StudentNameSuperior.length);
            }
          }
      //Below Average Student Name
          if(name2 >= 30 && name2 < 50)
          {
            if(this.StudentNameBA.length != this.calculationBelowAverage)
            {
            this.StudentNameBA.push(name[0]); 
            console.log("Student Name", this.StudentNameBA , this.StudentNameBA.length);
            }
          }
       //Slow Student Name
          if(name2 <=30)
            {
              if(this.StudentSlName.length != this.calculationSlow)
              {
              this.StudentSlName.push(name[0]); 
              console.log("Student Name", this.StudentSlName , this.StudentSlName.length);
              }
            }
      }
      var Very_Superior = this.StudentName;
      var Superior = this.StudentNameSuperior;
      var Below_Average = this.StudentNameBA;
      var Slow = this.StudentSlName;
      console.log("name of the student V.S", Very_Superior,Superior,Below_Average,Slow);
    let post = {Teacher_id,Subject_id,StudentRecord,Date,Grade,Very_Superior,Superior,Below_Average,Slow,Type_Of_Exam,Subject};
      // let post = {Teacher_id,Subject_id,StudentRecord,Date,Grade};
      this.service.StudentPerformancecreate(post).subscribe(() => {
        alert("Record has been Updated.")
        });
      }
      else if(this.Selected_Exam_type == "")
      {
        alert("Please fill the type of exam");
      }
  }
  //Used in modal for save the report card in same End

  //Used in modal for save the report card in New Start
  SaveNewReportCard()
  {
    //for checking the type of exam textbox value should not be null
    if(this.Selected_Exam_type != undefined)
    {
    let Grade = localStorage.getItem("GradeSource");
    var Teacher_id = this.Teacher_id;
    var Subject_id = Teacher_id + "_" + Math.floor( Math.random()*100 );
    var newQobj = this.dataSource.data;
    var Date = this.today;
    var Type_Of_Exam = this.Selected_Exam_type;
   var Subject = this.Selected_Subject;
    var StudentAvgPer = {"AboveAverage":this.calculationAboveAverage,"BelowAverage":this.calculationBelowAverage,"VerySuprior":this.calculationVerySuprior,
    "Suprior":this.calculationSuprior,"Average":this.calculationAverage,"Slow":this.calculationSlow,"CalculationAverage": this.CalculationAverage}
    this.StudentPer.push(newQobj,StudentAvgPer);
    var StudentRecord = this.StudentPer;

    //Used to find the Student Name
    for ( let i = 0; i < this.dataSource.data.length; i++ ) 
      {
        var name = this.dataSource.data[i];
        this.TotalMark = name[1];
        this.MarkObtained = name[2];
        console.log("mark obtained value",this.MarkObtained);
        // let index3 = JSON.parse(localStorage.getItem("index"));
        var Calculate = (100/this.TotalMark);
        var name2 = Calculate*this.MarkObtained;
        console.log("Calculate",Calculate,name2);
        if(name2 >= 95)
        {
          if(this.StudentName.length != this.calculationVerySuprior)
          {
          this.StudentName.push(name[0]); 
          console.log("Student Name", this.StudentName , this.StudentName.length);
          }
        }
        //Superior Student Name
        if(name2 >= 90 && name2 < 95)
          {
            if(this.StudentNameSuperior.length != this.calculationSuprior)
            {
            this.StudentNameSuperior.push(name[0]); 
            console.log("Student Name", this.StudentNameSuperior , this.StudentNameSuperior.length);
            }
          }
      //Below Average Student Name
          if(name2 >= 30 && name2 < 50)
          {
            if(this.StudentNameBA.length != this.calculationBelowAverage)
            {
            this.StudentNameBA.push(name[0]); 
            console.log("Student Name", this.StudentNameBA , this.StudentNameBA.length);
            }
          }
       //Slow Student Name
          if(name2 <=30)
            {
              if(this.StudentSlName.length != this.calculationSlow)
              {
              this.StudentSlName.push(name[0]); 
              console.log("Student Name", this.StudentSlName , this.StudentSlName.length);
              }
            }
      }
      var Very_Superior = this.StudentName;
      var Superior = this.StudentNameSuperior;
      var Below_Average = this.StudentNameBA;
      var Slow = this.StudentSlName;
      console.log("name of the student V.S", Very_Superior,Superior,Below_Average,Slow);
    let post = {Teacher_id,Subject_id,StudentRecord,Date,Grade,Very_Superior,Superior,Below_Average,Slow,Type_Of_Exam,Subject};
    this.service.StudentPerformancecreate(post).subscribe(() => {
      alert("Record has been added.")
      });

      this.Reportcard_score_update(); // updating the score of Report card in teacher score table
    }
    else if(this.Selected_Exam_type == "")
    {
      alert("Please fill the type of exam");
    }
  }
  //Used in modal for save the report card in New End

  //update the score in the Report card score table only for Student_performance_scores
  Reportcard_score_update()
  {
    let Grade1 = localStorage.getItem("GradeSource");
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    let Grade = Grade1 +'_'+ storage_grade;
    console.log("Grade_Subject",Grade);

    var Teacher_score = {"School_id":this.SchoolName,"Teacher_id":this.Teacher_id,"working_on":"Student_performance_scores",
    "grade":Grade};
    console.log("data of params",Teacher_score);
    this.service.Teacher_LessonScore_Update(Teacher_score).subscribe(() => {
    });
  }

  // it will side navbar Start
  
  checkSideNavOpen() 
  {
    this.showsidenavbar = !this.showsidenavbar;
  }
  // checkSideNavOpen()
  // {
  //   if(!this.opened)
  //   {
  //     this.button_content = "<Less Options";
  //   }
  //   else
  //   {
  //     this.button_content = "More Options>";
  //   }
  // }
  // it will side navbar End

  // Choosing the Result from dropdown of "Student Report Card" Start
  changeReportcard(Subject_id)
  {
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', Subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
      
          this.dataSource.data = response["Item"]["Student_Result"][0];
          this.calculationAboveAverage= response["Item"]["Student_Result"][1]["AboveAverage"];
          this.calculationBelowAverage= response["Item"]["Student_Result"][1]["BelowAverage"];
          this.calculationVerySuprior= response["Item"]["Student_Result"][1]["VerySuprior"];
          this.calculationSuprior= response["Item"]["Student_Result"][1]["Suprior"];
          this.calculationAverage= response["Item"]["Student_Result"][1]["Average"];
          this.calculationSlow= response["Item"]["Student_Result"][1]["Slow"];
          this.CalculationAverage= response["Item"]["Student_Result"][1]["CalculationAverage"];
          this.subject_id = response["Item"]["Subject_id"];
          this.Selected_Exam_type = response["Item"]["Type_Of_Exam"];
          this.Selected_Subject = response["Item"]["Subject"];
          this.data = {
            // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
            labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
          datasets: [
            {
              label: "Student",
              // data: [" ",this.calculationVerySuprior, this.calculationSuprior, this.calculationAboveAverage, this.calculationBelowAverage, this.calculationAverage, this.calculationSlow],
              data: [" ",this.calculationVerySuprior,this.calculationAboveAverage, this.calculationBelowAverage, this.calculationSlow],
              // data: [0,0,0],
              backgroundColor: 'rgb(10, 14, 214)',
              borderColor: 'rgb(10, 14, 214)',
            }
          ],  
        };
          this.options;
    });
    this.StudentName.length = 0;
    this.StudentNameSuperior.length = 0;
    this.StudentNameBA.length = 0;
    this.StudentSlName.length = 0;
  }
  // Choosing the Result from dropdown of "Student Report Card" End

  PreviousStudentResult(contenteidt)
  {
    this.modalService.open(contenteidt);

    let gradevalue = localStorage.getItem("GradeSource"); // Storing the grade value from local storage.
    let subjectvalue = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', null);
    params = params.append('Grade', gradevalue);
    params = params.append('Subject', subjectvalue);
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      { 
        console.log("checking the data",response);
        this.StudentReportCard = response["Items"];
        console.log("working",this.StudentReportCard);
      });
  }

// Very Superior Student Name Modal Start
  StudentRName(StudentVS)
  {
    this.modalService.open(StudentVS);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
              for ( let i = 0; i < this.dataSource.data.length; i++ ) 
              {
                var name = this.dataSource.data[i];
                this.TotalMark = name[1];
                this.MarkObtained = name[2];
                // let index3 = JSON.parse(localStorage.getItem("index"));
                var Calculate = (100/this.TotalMark);
                var name2 = Calculate*this.MarkObtained;
                console.log("Calculate",Calculate,name2);
                if(name2 >= 95)
                {
                  if(this.StudentName.length != this.calculationVerySuprior)
                  {
                  this.StudentName.push(name[0]); 
                  console.log("Student Name", this.StudentName , this.StudentName.length);
                  }
                }
              }
      });
  }
  // Very Superior Student Name Modal End

// Superior Student Name Modal Start
  StudentSName(StudentS)
  {
    this.modalService.open(StudentS);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
              for ( let i = 0; i < this.dataSource.data.length; i++ ) 
              {
                var name = this.dataSource.data[i];
                this.TotalMark = name[1];
                this.MarkObtained = name[2];
                // let index3 = JSON.parse(localStorage.getItem("index"));
                var Calculate = (100/this.TotalMark);
                var name2 = Calculate*this.MarkObtained;
                console.log("Calculate",Calculate,name2);
                if(name2 >= 90 && name2 < 95)
                {
                  if(this.StudentNameSuperior.length != this.calculationSuprior)
                  {
                  this.StudentNameSuperior.push(name[0]); 
                  console.log("Student Name", this.StudentNameSuperior , this.StudentNameSuperior.length);
                  }
                }
              }
      });
  }
  // Superior Student Name Modal End

  // Below Average Student Name Modal Start
  StudentBAName(StudentBA)
  {
    this.modalService.open(StudentBA);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
              for ( let i = 0; i < this.dataSource.data.length; i++ ) 
              {
                var name = this.dataSource.data[i];
                this.TotalMark = name[1];
                this.MarkObtained = name[2];
                // let index3 = JSON.parse(localStorage.getItem("index"));
                var Calculate = (100/this.TotalMark);
                var name2 = Calculate*this.MarkObtained;
                console.log("Calculate",Calculate,name2);
                if(name2 >= 30 && name2 < 50)
                {
                  if(this.StudentNameBA.length != this.calculationBelowAverage)
                  {
                  this.StudentNameBA.push(name[0]); 
                  console.log("Student Name", this.StudentNameBA , this.StudentNameBA.length);
                  }
                }
              }
      });
  }
  // Below Average Student Name Modal End

  // Slow Student Name Modal Start
  StudentSlowName(StudentSlow)
  {
    this.modalService.open(StudentSlow);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
              for ( let i = 0; i < this.dataSource.data.length; i++ ) 
              {
                var name = this.dataSource.data[i];
                this.TotalMark = name[1];
                this.MarkObtained = name[2];
                // let index3 = JSON.parse(localStorage.getItem("index"));
                var Calculate = (100/this.TotalMark);
                var name2 = Calculate*this.MarkObtained;
                console.log("Calculate",Calculate,name2);
                if(name2 <=30)
                {
                  if(this.StudentSlName.length != this.calculationSlow)
                  {
                  this.StudentSlName.push(name[0]); 
                  console.log("Student Name", this.StudentSlName , this.StudentSlName.length);
                  }
                }
              }
      });
  }
// Slow Student Name Modal End


  changeReportcardValue()
  {

  }

  selectChangeHandler (event: any) 
  {
    this.subject_id = event.target.value;
  }

  // From here we can create a new report card Start 
  NewResult()
  {
      let data = JSON.parse(localStorage.getItem("dataSource"));
      let subjectvalue = localStorage.getItem("SubjectSource");
      console.log("Local storage Subject value",subjectvalue);
      this.Selected_Subject = subjectvalue ;
      this.dataSource.data = data; // If the data in report card then Clear the data and make new report card 
      console.log("Local Storage data", data,this.Reportcard);
      this.calculationAboveAverage = 0;
      this.calculationBelowAverage = 0;
      this.calculationVerySuprior = 0;
      this.calculationSuprior = 0;
      this.calculationAverage = 0;
      this.calculationSlow = 0;
      this.CalculationAverage= 0;
      this.Selected_Exam_type = "";
      // this.Selected_Subject = "";

      this.data = {
        // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
        labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          label: "Student",
          data: [" ",0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgb(10, 14, 214)',
          borderColor: 'rgb(10, 14, 214)',
        }
      ],  
    };
      this.options;
  }
// From here we can create a new report card End 


  //From the modal of Previous Report Card Choose the Student Name , Exam type according to your need Start 
  AddStudentData()
  {
    
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
        this.StudentReportCard = response["Item"]["Student_Result"][0];
        this.Selected_Exam_type = response["Item"]["Type_Of_Exam"];
        this.Selected_Subject = response["Item"]["Subject"];
        // Step 1 find the checkbox is checked or no
        
              this.dataSource.data = this.StudentReportCard ;
              console.log(this.dataSource.data,this.dataSource.data[0][1]);

              // looop throught matrix
              for(var i = 0; i<= this.dataSource.data.length -1 ; i++)
              {
                for( var j= 0; j<= this.dataSource.data["0"].length-1 ; j++)
                {
        
                  // if checkbox value == true
                  // if( this.TypeExamChecked == true){
                  //   // Not removing Exam type
                  //   if(j==0){
                  //     j++;
                  //   }
                  // }
                  if( this.StudentNameChecked == true){
                    // Not removing Student Name
                    if(j==0){
                      j++;
                    }
                  }
                  // if( this.SubjectChecked == true){
                  //   // Not removing Subject Name
                  //   if(j==2){
                  //     j++;
                  //   }
                  // }
                  if( this.TotalMarkChecked == true){
                    // Not removing Total Mark
                    if(j==1){
                      j++;
                    }
                  }
                  if( this.MarkObtChecked == true){
                    // Not removing Mark Obtained
                    if(j==2){
                      j++;
                    }
                  }
                  
                  this.dataSource.data[i][j]="";

            }
        }
    console.log("Checkbox is check or not", this.TypeExamChecked,this.StudentNameChecked,this.SubjectChecked,
    this.TotalMarkChecked,this.MarkObtChecked);        
  });
  this.subject_id = "";
}
//From the modal of Previous Report Card Choose the Student Name , Exam type according to your need End

//trail for changing the subject according to grade Start
Change_Subject_Acc_Grade(alloted_grade:any)
{
  this.Gradewise_Subject = [];  // null the value of subject when grade is change
  this.Reportcard = []; // null the value of Report card coming when grade is change
  this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
  console.log(this.Gradewise_Subject,"grade wise subject is coming");
  let grade = alloted_grade;
  this.Selected_Grade_Lable = alloted_grade ;
  localStorage.setItem('GradeSource', grade);
  console.log("set the data in local", grade);
  this.show_alloted_grade = !this.show_alloted_grade;
  this.show_gradewise_subject = true;
  this.Subject_Selected = "";
}
//trail for changing the subject according to grade End

//Changing the Paper of previous in the dropdown Start
SelectingSubject(Subject: any)
{
    let subject = Subject;
    this.Subject_Selected = Subject ;
    localStorage.setItem('SubjectSource', subject);
    this.Selected_Subject = localStorage.getItem("SubjectSource");
    let gradevalue = localStorage.getItem("GradeSource");
    console.log("set the data in local", subject ,gradevalue,this.Selected_Subject);
  if(gradevalue != null && subject != null )
  {
       let subjectvalue = localStorage.getItem("SubjectSource");
       let params = new HttpParams();
        params = params.append('Teacher_id', this.Teacher_id);
        params = params.append('Subject_id', null);
        params = params.append('Grade', gradevalue);
        params = params.append('Subject', subject);
        console.log("data format", params,typeof params);
        this.service.StudentPerformanceget(params).subscribe(response => { 
          console.log("checking the data",response);
          this.Reportcard = response["Items"];
          console.log(typeof this.Reportcard,"paper getting", this.Reportcard,this.dataSource.data);
    });
    let data = JSON.parse(localStorage.getItem("dataSource"));
      this.dataSource.data = data;
      console.log("Local Storage data", data,this.Reportcard);
      this.calculationAboveAverage = 0;
      this.calculationBelowAverage = 0;
      this.calculationVerySuprior = 0;
      this.calculationSuprior = 0;
      this.calculationAverage = 0;
      this.calculationSlow = 0;
      this.CalculationAverage= 0;
      this.Selected_Exam_type = "";
      // this.Selected_Subject = "";

      this.data = {
        // labels: [" ","Very Superior", "Superior", "Above Average", "Below Average", "Average", "Slow Learner"],
        labels: [" ","VerySuperior","Above Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          label: "Student",
          data: ["",0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgb(10, 14, 214)',
          borderColor: 'rgb(10, 14, 214)',
        }
      ],  
    };
  }
}
//Changing the Paper of previous in the dropdown End

//Back to grade
Backto_Grade_Allocated()
{
  this.show_alloted_grade = true;
  this.show_gradewise_subject = false;
}

// For play the video of how to use Report Card Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Report Card End

}