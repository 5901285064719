import { Component, OnInit , Input , EventEmitter,TemplateRef ,ViewChild} from '@angular/core';
import { LpDataexService } from 'src/app/lp-dataex.service';
import {formatDate } from '@angular/common';
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';
import { mixinColor } from '@angular/material/core';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap'; 

@Component({
  selector: 'app-resourcelink',
  templateUrl: './resourcelink.component.html',
  styleUrls: ['./resourcelink.component.css']
})
export class ResourcelinkComponent implements OnInit {

  public RsouceVideoLink = [];

  videoLink = "";
  selectedAcitivity :any;
  topicid = "";
  subtopicid = "";
  education = "";
  topic_value :any;
  // today: number = Date.now();
  today= new Date().toDateString();
  Lessonplan_id = "";
  Lessonplan = [];
  Teacher_id = "";
  editable1:boolean = false;
  LessonActivity = [];
  showModal : boolean;
  localStorage: Storage;
  activityedit = "";
  todaydate: number = Date.now();

  Teacher_Name = ""; // used in the modal (gradecontent)
  Grade_Allocated = ""; // for getting the grade value in modal (gradecontent)

  Gradewise_Subject = [];
  Subject_Allocated = [];

  show_alloted_grade:boolean = true;
  show_gradewise_subject:boolean = false;
  Selected_Grade_Lable = "";
  Selected_Grade = "";
  Selected_Subject = "";
  School_id="";

  constructor(private dataService:LpDataexService,private service:QpDataService, private auth:AuthorizationService,private modalService: NgbModal) { }

  @ViewChild('gradecontent', { static: true }) content: TemplateRef<any>;

  ngOnInit(): void {
    this.dataService.$sharedValue.subscribe(result =>{
      console.log("Pushing activity working", result);
      if(result != "activity"){
      var LPobj = JSON.parse(result);
      console.log("data is coming",LPobj);
      this.videoLink = LPobj.video;
      this.selectedAcitivity = LPobj.activity;
      this.topicid = LPobj.topic;
      this.subtopicid = LPobj.subtopic;
      this.topic_value = LPobj.topic_value;
      this.education = LPobj.education;
      this.Selected_Grade = LPobj.grade;
      this.Selected_Subject = LPobj.subject;
      console.log(typeof this.topic_value);
      console.log("data from subject wise",this.selectedAcitivity,this.Selected_Grade,this.Selected_Subject);
      console.log("video",this.videoLink);
    }
    });
    // let Previousgradevalue = localStorage.getItem("GradeSource");
    // let storage_subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    // console.log("Choosing grade is coming", Previousgradevalue,storage_subject);
      if(this.Selected_Grade != undefined)
      {
    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != "")
      {
            // this.Teacher_id = result ;
            this.Teacher_id = result["User"];
            this.School_id = result["school"];
            console.log("Success id",this.Teacher_id,this.School_id);
            let params = new HttpParams();      
            params = params.append('Teacher_id', this.Teacher_id);
            params = params.append('Grade', this.Selected_Grade);
            params = params.append('Subject', this.Selected_Subject);
            this.service.Lessonplanget(params).subscribe(response => {
            this.Lessonplan_id = response["Items"][0]["Lesson_plan_id"];
            this.Lessonplan = response["Items"][0]["Teacher_Lesson_Plan"];
            console.log("lesson id", this.Lessonplan_id);
          });
          let params1 = new HttpParams();
          params1 = params1.append('School_id', this.School_id);
          params1 = params1.append('Teacher_id', this.Teacher_id);
          this.service.getteacherdata(params1).subscribe(response => {
          this.Teacher_Name = response["Item"]["Teacher_Name"];
          this.Grade_Allocated = response["Item"]["Grade_Allocated"];
          this.Subject_Allocated = response["Item"]["Subject_Allocated"];
          console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
          });
        }
        (err) => {
          console.log(err);
        }
      });
    }
    else if(this.Selected_Grade == null)
      {
        this.auth.getUserAttributes().subscribe(result => {        
          console.log("Success",result);
          if(result != ""){
            this.Teacher_id = result["User"];
            this.School_id = result["school"];
            console.log("result of user",this.Teacher_id);
            this.modalService.open(this.content);
            let params = new HttpParams();
            params = params.append('School_id', this.School_id);
            params = params.append('Teacher_id', this.Teacher_id);
            this.service.getteacherdata(params).subscribe(response => {
            // this.Teacher_Name = response["Item"]["Teacher_Name"];
            this.Teacher_Name = response["Item"]["Teacher_Name"];
            this.Grade_Allocated = response["Item"]["Grade_Allocated"];
            this.Subject_Allocated = response["Item"]["Subject_Allocated"];
            console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
            });
          }
        });
      }
  }

  AddLessonPlan(contenteidt,act) 
  { 
        this.modalService.open(contenteidt); 
        this.activityedit = act;
  }

  onConfirm(act): void 
  {
    // Close the dialog, return true
    if(this.Lessonplan_id != "")
      {
        // let Grade = localStorage.getItem("GradeSource"); // Storing the previous grade value from local storage.
        // let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
        let Grade = this.Selected_Grade;
        let Subject = this.Selected_Subject; 
        
          var date = this.today;
          var Teacher_id = this.Teacher_id;
          var Lesson_plan_id = this.Lessonplan_id;
        
          var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":"TQ-123","category":this.topic_value,"subcat": this.subtopicid,
        "education":this.education,"activity":act,"date":date};
        this.Lessonplan.push(newQobj);
        var Lessonplan = this.Lessonplan;
        let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
        this.service.Lessonplancreate(post).subscribe(() => {
          alert("Activity has been added to your Lesson Plan.")
          });
          console.log("Resource Activity with grade", post);
        }

    else if (this.Lessonplan_id == "" || this.Lessonplan_id == null)
      {
        // let Grade = localStorage.getItem("GradeSource"); // Storing the previous grade value from local storage.
        // let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
        let Grade = this.Selected_Grade;
        let Subject = this.Selected_Subject; 
        var date = this.today;
        var Teacher_id = this.Teacher_id;
        var Lesson_plan_id = Teacher_id + "_" + this.todaydate;
      
        var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":"TQ-" + Teacher_id,"category":this.topic_value,"subcat": this.subtopicid,
      "education":this.education,"activity":act,"date":date};
      this.Lessonplan.push(newQobj);
      var Lessonplan = this.Lessonplan;
      let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
      this.service.Lessonplancreate(post).subscribe(() => {
        alert("Activity has been added to your Lesson Plan.")
        });
        console.log("Resource Activity for new", post);
      }
      this.showModal = false;
  }

  onDismiss(): void {
      // Close the dialog, return false
      this.showModal = false;
  }

  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

  //update Lesson-Plan for all type Start
  updateLessonPlan()
  {
    if(this.Lessonplan_id != "")
      {
        var textcontrol= ((document.getElementById("Lactivity") as HTMLInputElement).value);
          for ( let i = 0; i < this.Lessonplan.length; i++ ) 
              {
                    if(this.Lessonplan[i]["activity"] == textcontrol)
                    {
                      this.showModal = true; // Show-Hide Modal Check
                      console.log("activity is there", "hii");
                    }   
              }
              if(this.showModal != true)
              {
                // let Grade = localStorage.getItem("GradeSource"); // Storing the previous grade value from local storage.
                // let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
                let Grade = this.Selected_Grade;
                let Subject = this.Selected_Subject; 
                var date = this.today;
                var Teacher_id = this.Teacher_id;
                var Lesson_plan_id = this.Lessonplan_id;
              
                var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":"TQ-123","category":this.topic_value,"subcat": this.subtopicid,
              "education":this.education,"activity":textcontrol,"date":date};
              this.Lessonplan.push(newQobj);
              var Lessonplan = this.Lessonplan;
              let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
              this.service.Lessonplancreate(post).subscribe(() => {
                alert("Activity has been added to your Lesson Plan.")
                });

              }
      }
      else if (this.Lessonplan_id == "" || this.Lessonplan_id == null)
      {
        // let Grade = localStorage.getItem("GradeSource"); // Storing the previous grade value from local storage.
        // let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
        let Grade = this.Selected_Grade;
        let Subject = this.Selected_Subject; 
        var date = this.today;
        var Teacher_id = this.Teacher_id;
        var Lesson_plan_id = Teacher_id + "_" + this.todaydate;
      
        var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":"TQ-" + Teacher_id,"category":this.topic_value,"subcat": this.subtopicid,
      "education":this.education,"activity":textcontrol,"date":date};
      this.Lessonplan.push(newQobj);
    var Lessonplan = this.Lessonplan;
      let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
      this.service.Lessonplancreate(post).subscribe(() => {
        alert("Activity has been added to your Lesson Plan.")
        });

      }
    
}
//update Lesson-Plan for all type End

//for modal changing the grade 
open(content) 
  { 
    this.modalService.open(content);
    let params = new HttpParams();
    params = params.append('School_id', this.School_id);
    params = params.append('Teacher_id', this.Teacher_id);
    this.service.getteacherdata(params).subscribe(response => {
    this.Teacher_Name = response["Item"]["Teacher_Name"];
    this.Grade_Allocated = response["Item"]["Grade_Allocated"];
    console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated);
    });
  }

  //trail for changing the subject according to grade Start
Change_Subject_Acc_Grade(alloted_grade:any)
{
  this.Gradewise_Subject = [];
  console.log("subject data", this.Subject_Allocated);
  this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
  console.log(this.Gradewise_Subject,"grade wise subject is coming");
  let grade = alloted_grade;
  this.Selected_Grade_Lable = alloted_grade ;
  localStorage.setItem('GradeSource', grade);
  console.log("set the data in local", grade);
  this.show_alloted_grade = !this.show_alloted_grade;
  this.show_gradewise_subject = true;
}
//trail for changing the subject according to grade End

SelectingSubject(Subject: any)
{
  let subject = Subject;
  localStorage.setItem('SubjectSource', subject);
  // Storing the previous grade value from dashboard local storage.
  let gradevalue = localStorage.getItem("GradeSource"); 
  
  if(gradevalue != null && subject != null)
  {  
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Grade', gradevalue);
    params = params.append('Subject', subject);
    this.service.Lessonplanget(params).subscribe(response => 
    {
    this.Lessonplan_id = response["Items"][0]["Lesson_plan_id"];
    this.Lessonplan = response["Items"][0]["Teacher_Lesson_Plan"];
    console.log("set the Lesson Plan in local", this.Lessonplan);
    });
    }
}

//Back to grade
Backto_Grade_Allocated()
{
  this.show_alloted_grade = true;
  this.show_gradewise_subject = false;
}

}

