import { Component, OnInit , Input , EventEmitter ,TemplateRef ,ViewChild, ElementRef} from '@angular/core';
import { Lesson } from '../lessonplanning';
import { CATEGORY } from '../lessonplanning';
import { LessonSubcategory } from '../lessonplanning';
import { SUBCATEGORY } from '../lessonplanning';
import { LessonActivity } from '../lessonplanning';
import { ACTIVITY } from '../lessonplanning';
import { Educational } from '../lessonplanning';
import { EDUCTIONALAIDS } from '../lessonplanning';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap'; 
import { FormGroup, FormControl,Validators } from '@angular/forms'; 
import { LpDataexService } from 'src/app/lp-dataex.service';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';


@Component({
  selector: 'app-lesson-planning',
  templateUrl: './lesson-planning.component.html',
  styleUrls: ['./lesson-planning.component.css']
})
export class LessonPlanningComponent implements OnInit {
  
  Lesson = CATEGORY; 
  selectedcategory: Lesson;

  subcategory = SUBCATEGORY; 
  selectedsubcategory: LessonSubcategory;

  activity = ACTIVITY; 
  selectedactivity: LessonActivity;

  education = EDUCTIONALAIDS; 
  selectededucation: Educational;

  selected: EventEmitter<string> = new EventEmitter<string>();
  
  selectedcat: any;
  selectedsubcat: any;
  selectededu: any;
  selectedact: any;
  teachercode: any;
  selecteddate: any;

  userForm: FormGroup;

  Question = [];
  Categoryedit = [];

  Lessonplan_id = "";
  Teacher_id = "";
  Currentgrade = "";

  today: number = Date.now();
  Teacher_Name = ""; // used in the modal (gradecontent)
  Grade_Allocated = ""; // for getting the grade value in modal (gradecontent)

  Gradewise_Subject = [];
  Subject_Allocated = [];

  show_alloted_grade:boolean = true;
  show_gradewise_subject:boolean = false;
  Selected_Grade_Lable = "";
  LocalStorage_Subject = "";
  School_id = "";

  constructor(private modalService: NgbModal , private DataService: LpDataexService,private service:QpDataService, private auth:AuthorizationService) { }

  
  @ViewChild('gradecontent', { static: true }) content: TemplateRef<any>;

  ngOnInit(): void {
    let gradevalue = localStorage.getItem("GradeSource"); // Storing the grade value from local storage.
    let storage_subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Selected_Grade_Lable = localStorage.getItem("GradeSource");
    this.LocalStorage_Subject = localStorage.getItem("SubjectSource");

    console.log("Choosing grade is coming", gradevalue,storage_subject);
      if(gradevalue != undefined)
      {
      this.auth.getUserAttributes().subscribe(result => {        
        console.log("Success",result);
        if(result != "")
        {
          // this.Teacher_id = result ;
          this.Teacher_id = result["User"];
          this.School_id = result["school"];
          console.log("Success id",this.Teacher_id,this.School_id);
          let params = new HttpParams();
          params = params.append('Teacher_id', this.Teacher_id);
          params = params.append('Grade', gradevalue);
          params = params.append('Subject', storage_subject);
          console.log("data format", params,typeof params);
          this.service.Lessonplanget(params).subscribe(response => {
          this.Question = response["Items"][0]["Teacher_Lesson_Plan"];
          this.Lessonplan_id = response["Items"][0]["Lesson_plan_id"];
          console.log("lesson id", this.Lessonplan_id);
            // add logic to push to disp-lesson plan
            var str_less = JSON.stringify(this.Question);// '[{}]'
            this.DataService.publishArrayData(str_less);
            console.log("lesson id", this.Lessonplan_id, typeof str_less,str_less);
          });
          let params1 = new HttpParams();
          params1 = params1.append('School_id', this.School_id );
          params1 = params1.append('Teacher_id', this.Teacher_id);
          this.service.getteacherdata(params1).subscribe(response => {
          this.Teacher_Name = response["Item"]["Teacher_Name"];
          this.Grade_Allocated = response["Item"]["Grade_Allocated"];
          this.Subject_Allocated = response["Item"]["Subject_Allocated"];
          console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
          });
        }
        (err) => {
          console.log(err);
        }
      });
    }
    else if(gradevalue == null && storage_subject == null)
      {
        this.auth.getUserAttributes().subscribe(result => {        
          console.log("Success",result);
          if(result != ""){
            this.Teacher_id = result["User"];
            this.School_id = result["school"];
            console.log("result of user",this.Teacher_id);
            this.modalService.open(this.content);
            let params = new HttpParams();
            params = params.append('School_id', this.School_id );
            params = params.append('Teacher_id', this.Teacher_id);
            this.service.getteacherdata(params).subscribe(response => {
            this.Teacher_Name = response["Item"]["Teacher_Name"];
            this.Grade_Allocated = response["Item"]["Grade_Allocated"];
            this.Subject_Allocated = response["Item"]["Subject_Allocated"];
            console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
          });
        }
      });
    }
  }
  
  Save()
  {
    if(this.Lessonplan_id != "")
    {
      let Grade = localStorage.getItem("GradeSource");
      let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
      
      var date = this.selecteddate.toString().slice(0,15);
      var Teacher_id = this.Teacher_id;
      var Lesson_plan_id = this.Lessonplan_id;
      
      var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":this.teachercode,"category":this.selectedcat,"subcat": this.selectedsubcat,
          "education":this.selectededu,"activity":this.selectedact,"date":date};
      
      console.log("Category",newQobj);
      this.Question.push(newQobj);
      var Lessonplan = this.Question;
      let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
      this.service.Lessonplancreate(post).subscribe(() => {
        alert("Record has been added into your Lesson Plan.")
        });
        var str_less = JSON.stringify(newQobj);
        this.DataService.publishData(str_less);

        this.Lessonplan_score_update();
        
      }
      else if (this.Lessonplan_id == "" || this.Lessonplan_id == null)
      {
        let Grade = localStorage.getItem("GradeSource");
        let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.

      var date = this.selecteddate.toString().slice(0,15);
      var Teacher_id = this.Teacher_id;
      var Lesson_plan_id = Teacher_id + "_" + this.today;
      var newQobj = {"id":Math.floor( Math.random()*100 ),"teacher":this.teachercode,"category":this.selectedcat,"subcat": this.selectedsubcat,
          "education":this.selectededu,"activity":this.selectedact,"date":date};
      
      console.log("Category",newQobj);
      this.Question.push(newQobj);
      var Lessonplan = this.Question;
      let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
      this.service.Lessonplancreate(post).subscribe(() => {
        alert("Record has been added into your Lesson Plan.")
        });
        var str_less = JSON.stringify(newQobj);
        this.DataService.publishData(str_less);
      
        this.Lessonplan_score_update();

      }
  }   

  //update the score in the teacher score table only for lessonplan_score
  Lessonplan_score_update()
  {
    let Grade1 = localStorage.getItem("GradeSource");
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    let Grade = Grade1 +'_'+ storage_grade;
    console.log("Grade_Subject",Grade);
    var Teacher_score = {"School_id":this.School_id,"Teacher_id":this.Teacher_id,"working_on":"Lesson_plan_scores",
    "grade":Grade};
    console.log("data of params",Teacher_score);
    this.service.Teacher_LessonScore_Update(Teacher_score).subscribe(() => {
    });
  }

  open(content) 
  { 
        this.modalService.open(content);
        let params = new HttpParams();
        params = params.append('School_id', this.School_id);
        params = params.append('Teacher_id', this.Teacher_id);
        this.service.getteacherdata(params).subscribe(response => {
        this.Teacher_Name = response["Item"]["Teacher_Name"];
        this.Grade_Allocated = response["Item"]["Grade_Allocated"];
        console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated);
        });
  }

//trail for changing the subject according to grade Start
Change_Subject_Acc_Grade(alloted_grade:any)
{
  this.Gradewise_Subject = [];
  console.log("subject data", this.Subject_Allocated);
  this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
  console.log(this.Gradewise_Subject,"grade wise subject is coming");
  let grade = alloted_grade;
  this.Selected_Grade_Lable = alloted_grade ;
  localStorage.setItem('GradeSource', grade);
  console.log("set the data in local", grade);
  this.show_alloted_grade = !this.show_alloted_grade;
  this.show_gradewise_subject = true;
}
//trail for changing the subject according to grade End

SelectingSubject(Subject: any)
{
  let subject = Subject;
  localStorage.setItem('SubjectSource', subject);
  // Storing the previous grade value from dashboard local storage.
  let gradevalue = localStorage.getItem("GradeSource"); 
  this.LocalStorage_Subject = Subject ;
  
  if(gradevalue != null && subject != null)
  {  
        let params = new HttpParams();
        params = params.append('Teacher_id', this.Teacher_id);
        params = params.append('Grade', gradevalue);
        params = params.append('Subject', subject);
        console.log("data format", params,typeof params);
        this.service.Lessonplanget(params).subscribe(response => {
          this.Question = response["Items"][0]["Teacher_Lesson_Plan"];
          this.Lessonplan_id = response["Items"][0]["Lesson_plan_id"];
          
          var str_less = JSON.stringify(this.Question);
          this.DataService.publishArrayData(str_less);
          console.log("lesson id", this.Lessonplan_id, typeof str_less,str_less);
        });
    }
}

  //Back to grade
  Backto_Grade_Allocated()
  {
    this.show_alloted_grade = true;
    this.show_gradewise_subject = false;
  }
}
