<app-navbar></app-navbar>
<div class="row" style="align-content: right; background-color: #fafafa;" >
   <br>
    <div class="col-sm-12 col-md-12">  
     <div class="container">
  <div class="md-form">
  <mat-card>
<div class= "row">
   <div class="col-md-10 offset-1">
  <!-- <ul style="list-style-type:none;align-content: center;margin-left: 10px;"> -->
    <ul style="list-style-type:none;align-content: center;margin-left: -65px;">
   <mat-card>
      <!-- <h1 class="container-text"><b>Teacher Resource</b></h1> -->
      <mat-chip-list aria-label="Fish selection">
        <h1 style="margin-right: 5%;"><b>Choose the activity and add to your lesson plan.</b></h1>
        <mat-chip color="primary" selected>{{Selected_Grade}}</mat-chip>
        <mat-chip color="accent" selected>{{Selected_Subject}}</mat-chip>
      </mat-chip-list>
      <!-- &nbsp;&nbsp;
    <button style="text-align: left;"  class="btn btn-lg btn-outline-success" 
    (click)="open(gradecontent)"> 
    Change Grade From Here 
    </button> -->
   </mat-card>
   <br>
   <mat-card>
      <h1>Topic: {{topic_value}}</h1>
      <h3><b>Suggested Activities:</b></h3>
 </mat-card><br>
      <li *ngFor="let act of selectedAcitivity">
         <mat-card>
            <mat-card-content>
               <p>{{ act }}</p>
            </mat-card-content>
               <!-- <input id="{{act}}" class="form-control" value={{act}} *ngIf="editable1"/>   -->
         
               <mat-card-footer class="row" style="margin-left: 0;">  
                  <mat-card-actions>  
                  <button class="btn btn-secondary" (click)="AddLessonPlan(contenteidt,act)">Add to your Lesson Plan</button>
                     &nbsp;&nbsp;         
                  <!-- <button (click)="EditLessonPlan(act)" class="btn btn-warning"><b>Edit Lesson Plan</b></button>&nbsp;&nbsp;
                  <button (click)="updateLessonPlan(act)" *ngIf="editable1" class="btn btn-success"><b>Update Lesson Plan</b></button> -->
               </mat-card-actions>
            </mat-card-footer>
         </mat-card>
        <br>
        <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
         <div class="modal-dialog">
           <div class="modal-content">
             <div class="modal-header">
             <button type="button" class="close" data-dismiss="modal" (click) = "hide()">&times;</button>
           </div>
           <mat-card>
            <div class="text-center">
           <!-- <div class="modal-body" style="margin: 0 auto;text-align: left;"> -->
             <div class="row">
                 <div class="col-md-12">
                   <p><b>This activity already there in lesson plan.If you want to add them then press yes.</b></p>
                   <div class="row">    
                     <div class="col-md-12"> 
                        <button class="btn btn-primary" (click)="onConfirm(act)">Yes</button> 
                        &nbsp;&nbsp;&nbsp;&nbsp;  
                        <button class="btn btn-danger" (click)="onDismiss()">No</button> 
                     </div>    
                 </div>     
                 </div>
               </div>
            </div>
           </mat-card>
           <!-- </div> -->
           <div class="modal-footer">
             <button (click)="hide()" class="btn btn-info" style="margin-right: 50%;"><b>Back</b></button>
             <button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
           </div>
         </div>
       </div>
       </div>
      </li>
  </ul>
</div>
</div>
<br>
<div class="row">
   <div class="col-md-2"></div>
   <video [src] = "videoLink" class="videoplay" controls></video>
</div>
<br>
<br>
<br>
<br>
</mat-card>
<br>
   
  </div> 
  </div>
  
  </div> 
  </div>

  <footer class="page-footer font-small bg-dark-gray">

   <!-- Copyright -->
   <div class="footer-copyright text-center py-3">© 2020 Copyright:
     NerdNerdy.com
   </div>
   <!-- Copyright -->
 
 </footer>
 <!-- for edit the Activity of type 1 Start-->
 <ng-template #contenteidt let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <h1 class="modal-title w-100 text-center">Activity</h1>
    </div> 
    <h4 class="modal-sub-title text-left" style="color:black;margin-left: 1px;">Step 1:- You can edit the given activity.</h4>
    <h4 class="modal-sub-title text-left" style="color:black;margin-left: 1px;">Step 2:- Click <span style="color:#00ff00">Save</span> and the activity is added to your customized lesson plan.</h4>
    <!-- <h2 class="modal-sub-title text-center" style="color:black;">Update the activity and to add to lesson plan directly by pressing save</h2> -->
    <mat-card>
      <div class="text-center">
        <textarea style="height: 200px; width: 100%;" id="Lactivity" class="form-control" type="text">{{activityedit}}</textarea>
        <!-- <textarea id="Lactivity" class="form-control" type="text">{{activityedit}}</textarea> -->
      <mat-card-actions>
        <button (click)="updateLessonPlan();modal.close('Update click')" class="btn btn-success"><b>Save</b></button>&nbsp;&nbsp;
          <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
      </mat-card-actions>
    </div>
    </mat-card>
  </div>
</ng-template>
<!-- for edit the Activity of type 1 End-->

<!-- For choosing the value from Grade Start -->
<ng-template #gradecontent let-modal class="modal">   
  <div class="modal-content">
    <div class="modal-header"> 
    <h1 class="modal-title w-100 text-center">Welcome back, {{Teacher_Name}}</h1>
      <!-- <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-danger"> 
      Close
  </button>  -->
    </div>
    <!-- <h2 class="modal-sub-title text-center" style="color:black;">Select a grade and subject for creating respective QuestionBank , Lesson Plans, Courses and more !!</h2> -->
    <mat-card>
        <form> 
          <br>
        <div *ngIf="show_alloted_grade">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted grade/grades</h2>
          <mat-card>
            <div *ngFor="let alloted_grade of Grade_Allocated" class="text-center">
              <mat-card-actions>
                  <button (click)="Change_Subject_Acc_Grade(alloted_grade)" class="btn btn-warning"><b>{{alloted_grade}}</b></button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>
        <div *ngIf="show_gradewise_subject">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted subject/subjects</h2>
          <mat-card>
            <mat-card-actions>
              <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button>
              <label><B>Allotted Grade :-</B></label>&nbsp;&nbsp;
              <button class="btn btn-warning" disabled style="align-content: right;">
                {{Selected_Grade_Lable}}</button>
            </mat-card-actions>
            
            <div *ngFor="let gradewise_subject of Gradewise_Subject">
              <mat-card-actions>
                <label><B>Allotted Subject :-</B></label>&nbsp;&nbsp;
                  <button (click)="SelectingSubject(gradewise_subject);modal.close('Subject click')" class="btn btn-success"><b>{{gradewise_subject}}</b></button>
                  <br>
                  <h4>Click above<img src="assets/arrow.png" height="45" width="auto"> to enter the resource.</h4>
                </mat-card-actions>
            </div>
            
          </mat-card>
        </div>
          <br>
        </form> 
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from grade Start -->