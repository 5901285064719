import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LpDataexService } from './lp-dataex.service'; //used for the service
import { TableModule } from 'ngx-easy-table'; //used table in disp-lessonplan component/supervisiordashboard component
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgCircleProgressModule } from 'ng-circle-progress'; //for circle progess bar chart
import { ChartModule } from 'angular2-chartjs'; //for bar chart
import { HttpClientModule } from '@angular/common/http';

//Mat card for angular material Start
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
//Mat card for angular material End

//All component used in the project start 
import { AppComponent } from './app.component';
import { HeroesComponent } from './heroes/heroes.component';
import { HeroDetailComponent } from './hero-detail/hero-detail.component';
import { QuestionsDataComponent } from './questions-data/questions-data.component';
import { LessonPlanningComponent } from './lesson-planning/lesson-planning.component'; 
import { DispLessonplanComponent } from './disp-lessonplan/disp-lessonplan.component';
import { SubjectwiseactComponent } from './subjectwiseact/subjectwiseact.component';
import { ResourcelinkComponent } from './resourcelink/resourcelink.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { CarouselComponent } from './carousel/carousel.component';
import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TqDashboardComponent } from './tq-dashboard/tq-dashboard.component';
import { NewreportcardComponent } from './newreportcard/newreportcard.component';
//All component used in the project End 

//for newreportcard start and also Mat card is used from angular material
import { CdkTableModule } from '@angular/cdk/table';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SupervisiordashboardComponent } from './supervisiordashboard/supervisiordashboard.component';
import { JumphatsComponent } from './jumphats/jumphats.component';
import { TeacherGradeAllotmentComponent } from './teacher-grade-allotment/teacher-grade-allotment.component';
import { VideoPlayerComponent } from './video/video-player/video-player.component';
import { SidebarComponent } from './video/sidebar/sidebar.component';
import { CoursesComponent } from './video/courses/courses.component';
//for newreportcard End
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; // for using the autocomplete the school name


@NgModule({
  declarations: [
    AppComponent,
    HeroesComponent,
    HeroDetailComponent,
    QuestionsDataComponent,
    LessonPlanningComponent,
    DispLessonplanComponent,
    SubjectwiseactComponent,
    ResourcelinkComponent,
    LoginComponent,
    RegisterComponent,
    CarouselComponent,
    BaseComponent,
    NavbarComponent,
    TqDashboardComponent,
    NewreportcardComponent,
    SupervisiordashboardComponent,
    JumphatsComponent,
    TeacherGradeAllotmentComponent,
    VideoPlayerComponent,
    SidebarComponent,
    CoursesComponent
    
  ],
  imports: [
    [BrowserModule],
    FormsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatInputModule,
     MatFormFieldModule,
    [NgbModule],
   [CommonModule],
   AppRoutingModule,
   MatDatepickerModule,
   MatNativeDateModule,
   TableModule,
   MatCardModule,
   MatSidenavModule,
   MatButtonModule,
   MatExpansionModule,
   MatTabsModule,
   FlexLayoutModule,
   CarouselModule.forRoot(),
   NgCircleProgressModule.forRoot({
   }),
   ChartModule,
    CdkTableModule,
    NoopAnimationsModule,
    MatMenuModule,
    MatTreeModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatStepperModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatTooltipModule,
    MatTableModule,
    MatListModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    HttpClientModule,
    GooglePlaceModule
  ],
  
  providers: [LpDataexService],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class AppModule { }
