import { Component } from '@angular/core';
// import {Lesson} from 'src/app/lessonplanning';
// import { CATEGORY } from 'src/app/lessonplanning';
// import {LessonSubcategory} from 'src/app/lessonplanning';
// import { SUBCATEGORY } from 'src/app/lessonplanning';
// import {LessonActivity} from 'src/app/lessonplanning';
// import { ACTIVITY } from 'src/app/lessonplanning';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Types Of Questions';

  // Lesson = CATEGORY; 
  // n = JSON.parse(JSON.stringify(this.Lesson));
  // selectedcategory: Lesson;

  // subcategory = SUBCATEGORY; 
  // selectedsubcategory: LessonSubcategory;

  // activity = ACTIVITY; 
  // selectedactivity: LessonActivity;

  // ngOnInit() {
  //   console.log("deep copy",this.n);
  // }
}

// @Component({
//   selector: 'Lesson-planning',    
//   template: './Lesson-planning.component.html'
//   })
//   export class LessonComponent{
//     Lesson = CATEGORY; 
//   n = JSON.parse(JSON.stringify(this.Lesson));
//   selectedcategory: Lesson;

//   subcategory = SUBCATEGORY; 
//   selectedsubcategory: LessonSubcategory;

//   activity = ACTIVITY; 
//   selectedactivity: LessonActivity;

//   ngOnInit() {
//     console.log("deep copy",this.n);
//   }
    
//   }
