import { Component, OnInit } from '@angular/core';
import {AuthorizationService} from '../authorization.service';
import {Router} from '@angular/router'; 
import { QpDataService } from '../qp-data.service';
import { HttpParams } from "@angular/common/http";
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private auth:AuthorizationService,private router:Router, private teacherdataService: QpDataService) { }
  isNavbarCollapsed = true;
  _isNavbarCollapsedscreen = 'closed';
  superVisorFlag:number= null;

  ngOnInit(): void {
    if(!this.auth.isLoggedIn()){
      this.router.navigate(["/"]);
      
    }
    else{
     var user = this.auth.getAuthenticatedUser();
     console.log("user data",user);
    if(!localStorage.getItem("supervisorFlag")){
      // call backend check supervisor flag and set it in local storage
      this.auth.getUserAttributes().subscribe(userDetails =>{
        if(userDetails != ''){
          console.log(userDetails["school"],userDetails["User"],userDetails)
          let params = new HttpParams();
          params = params.append('School_id',userDetails["school"]);
          params = params.append('Teacher_id',userDetails["User"]);

          this.teacherdataService.getteacherdata(params).subscribe(tData =>{
            console.log(tData);
            if(tData["Item"]["supervisorFlag"]){
              localStorage.setItem("supervisorFlag",(tData["Item"]["supervisorFlag"]).toString());
              this.superVisorFlag= parseInt(tData["Item"]["supervisorFlag"]);
            }
            else{
              localStorage.setItem("supervisorFlag","0");
              this.superVisorFlag = 0;
            }
          })

        }
      })
    }
    else{
      this.superVisorFlag = parseInt(localStorage.getItem("supervisorFlag"));
    }
     
    }
  }

  SignOut(){
    
    this.auth.logOut();
    this.router.navigate(["/"]);
  }
//Responsive navbar for small screen Start
  toggleNavbar() {
    if(this.isNavbarCollapsed){
        this._isNavbarCollapsedscreen = 'open';
      this.isNavbarCollapsed = false;
    } else {
    this._isNavbarCollapsedscreen = 'closed';
      this.isNavbarCollapsed = true;
    }
  }
  //Responsive navbar for small screen End
}
