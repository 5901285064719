<!-- Adding Register Content -->
<div class="container-fluid" style="overflow: hidden;">
  <div class="row">
    <div class="col-md-6">
      <app-base></app-base>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8" style="margin-top: 9%;">
          <div class="text-center"><img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/nnlogo.png" height="45" width="auto"></div>
          <div *ngIf="success_registration" style="color: green;">Thank you for registering please check your registered email id for verfication link</div>
          <div *ngIf="failed_registration" style="color: red;">Registration Failed , please ensure all inputs. {{failed_detail }}</div>
          <form (ngSubmit)="register(regform)" #regform="ngForm" name="FormName">
            <div class="form-group">
              <div class="form-group">
                  <label for="school_name">School Name</label>
                  <!-- <input type="text" name="school_name" id="school_name" ngModel class="form-control" required> -->
                  <input ngx-google-places-autocomplete placeholder="" class="form-control" type="text" name="school_name" id="school_name"/>
                  <!-- <input ngx-google-places-autocomplete placeholder="Enter a School Name" [(ngModel)] type="text" name="school_name" class="form-control" required> -->
                </div>
                <div class="form-group">
                  <label for="name">Teacher's Name</label>
                  <input type="name" name="name" id="name" ngModel class="form-control" required>
                </div>
                <div class="form-group">
                  <label for="phone_number">Phone Number</label>
                  <input type="phone_number" name="phone_number" id="phone_number" ngModel class="form-control" required>
                </div>
              <label for="email">Email</label>
              <input type="email" name="email" id="email" ngModel class="form-control">
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" name="password" id="password" ngModel class="form-control" required>
            </div>
            <div class="form-group">
              <label for="conf_password">Confirm Password</label>
              <input type="password" name="cong_password" id="conf_password" ngModel class="form-control" required>
            </div>
  
           <div *ngIf="!success_registration"><button type="submit" class="btn btn-primary">Register</button>
            <button type="view" class="btn btn-success" (click)="View_Video(Viewvideo)" style="margin-left: 5px;">Registration Video</button>
            <div>Already have an account? &nbsp;<a routerLink="/login">Login</a></div>
          </div>
           <div *ngIf="success_registration"> <a routerLink='/login' class="btn btn-success">Login</a></div>
           
          </form>
    </div>
    </div>
    </div>
  </div>
  <br />
</div>

<!-- For view how to register and login Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacher+training+module+introduction.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>

<!-- <div class="row" *ngIf="Videoplay">
  <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacher+training+module+introduction.mp4" autoplay class="videoplay" controls></video>
</div> -->
<!-- For view how to register and login End-->
