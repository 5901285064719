<app-navbar></app-navbar>

<!-- All the data into single container start-->
<mat-drawer-container class="example-container h-100">
  <!-- used for the choosing the subject and the topic start-->
  <mat-drawer mode="side" opened>
    <div class="row h-500">
    <div class="example-container col-md-12">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label><b>Training Modules</b></mat-label>
            <mat-select placeholder="Mode" [(ngModel)]="SelectCourse">
                <mat-option value="Course1">Building Self Esteem</mat-option>
                <mat-option value="Course4">Financial Literacy</mat-option>
                <mat-option value="Course5">Grammar For Early Learners</mat-option>
                <mat-option value="Course2">2D & 3D Shapes</mat-option>
                <mat-option value="Course3">Waste Segregation</mat-option>
              </mat-select>
            <!-- <mat-select>
              <mat-option *ngFor="let CoursesAvailable of AllCourses_Available" [value]="CoursesAvailable" color="accent">
                  {{CoursesAvailable}} 
              </mat-option>
          </mat-select> -->
          </mat-form-field>

          <!-- through search button we can find the supervisor data which is related to that grade -->
      <mat-card-actions>
        <button type="button" (click)="SearchCourse()" class="btn btn-success">Submit</button>&nbsp;
        <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-top: 5px;">Teacher Training Video</button>
      </mat-card-actions>
    </div>
  </div>
  </mat-drawer>
  <!-- used for the choosing the subject and the topic End-->

  <!-- it will show the data into this start-->
  <mat-drawer-content >
      <div class = "row h-100">
          <div class="col-sm-12 col-md-12">
            <br>  
            <div style="margin-left: 2.33%;">
                <mat-chip-list aria-label="Fish selection">
                  <h1 style="margin-right: 75%;">Courses</h1>
                  <mat-chip color="primary" selected>{{LocalStorage_Grade}}</mat-chip>
                  <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
                </mat-chip-list>
            </div>
           <hr>
          
            <!-- <div *ngIf="hide_content_middle" class="text-center align-middle" style="margin-top: 23%; font-size: medium;">
              Select from the left sidenav and find courses...
            </div> -->
            <img src="assets/Training.jpeg" class="image" *ngIf="hideimage">
        <div class="container" Visible="false" *ngIf="show_courses">
          <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
            <!-- <div fxFlex="25%" *ngFor="let subtop1 of Question"> -->
                <div fxFlex="25%">
              <!-- click event of mat card is used to find the courses  -->
              <mat-card class="example-card">
                <mat-card-header class="header">
                  <mat-card-title>Building Self Esteem</mat-card-title>
                  <mat-card-subtitle>This course provides information on building esteem in children of diffrerent ages.</mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image [src] = "course_src" alt="Photo of a Shiba Inu">
                <mat-card-content>
                  <p>
                    To view videos from this course, click the link given below-
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <div class="row">
                    <div class="col-md-12">
                  <!-- <button mat-button><a routerLink="/coursesvideo">Go to Course </a></button> -->
                  <!-- <button mat-button (click)="SubmitCourse()">Go to Course</button> -->
                  <button type="button" class="btn btn-primary" (click)="SubmitCourse()">Go to Course</button>
                </div>
                  <div class="col-md-12">
                Course Completed</div> 
                <div class="progress col-md-12" style="padding-top: 5%;padding-bottom: 3%;">{{course_completion}}%</div>
            </div>
                 <mat-progress-bar mode="determinate" [value]="course_completion"></mat-progress-bar>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>

     <!-- for Waste Segregation content start -->
     <div class="container" Visible="false" *ngIf="show_waste_segregation_courses">
      <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
        <!-- <div fxFlex="25%" *ngFor="let subtop1 of Question"> -->
            <div fxFlex="25%">
          <!-- click event of mat card is used to find the courses  -->
          <mat-card class="example-card">
            <mat-card-header class="header">
              <mat-card-title>Waste Segregation</mat-card-title>
              <mat-card-subtitle>This course provides information on waste segregation.</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image [src] = "course_src" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p>
                To view videos from this course, click the link given below-
              </p>
            </mat-card-content>
            <mat-card-actions>
              <div class="row">
                <div class="col-md-12">
              <!-- <button mat-button><a routerLink="/coursesvideo">Go to Course </a></button> -->
              <!-- <button mat-button (click)="SubmitCourse()">Go to Course</button> -->
              <button type="button" class="btn btn-primary" (click)="SubmitCourse()">Go to Course</button>
            </div>
              <div class="col-md-12">
            Course Completed</div> 
            <div class="progress col-md-12" style="padding-top: 5%;padding-bottom: 3%;">{{course_completion}}%</div>
        </div>
             <mat-progress-bar mode="determinate" [value]="course_completion"></mat-progress-bar>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
<!-- for Waste Segregation content End -->

<!-- for 2D&3d Shapes content start -->
<div class="container" Visible="false" *ngIf="show_2d_3d_courses">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
    <!-- <div fxFlex="25%" *ngFor="let subtop1 of Question"> -->
        <div fxFlex="25%">
      <!-- click event of mat card is used to find the courses  -->
      <mat-card class="example-card">
        <mat-card-header class="header">
          <mat-card-title>2D & 3D Shapes</mat-card-title>
          <mat-card-subtitle>This course provides information on 2d & 3d shapes.</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image [src] = "course_src" alt="Photo of a Shiba Inu">
        <mat-card-content>
          <p>
            To view videos from this course, click the link given below-
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div class="row">
            <div class="col-md-12">
          <!-- <button mat-button><a routerLink="/coursesvideo">Go to Course </a></button> -->
          <!-- <button mat-button (click)="SubmitCourse()">Go to Course</button> -->
          <button type="button" class="btn btn-primary" (click)="SubmitCourse()">Go to Course</button>
        </div>
          <div class="col-md-12">
        Course Completed</div> 
        <div class="progress col-md-12" style="padding-top: 5%;padding-bottom: 3%;">{{course_completion}}%</div>
    </div>
         <mat-progress-bar mode="determinate" [value]="course_completion"></mat-progress-bar>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
<!-- for 2D&3d Shapes content End -->

<!-- add extra courses start-->

<!-- for Financial Literacy content start -->
<div class="container" Visible="false" *ngIf="show_financial_courses">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
    <!-- <div fxFlex="25%" *ngFor="let subtop1 of Question"> -->
        <div fxFlex="25%">
      <!-- click event of mat card is used to find the courses  -->
      <mat-card class="example-card">
        <mat-card-header class="header">
          <mat-card-title>Financial Literacy</mat-card-title>
          <mat-card-subtitle>This course provides information on Financial Literacy.</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image [src] = "course_src" alt="Photo of a Shiba Inu">
        <mat-card-content>
          <p>
            To view videos from this course, click the link given below-
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div class="row">
            <div class="col-md-12">
          <!-- <button mat-button><a routerLink="/coursesvideo">Go to Course </a></button> -->
          <!-- <button mat-button (click)="SubmitCourse()">Go to Course</button> -->
          <button type="button" class="btn btn-primary" (click)="SubmitCourse()">Go to Course</button>
        </div>
          <div class="col-md-12">
        Course Completed</div> 
        <div class="progress col-md-12" style="padding-top: 5%;padding-bottom: 3%;">{{course_completion}}%</div>
    </div>
         <mat-progress-bar mode="determinate" [value]="course_completion"></mat-progress-bar>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
<!-- for Financial Literacy content End -->

<!-- for Grammar for early learners content start -->
<div class="container" Visible="false" *ngIf="show_grammar_courses">
  <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
    <!-- <div fxFlex="25%" *ngFor="let subtop1 of Question"> -->
        <div fxFlex="25%">
      <!-- click event of mat card is used to find the courses  -->
      <mat-card class="example-card">
        <mat-card-header class="header">
          <mat-card-title>Grammar For Early Learners</mat-card-title>
          <mat-card-subtitle>This course provides information on Grammar for early learners.</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image [src] = "course_src" alt="Photo of a Shiba Inu">
        <mat-card-content>
          <p>
            To view videos from this course, click the link given below-
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div class="row">
            <div class="col-md-12">
          <!-- <button mat-button><a routerLink="/coursesvideo">Go to Course </a></button> -->
          <!-- <button mat-button (click)="SubmitCourse()">Go to Course</button> -->
          <button type="button" class="btn btn-primary" (click)="SubmitCourse()">Go to Course</button>
        </div>
          <div class="col-md-12">
        Course Completed</div> 
        <div class="progress col-md-12" style="padding-top: 5%;padding-bottom: 3%;">{{course_completion}}%</div>
    </div>
         <mat-progress-bar mode="determinate" [value]="course_completion"></mat-progress-bar>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
<!-- for Grammar for early learners content End -->

<!-- add extra courses End-->

      </div> 
   </div>
  </mat-drawer-content>
  <!-- it will show the data into this End-->
</mat-drawer-container>

<hr style="margin-bottom: 0;">
  <footer class="page-footer font-small bg-dark-gray">
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
  NerdNerdy.com
  </div>
  <!-- Copyright -->
  </footer>

  <!-- For view how to use Teacher Training Course Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/Teacher_Training_Module.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>
<!-- For view how to use Teacher Training Course End-->
