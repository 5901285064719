import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable({
  providedIn: 'root'
})
export class LpDataexService {
  // declare a behavour subject object for lesson planning
  private sharedValue = new BehaviorSubject<string>("abc");
  
  private sharedArrayValue = new BehaviorSubject<string>("abc");


// declare a behavour subject object for supervisiordashboard
  private supervisiordataValue = new BehaviorSubject<string>("supervisior"); 

  constructor() {  }
   // Observable string streams

   //Start lesson planning service

   // create an observable for lesson planning
  $sharedValue = this.sharedValue.asObservable();
  $sharedArrayValue = this.sharedArrayValue.asObservable();

// Service message commands
 publishData(data: string)  // this service is used in the lesson-planning component.ts
 {
   // push values into the observable
   this.sharedValue.next(data); //the data is push into this
 }
 publishArrayData(data: string)  // this service is used in the lesson-planning component.ts
 {
   // push values into the observable
   this.sharedArrayValue.next(data); //the data is push into this
 }
 //End lesson planning service

//Start supervisiordashboard service

// create an observable for supervisiordashboard
 $supervisiordataValue = this.supervisiordataValue.asObservable();
 // Service message commands
 Supervisiordata(data: string) // this service is used in the supervisiordashboard component.ts
 {
  // push values into the observable
  this.supervisiordataValue.next(data); //the data is push into this
}
//End supervisiordashboard service

}
