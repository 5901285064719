<div class="container" id="content" #content>
    <h2>Questions Bank</h2>
    <ul class="heroes">
      <li *ngFor="let i of n"
        [class.selected]="i === selectedHero"
        (click)="onSelect(i)">
        <span class="badge">{{i.id}}</span> {{i.question}}
      </li>
      <li *ngFor="let qt2 of question_type2"
      [class.selected]="qt2 === selectedQuestion"
      (click)="onSelectQues(qt2)">
      <span class="badge">{{qt2.id}}</span> {{qt2.question}}
      {{qt2.option}}
    </li>
    <li *ngFor="let qt3 of question_type3"
      [class.selected]="qt3 === selectedQuestion"
      (click)="onSelectFillQues(qt3)">
      <span class="badge">{{qt3.id}}</span> {{qt3.question}}
    </li>
    </ul>
    </div>
