import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class QpDataService {

  private QuestionBankurl = 'https://5jwsplhsxc.execute-api.ap-south-1.amazonaws.com/default/TQ-QuestionBank-V1';
  private Lessonplanurl = 'https://p8sv4ytml7.execute-api.ap-south-1.amazonaws.com/default/TQ-LessonPlans';
  private StudentPerformanceurl = 'https://57dj4rnjzj.execute-api.ap-south-1.amazonaws.com/default/TQ-StudentPerformance';
  private TeacherScoreurl = 'https://xi2zgjvonb.execute-api.ap-south-1.amazonaws.com/default/TQ-TeacherScores';
  private TeacherAdminsurl = 'https://bli8e1iul9.execute-api.ap-south-1.amazonaws.com/default/TQ-TeacherAdmins';

  private Videourl = 'https://0k0vopi6t8.execute-api.ap-south-1.amazonaws.com/default/TQ-Courses-Comp-IDV2';
  private Topicwiseurl = 'https://c6otthfox0.execute-api.ap-south-1.amazonaws.com/default/TQ-TopicWise';
  private CoursesVideourl = 'https://ek2hxdlz06.execute-api.ap-south-1.amazonaws.com/default/TQ-Video_courses';

  constructor(private httpClient: HttpClient) { }

  getQuestionBank(params){
    return this.httpClient.get(this.QuestionBankurl, {params});
  }

  createQuestionBank(post){
    return this.httpClient.post(this.QuestionBankurl,JSON.stringify(post));
  }

  deleteQuestionBank(params){
    return this.httpClient.delete(this.QuestionBankurl,{params});
  }

  Lessonplanget(params){
    return this.httpClient.get(this.Lessonplanurl, {params});
  }

  Lessonplancreate(post){
    return this.httpClient.post(this.Lessonplanurl,JSON.stringify(post));
  }

  StudentPerformanceget(params){
    return this.httpClient.get(this.StudentPerformanceurl, {params});
  }

  StudentPerformancecreate(post){
    return this.httpClient.post(this.StudentPerformanceurl,JSON.stringify(post));
  }

  getteacherdata(params){
    return this.httpClient.get(this.TeacherScoreurl, {params});
  }

  Teacher_LessonScore_Update(post_score){
    return this.httpClient.post(this.TeacherScoreurl,JSON.stringify(post_score));
  }

  Delete_Teacher_Grade(params){
    return this.httpClient.delete(this.TeacherScoreurl,{params});
  }

  //Getting the data from Teacheradmins table
  Teacher_Adminget(params){
    return this.httpClient.get(this.TeacherAdminsurl, {params});
  }

  Teacher_Admin_patch(params) // {},{{}}
  {
    return this.httpClient.patch(this.TeacherAdminsurl, JSON.stringify(params));
  }

  Teacher_Admin_put(params)
  {
    return this.httpClient.put(this.TeacherAdminsurl, JSON.stringify(params));
  }

  Teacher_Admin_post(post_admin)
  {
    return this.httpClient.post(this.TeacherScoreurl,JSON.stringify(post_admin));
  }

  Video_get(){
    return this.httpClient.get(this.Videourl);
  }

  Video_create(post){
    return this.httpClient.post(this.Videourl,JSON.stringify(post));
  }

  Topicwise_get(params){
    return this.httpClient.get(this.Topicwiseurl, {params});
  }

  //Courses 
  Courses_get(){
    return this.httpClient.get(this.CoursesVideourl);
  }
}
