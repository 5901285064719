export interface Lesson {
    id:number;
    category : string;
}

export interface LessonSubcategory {
    id:number;
    subcategory : string;
}
export interface LessonActivity {
    id:number;
    activity : string;
}
export interface Educational {
    id:number;
    education : string;
}

export const CATEGORY: Lesson[] = [
    { id: 11, category: 'Emotion'},
    { id: 12, category: 'Expressive Language'},
    { id: 13, category: 'Cognition' },
    { id: 14, category: 'Gross Motor Skills' },
    { id: 15, category: 'Writing'},
];

export const SUBCATEGORY: LessonSubcategory[] = [
    { id: 16, subcategory : 'Expressions' },
    { id: 17, subcategory : 'Expanded Phrases- Speaking Grammatically correct phrases' },
    { id: 18, subcategory : 'Time concept' },
    { id: 19, subcategory : 'Balancing, jumping & hopping' },
    { id: 20, subcategory : 'Hand, Finger strength & pincer strength.' },
];

export const EDUCTIONALAIDS: Educational[] = [
    { id: 21, education : 'Hand and Finger strengthening Velcro Board' },
    { id: 22, education : 'Writing Readiness Manual' },
    { id: 23, education : 'Writing Readiness-Tracing of standing line on Textured Paper' },
    { id: 24, education : 'Color Shade card' },
    { id: 25, education : 'Seriation(back side) Outline matching with shapes and size' },
];

export const ACTIVITY: LessonActivity[] = [
    { id: 26, activity : 'List out emotions on one side an put jumbled pictures on the other. Ask the child to match the name of the emotion to its picture.'},
    { id: 27, activity : 'Parent may name the category and child has to say what comes in his/her might. You make thgeis activity intersting by making it a two three player game. For Example: Category: fruits and the child writes apples, oranges, etc.'},
    { id: 28, activity : 'Mix different textures, shapes, sizes of pasta and mix them together. let the child match them correctly.'},
    { id: 29, activity : 'Create obstacle course for the child using the cushions, hop ball, tunnel etc. by combining lots of gross motor skills together into one practice.'},
    { id: 30, activity : 'Color using shading to demonstrate that different shades require a different amount of pressure.'},
];