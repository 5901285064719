<app-navbar></app-navbar>
<br>
<br>
<div class="container">
  <div class="md-form">
    <mat-card class="mat-example-container">
      <div class="row">
        <div class="col-md-4">
          <!-- <h1 style="padding-left: 100px;">Course 1</h1> -->
          <mat-drawer-container class="example-container">
                  <mat-drawer-content>
                      <div *ngFor="let i of data">
                          <button (click)="onVideoOptionClick(i)" mat-stroked-button class="btn btn-primary btn-lg btn_changes" >
                              <span *ngIf = "videoCompletedChecker(i.video_details)">
                                <i class="fa fa-check" aria-hidden="true" style="color: green; size: 300px;"></i></span>
                              {{i.video_name}}
                          </button>
                          <!-- <button mat-raised-button (click)="onVideoOptionClick(i)" color="primary">{{i.video_details}}</button> -->
                      </div>
                  </mat-drawer-content>
                </mat-drawer-container>
        </div>
        <div class="col-md-8">
          <app-video-player></app-video-player>
        </div>
        </div>
    </mat-card>
  </div>
</div>
<hr style="margin-bottom: 0;">
  <footer class="page-footer font-small bg-dark-gray">
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
  NerdNerdy.com
  </div>
  <!-- Copyright -->
  </footer>

  