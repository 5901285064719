<!-- <h1>{{title}}</h1> -->
  <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
        <li class="nav-item">
            <a class="nav-link" routerLink="/questionbank"><b style="font-size: medium; color: white;">QuestionBank</b></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/lessonplanning"><b style="font-size: medium; color: white;">Lesson Planing</b></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/chapterwise"><b style="font-size: medium;color: white;">Teacher Rescources</b></a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="http://localhost:4201/"><b style="font-size: medium;color: white;">Teacher Training</b></a>
        </li>
        </ul>
    </div>
</nav> -->
  
<!-- <div class="container-fluid h-100 "> -->
    <router-outlet></router-outlet>
<!-- </div> -->
