import { Component, OnInit , Input , EventEmitter} from '@angular/core';
import { LpDataexService } from '../lp-dataex.service';
import {Router} from '@angular/router'; 
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';
import {NgbModal}  from '@ng-bootstrap/ng-bootstrap'; 

@Component({
  selector: 'app-subjectwiseact',
  templateUrl: './subjectwiseact.component.html',
  styleUrls: ['./subjectwiseact.component.css']
})
export class SubjectwiseactComponent implements OnInit {

  Select_Grade = "";
  Select_Subject = "";
  Select_Topic = "";
  Topic_Available = [];
  Gradewise_Subject = [];
  Grade_Allocated = ""; // for getting the grade value in modal (gradecontent)
  Teacher_id = "";
  Subject_Allocated = [];
  Topicwise_Data = [];
  Subtopic_Id = "";
  Activity = [];
  Video_Link = "";
  Description = "";
  LocalStorage_Grade = "";
  LocalStorage_Subject = "";
  hideimage:boolean = true;
  School_id="";
  alloted_grade="";
  gradewise_subject="";

  constructor(private DataService:LpDataexService, private router: Router,private service:QpDataService,private auth:AuthorizationService,private modalService: NgbModal) { }

  ngOnInit(): void 
  {
    this.notSearched();
    // let gradevalue = localStorage.getItem("GradeSource"); // Storing the grade value from local storage.
    // let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    
    this.LocalStorage_Grade = localStorage.getItem("GradeSource");
    this.LocalStorage_Subject = localStorage.getItem("SubjectSource");

    this.alloted_grade = localStorage.getItem("GradeSource");
    this.gradewise_subject = localStorage.getItem("SubjectSource");
    console.log("Choosen grade and subject is coming", this.LocalStorage_Grade,this.LocalStorage_Subject,this.alloted_grade,this.gradewise_subject);

    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != ""){
      this.Teacher_id = result["User"];
      this.School_id = result["school"];
     // for getting the grade and subject value 
        let dropdowngrade = new HttpParams();
        dropdowngrade = dropdowngrade.append('School_id', this.School_id);
        dropdowngrade = dropdowngrade.append('Teacher_id', this.Teacher_id);
        this.service.getteacherdata(dropdowngrade).subscribe(response => {
        this.Grade_Allocated = response["Item"]["Grade_Allocated"];
        this.Subject_Allocated = response["Item"]["Subject_Allocated"];
        console.log("all grade is coming in dropdown", this.Grade_Allocated,this.Subject_Allocated);
        });
      }
    });
    
  }

  notSearched()
  {
    if(this.Topicwise_Data.length <=0)
    {
      return true;
    }
    return false;
  }

  Resourcefind() 
  { 
    var newQobj = {"topic":this.Select_Topic,"subtopic":this.Subtopic_Id,"activity":this.Activity,"video":this.Video_Link , 
                   "topic_value": this.Select_Topic,"education": this.Description,"grade":this.Select_Grade,"subject":this.Select_Subject};
    console.log("data is coming",newQobj);
    var actvideolink = JSON.stringify(newQobj);
    this.DataService.publishData(actvideolink);
    this.router.navigate(['/teacher-rescource']);
  }  


//trail for changing the subject according to grade Start
Grade_Selection(alloted_grade:any)
{
  this.Select_Grade = alloted_grade
  this.LocalStorage_Grade = alloted_grade;
  this.LocalStorage_Subject = "";
  this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
  // this.Select_Subject = "";
  // this.Select_Topic = "";
  console.log(this.Gradewise_Subject,"grade wise subject is coming");
}
//trail for changing the subject according to grade End

//Choosing subject are available in the dropdown Start
Subject_Selection(Subject: any)
{
  this.Select_Subject = Subject; //finding the subject in this
  this.LocalStorage_Subject = Subject;
  let params1 = new HttpParams();
  params1 = params1.append('Grade', this.Select_Grade);
  params1 = params1.append('Subject_Id', this.Select_Subject);
  
  this.service.Topicwise_get(params1).subscribe(response => { 
    console.log("checking the data",response);
    this.Topic_Available = response["Items"]; 
    
  });
}
//Choosing subject are available in the dropdown End

// Choosing Topic are available in the dropdown Start
Topic_Selection(topicAvailable)
{
  this.Select_Topic = topicAvailable; //finding the topic in this
  console.log("Topic", this.Select_Topic);
}
// Choosing Topic are available in the dropdown End

Search()
{

  let params1 = new HttpParams();
  params1 = params1.append('Grade', this.Select_Grade);
  params1 = params1.append('Subject_Id', this.Select_Subject);
  params1 = params1.append('Topic', this.Select_Topic);
  this.service.Topicwise_get(params1).subscribe(response => { 
    console.log("checking the data",response);
    this.Topicwise_Data = response["Items"]; 
    this.Subtopic_Id = response["Items"][0]["Subtopic_Id"];
    this.Activity = response["Items"][0]["Activity"];
    this.Video_Link = response["Items"][0]["Video"];
    this.Description = response["Items"][0]["Description"];
    this.hideimage = false;
    console.log("Sub topic",this.Subtopic_Id);
  });

}

// For play the video of how to use Lesson Plan Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Lesson Plan End

}
