<app-navbar></app-navbar>

<!-- All the data into single container start-->
<mat-drawer-container class="example-container h-100">
  <!-- used for the choosing the subject and the topic start-->
  <mat-drawer mode="side" opened>
    <div class="row h-500">
    <div class="example-container col-md-12">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label><b>Grade</b></mat-label>
        <!-- ngmodel is used for the subject option and the change event is used to change the topic which was
        related to that sunject -->
        <mat-select>
          <mat-option *ngFor="let alloted_grade of Grade_Allocated" (click)="Grade_Selection(alloted_grade)" [value]="alloted_grade" color="accent">
            {{alloted_grade}} 
        </mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field class="w-100" appearance="fill" disabled>
          <mat-label><b>Subject</b></mat-label>
          <!-- ngmodel is used for the subject option and the change event is used to change the topic which was
          related to that sunject -->
          <mat-select>
            <mat-option *ngFor="let gradewise_subject of Gradewise_Subject" (click)="Subject_Selection(gradewise_subject)" [value]="gradewise_subject" color="accent" style="text-align: center;">
              {{gradewise_subject}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- if the subject is choosed then the topic is visible that was mapped with it
      and the change event is used to show the subtopic related to that -->
      <mat-form-field class="w-100" appearance="fill" disabled>
          <mat-label><b>Topics</b></mat-label>
          <mat-select>
            <mat-option *ngFor="let topicAvailable of Topic_Available" (click)="Topic_Selection(topicAvailable.Topic)" [value]="topicAvailable.Topic" color="accent" style="text-align: center;">
                {{topicAvailable.Topic}} 
            </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-card-actions>
        <button type="button" (click)="Search()" class="btn btn-success">Submit</button>
        <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-left: 5px;">Lesson Plan Video</button>
      </mat-card-actions>
    </div>
  </div>
  </mat-drawer>
  <!-- used for the choosing the subject and the topic End-->

  <!-- it will show the data into this start-->
  <mat-drawer-content>
      <div class = "row h-100">
          <div class="col-sm-12 col-md-12">
            <br>  
            <div style="margin-left: 2.33%;" class="col-md-12">
                <!-- showing the selecting grade and subject button Start-->
                    <mat-chip-list aria-label="Fish selection">
                      <h1 style="margin-right: 10.33%;">Classroom activities with educational resource video.</h1>
                      <mat-chip color="primary" selected>{{LocalStorage_Grade}}</mat-chip>
                      <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
                    </mat-chip-list>
                  <!-- showing the selecting grade and subject button End-->
            </div>
           <hr>
            <!-- <div *ngIf="notSearched();" class="text-center align-middle" style="margin-top: 23%; font-size: medium;">
              Select from the left sidenav and find activites, teaching plannings and more...
            </div> -->
          <!-- <div style="margin-left: 2.33%;" class="col-md-12">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="primary" selected>{{LocalStorage_Grade}}</mat-chip>
              <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
            </mat-chip-list>
          </div>
          <hr> -->
            <img src="assets/Activity.jpeg" class="image" *ngIf="hideimage">
        <div class="container" Visible="false">
          <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="25%" *ngFor="let subtop1 of Topicwise_Data">
              <!-- click event of mat card is used to find the activity which is related to the topic  -->
              <mat-card class="example-card" (click)="Resourcefind()">
                <mat-card-header class="header">
                  <!-- <div mat-card-avatar class="example-header-image"></div> -->
                  <mat-card-title>Resource</mat-card-title>
                  <mat-card-subtitle><b>{{subtop1.Subtopic_Id}}</b></mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image src={{subtop1.Image}} alt="Photo of a Shiba Inu">
                <mat-card-content>
                  <p>
                    {{subtop1.Description}}
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <!-- View is used to find the activity which is related to the topic  -->
                  <!-- <button mat-button>View</button> -->
                  <button class="btn btn-success">View Suggested Activities</button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
      </div> 
   </div>
  </mat-drawer-content>
  <!-- it will show the data into this End-->
</mat-drawer-container>

<hr style="margin-bottom: 0;">
  <footer class="page-footer font-small bg-dark-gray">
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
  NerdNerdy.com
  </div>
  <!-- Copyright -->
  </footer>

  
<!-- For view how to make Lesson Plan Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacher%20training%20module2%20lesson%20plans.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>
<!-- For view how to make Lesson Plan End-->