import { Injectable } from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs/Observable';

const poolData = {
  UserPoolId: 'ap-south-1_KsAAfInrw', // Your user pool id here
  ClientId: '38jpluh9ohkgumr958j4oddmpr' // Your client id here  
};

const userPool = new CognitoUserPool(poolData);
let error = false;

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  cognitoUser: any;

  constructor() { }

  register(email, password,attributArray) {

    const attributeList = attributArray;

    return Observable.create(observer => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          console.log("signUp error", err);
          observer.error(err);
        }

        this.cognitoUser = result.user;
        console.log("signUp success", result);
        observer.next(result);
        observer.complete();
      });
    });

  }

  confirmAuthCode(code) {
    const user = {
      Username : this.cognitoUser.username,
      Pool : userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function(err, result) {
        if (err) {
          console.log(err);
          observer.error(err);
        }
        console.log("confirmAuthCode() success", result);
        observer.next(result);
        observer.complete();
      });
    });
  }

  signIn(email, password) { 

    const authenticationData = {
      Username : email,
      Password : password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username : email,
      Pool : userPool
    };
    const cognitoUser = new CognitoUser(userData);
    
    return Observable.create(observer => {

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          
          //console.log(result);
          observer.next(result);
          observer.complete();
        },
        onFailure: function(err) {
          console.log(err);
          observer.error(err);
        },
      });
    });
  }

  isLoggedIn() {    
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }

  logOut() {
    this.getAuthenticatedUser().signOut();
    this.cognitoUser = null;
    //for clearing the local storage data start
    // localStorage.removeItem('eq_user');
    localStorage.clear();
    console.log("data is clear",localStorage.clear());
    //for clearing the local storage data End
  }

getUserAttributes()
  {
    let cognitoUser = userPool.getCurrentUser();

    return Observable.create(observer => {
    if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {

            cognitoUser.getUserAttributes(function(err, result) {
                if (err) {
                    console.log(err);
                    observer.error(err);
                    // return;
                }
                console.log("data of user in result",result,result[4].getValue(),result[5].getValue());
                for (let i = 0; i < result.length; i++) {
                    console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
                    
                }
                var Teacher_Name = result[2].getValue();
                var User = result[5].getValue();
                var school = result[7].getValue();
                observer.next({User,school,Teacher_Name});
                console.log("teacher_id is coming", User,school,Teacher_Name);
            });
        });
       }
      });
  } 
}

  // Via Promise
  
  // getUserAttributes() 
  // {
  //   var promise = new Promise(function(resolve, reject) 
  //   { 
  //     let cognitoUser = userPool.getCurrentUser();
  //     if (cognitoUser != null) 
  //     {
  //        cognitoUser.getSession(function (err, session) 
  //        {
  //         cognitoUser.getUserAttributes(function(err, result) 
  //         {
  //           if(err) 
  //           { 
  //             reject();
  //           } 
  //           else 
  //           { 
  //             for (let i = 0; i < result.length; i++) 
  //             {
  //                   console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
  //                   var User = result[5].getValue();
  //             }
  //             resolve(User);
  //           }
  //         });
  //       });
  //     }
  //   }); 
  //     promise. 
  //       then(function () { 
  //         console.log('Success, You are Favour'); 
  //       }). 
  //       catch(function () { 
  //         console.log('Some error has occured'); 
  //       });
  // }
  
// }
