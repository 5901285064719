<app-navbar></app-navbar>

<!-- All the data into single container start-->
<mat-drawer-container class="example-container h-100">
  <!-- used for the choosing the subject and the topic start-->
  <mat-drawer mode="side" opened>
    <div class="row h-100">
      <div class="example-container col-md-12">
        <mat-card-actions style="margin-top: 2px;">
          <button type="button" (click)="GradeAllotment()" class="btn btn-secondary">Grade Allotment</button>
        </mat-card-actions>
        <br>
        <div style="margin-top: 20px;margin-bottom: 10px;">
        <button disabled class="col-md-12 btn-warning">Teacher's Performance Dashboard</button>
        </div>
        <br>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label><b>Grade</b></mat-label>
          <mat-select>
            <mat-option *ngFor="let gradeAvailable of AllGrade_Available" (click)="GradeValue(gradeAvailable)" [value]="gradeAvailable" color="accent">
                {{gradeAvailable}} 
            </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="fill" disabled>
          <mat-label><b>Subject</b></mat-label>
          <mat-select>
            <mat-option *ngFor="let subjectAvailable of AllSubject_Available" (click)="SelectingSubject(subjectAvailable)" [value]="subjectAvailable" color="accent" style="text-align: center;">
                {{subjectAvailable}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      
      <!-- through search button we can find the supervisor data which is related to that grade -->
      <mat-card-actions>
        <button type="button" (click)="Search()" class="Btn-Space btn btn-success">Submit</button>&nbsp;&nbsp;
        <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-top: 5px;">Supervisiour Dashboard Video</button>
        <!-- <button type="button" (click)="GradeAllotment()" class="btn btn-secondary">Grade Allotment</button> -->
      </mat-card-actions>
    </div>
    </div>
  </mat-drawer>
  <!-- used for the choosing the subject and the topic End-->

<!-- ngx table with edit option in the row Start -->
<mat-drawer-content>
  <div class = "row h-100">
      <div class="col-sm-12 col-md-12">
        <br>  
        <div style="margin-left: 2.33%;">
            <h1>Subject wise Data Based</h1>
        </div>
       <hr>
       <div class="columns">
         <!-- this [data] is used to fetch the data from "Supervisior" and show in the table-->
      <ngx-table [configuration]="configuration" [data]="Supervisior" [columns]="columns"> </ngx-table>
    </div>
  <!-- <ng-template #teacheridTpl let-row let-rowIndex="rowIndex">
    <button class="btn btn-success btn-sm" disabled>{{row.Teacher_id}}</button>
</ng-template> -->
<ng-template #teachernameTpl let-row let-rowIndex="rowIndex">
    <button class="btn btn-warning btn-sm" disabled>{{row.Teacher_Name}}</button>
</ng-template>
<ng-template #classperformanceTpl let-row let-rowIndex="rowIndex">
    <button class="btn btn-secondary btn-sm" title="Click to view the class performance" (click)="onClick($event,row)" value="{{row.classperformance}}">Class Performance</button>
</ng-template>
<ng-template #trainingTpl let-row let-rowIndex="rowIndex">
    <!-- circle graph start -->
          <circle-progress
                              [percent]="20"
                              [radius]="35"
                              [outerStrokeWidth]="7"
                              [innerStrokeWidth]="6"
                              [outerStrokeColor]="'#1bf5f6'"
                              [innerStrokeColor]="'#1bf6a5'"
                              [animation]="true"
                              [animationDuration]="200">
          </circle-progress>
    <!-- circle graph End -->
</ng-template>
<ng-template #questionTpl let-row let-rowIndex="rowIndex">
    <button class="btn btn-success btn-sm" title="Click to view the teacher contrition" (click)="onClickQuestion($event,row)" value="{{row.question}}">Teacher Contribution</button>
</ng-template>
<ng-template #matricsTpl let-row let-rowIndex="rowIndex">
    <button class="btn btn-warning btn-sm" disabled>{{row.matrics}}</button>
</ng-template>
<ng-template #SuperinputTpl let-row let-rowIndex="rowIndex">
  <input type="text" #Superinput class="form-label"/>
</ng-template>
</div>
  </div>
</mat-drawer-content>
</mat-drawer-container>
<!-- ngx table with edit option in the row end -->

<!-- Class Performance modal popup Start -->

<div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title h5">Class Performance Analysis</div>
        <button type="button" class="close" data-dismiss="modal" (click) = "hide()">&times;</button>
      </div>
      <div class="modal-body" style="margin: 0 auto;text-align: left;">
        <div class="row">
            <div class="col-md-6">
              <p><b>The Class Performance Table highlights the number of students falling under different performance range.</b></p>
              <div style="margin:5px; margin-top: 01%;">
                <mat-form-field appearance="fill" class="col-md-12">
                  <mat-label>Student Exam Result</mat-label>
                  <select matNativeControl required (change)="changeReportcard($event)">
                      <option [value]="null" style="align-content: center;"> ---- Choose Exam Result ---- </option>
                      <option *ngFor="let card of Reportcard" value={{card.Subject_id}} style="align-content: center;">{{card.Date}}</option>
                  </select>
                </mat-form-field>

                <div class="alert alert-info"><b>Class Performance Table</b></div>
                <div class="table-responsive">
                  <table class="table" bordered="true">
                    <thead>
                      <tr style="height: 40px;">
                        <th scope="col">Very Superior</th>
                        <td class="mat-cell" title="Click to view the very superior student name" (click)="StudentRName(StudentVS)" style="cursor: hand;cursor: pointer;">
                          {{calculationVerySuprior}}
                        </td>
                      </tr>
                      <tr style="height: 40px;">
                        <th scope="col">Superior</th>
                        <td class="mat-cell" title="Click to view the superior student name" (click)="StudentSName(StudentS)" style="cursor: hand;cursor: pointer;">
                          {{calculationSuprior}}
                        </td>
                    </tr>
                    </thead>
                      <tbody>
                        <tr style="height: 40px;">
                            <th scope="col">Above Average</th>
                            <td class="mat-cell">
                              {{calculationAboveAverage}}
                            </td>
                          </tr>
                        <tr style="height: 40px;">
                          <th scope="col">Average</th>
                          <td class="mat-cell">
                            {{calculationAverage}}
                          </td>
                        </tr>
                        <tr style="height: 40px;">
                            <th scope="col">Below Average</th>
                            <td class="mat-cell" title="Click to view the below average student name" (click)="StudentBAName(StudentBA)" style="cursor: hand;cursor: pointer;">
                              {{calculationBelowAverage}}
                            </td>
                          </tr>
                        <tr style="height: 40px;">
                          <th scope="col">Slow</th>
                          <td class="mat-cell" title="Click to view the slow student name" (click)="StudentSlowName(StudentSlow)" style="cursor: hand;cursor: pointer;">
                            {{calculationSlow}}
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            <div class="col-md-6">
              <mat-card style="margin-top: 0%;">
                <div class="table-responsive">
                  <div class="alert alert-info"><b>Student-Based Class Performance</b></div>
                   <chart [type]="type" [data]="data" [options]="options" class="barchart"></chart>
             </div>
           </mat-card>
           <br>
           <button class="btn btn-secondary" (click)="ReferenceTable(Referencetable)"  title="Click to view the reference table"><b>Reference Table</b></button>
            </div>
          </div>
      </div>
      <div class="modal-footer" style="margin-top: -25px;">
        <button (click)="hide()" style="margin-right: 80%;" class="btn btn-success"><b>Back</b></button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="hide()">Close</button>
      </div>
    </div>
  </div>
  </div>
  <!-- Class Performance modal popup End -->

  <!-- Teacher Peer Contribution modal popup Start -->

  <div class="modal" id="myModal1" [style.display]="showModal1 ? 'block' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        <div class="modal-title h5">Teacher's as knowledge partner</div>
        <button type="button" class="close" data-dismiss="modal" (click) = "Questionhide()">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
              <div style="margin:2px;">
                <div class="alert alert-warning"><b>Teacher's as knowledge partner</b></div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th *ngFor="let head of headTitle" scope="col">{{head}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr mdbTableCol>
                        <td>{{PQuestion}}</td>
                        <td>{{TDevelopment}}</td>
                        <td>{{CQuestion}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button (click)="Questionhide()" style="margin-right: 80%;" class="btn btn-success"><b>Back</b></button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "Questionhide()">Close</button>
      </div>
    </div>
  </div>
  </div>

  <!-- Teacher Peer Contribution modal popup End -->
<!-- </mat-drawer-content>
</mat-drawer-container> -->

<hr style="margin-bottom: 0;">
<footer class="page-footer font-small bg-dark-gray">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2020 Copyright:
      NerdNerdy.com
    </div>
    <!-- Copyright -->
  </footer>

  <!--Very Superior Student Name Start -->
  <ng-template #StudentVS let-modal>   
    <div class="modal-content-Name">
      <!-- <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div> -->
      <h1 class="modal-title w-100 text-center">Very Superior Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentName; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Very Superior Student Name End -->

<!--Superior Student Name Start -->
  <ng-template #StudentS let-modal>   
    <div class="modal-content-Name">
      <!-- <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div> -->
      <h1 class="modal-title w-100 text-center">Superior Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentNameSuperior; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Superior Student Name End -->

<!--Below Average Student Name Start -->
  <ng-template #StudentBA let-modal>   
    <div class="modal-content-Name">
      <!-- <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div> -->
      <h1 class="modal-title w-100 text-center">Below Average Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentNameBA; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Below Average Student Name End -->

<!--Slow Student Name Start -->
  <ng-template #StudentSlow let-modal>   
    <div class="modal-content-Name">
      <!-- <div class="modal-header"> 
        <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button> 
      </div> -->
      <h1 class="modal-title w-100 text-center">Slow Student Name</h1>
      <mat-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="height: 40px;width: 40px;">
                <th scope="col">S.NO.</th>
                <th scope="col">Student Name</th>
              </tr>
            </thead>
            <tbody>
              <tr mdbTableCol *ngFor="let card of StudentSlName; let ndx = index" style="height: 40px;width: 40px">
                <th scope="row">{{ndx+1}}</th>
                <th scope="row">{{card}}</th>
              </tr>
            </tbody>
          </table>
        </div>
          <mat-card-actions>
            <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
        </mat-card-actions>
      </mat-card>
      </div> 
    </ng-template>
<!--Slow Student Name End -->

<!--Reference Table Start -->
<ng-template #Referencetable let-modal>   
  <div class="modal-content-Name">
    <h1 class="modal-title w-100 text-center">Reference Table</h1>
    <mat-card>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr style="height: 40px;width: 40px;">
              <th *ngFor="let head of headElements" scope="col">{{head}}</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let el of elements">
              <td>{{el.Percentage}}</td>
              <td>{{el.Name}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p><b>The Reference Table is given to corelate the data.</b></p>
        <mat-card-actions>
          <button (click)="modal.dismiss('Cross click')" class="btn btn-info"><b>Back</b></button>
      </mat-card-actions>
    </mat-card>
    </div> 
  </ng-template>
<!--Very Superior Student Name End -->



<!-- For view how to use Supervisiour Dashboard Start-->

<ng-template #Viewvideo let-modal class="modal"> 
  <div class="modal-content">
    <div class="modal-header"> 
        <button type="button" class="close"
                aria-label="Close" (click)= 
                "modal.dismiss('Cross click')" class="btn btn-danger"> 
                Close
        </button> 
        <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
    </div> 
    <mat-card>
      <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacher%20training%20module2%20supervisor%20dashboard.mp4" autoplay class="videoplay" controls></video>
    </mat-card>
  </div>
</ng-template>
<!-- For view how to use Supervisiour Dashboard End-->