<app-navbar></app-navbar>
<!-- <div [style.display]="showiframe ? 'block' : 'none'" style="height: 100%;">
<iframe src="https://jumphats.com/tq-worksheets.html" height="100%" width="100%"></iframe>
</div>
<div [style.display]="showdiv ? 'block' : 'none'">Best viewed on a larger screen.</div> -->

<div id="content-desktop" style="height: 100%;">
    <button type="view" class="btn btn-primary" (click)="View_Video(Viewvideo)" style="margin-top: 5px;margin-bottom: 5px;margin-left: 10%;">Worksheet Video</button>
<iframe src="https://jumphats.com/tq-worksheets.html" height="100%" width="100%"></iframe>
</div>

<div id="content-mobile">
    <a style="font-size: 5vw;font-weight: bold;">Best viewed on a larger screen.</a>
    <br><br>
    <div class="row">
        <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/JumpHats+(Introduction).mp4" autoplay class="videoplay" controls></video>
     </div>
</div>

<!-- For view how to use Report Card Start-->

<ng-template #Viewvideo let-modal class="modal"> 
    <div class="modal-content">
      <div class="modal-header"> 
          <button type="button" class="close"
                  aria-label="Close" (click)= 
                  "modal.dismiss('Cross click')" class="btn btn-danger"> 
                  Close
          </button> 
          <!-- <h1 class="modal-title w-100 text-center">Activity</h1> -->
      </div> 
      <mat-card>
        <video src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacher%20training%20module2%20creating%20worksheets.mp4" autoplay class="videoplay" controls></video>
      </mat-card>
    </div>
  </ng-template>
  <!-- For view how to use Report Card End-->
  