import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthorizationService} from '../authorization.service';
import { Router } from '@angular/router';
import { QpDataService } from '../qp-data.service';
// import { from } from 'rxjs';
import {NgbModal}  from '@ng-bootstrap/ng-bootstrap'; 

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  Teacher_id = "";
  Teacher_Name = "";
  // school_name : any;

  constructor(private auth: AuthorizationService, private router: Router,private service:QpDataService,private modalService: NgbModal) { }
  
  success_registration:boolean= false;
  failed_registration:boolean=false;
  failed_detail='';

  ngOnInit(): void {
    
  }
  register(form: NgForm) {
    // var school_name= ((document.getElementById("school_name") as HTMLInputElement).value);
    const School_Name = document.forms["FormName"]["school_name"].value;
    const schoolid = School_Name.split(' ').join('').toLowerCase().replace(/,/g,"");
    console.log("full name of school",School_Name,schoolid);
    const email = form.value.email;
    const password = form.value.password;
    // const teacherid = form.value.name.replace(/ .*/, '') + "_" + form.value.school_name.replace(/ .*/, '') + "_" + (Math.floor((Math.random() * 100) + 1));
    const teacherid = form.value.name.replace(/ .*/, '') + "_" + School_Name.replace(/ .*/, '') + "_" + (Math.floor((Math.random() * 100) + 1));
    const attributeArray = [{
      Name : 'phone_number',
        Value : '+91' + form.value.phone_number
    },
    {
      Name: 'custom:school_name',
      // Value: form.value.school_name
      Value: schoolid
    },
    {
      Name: 'custom:teacher_id',
      Value: teacherid
    },
    {
      Name: 'name',
      Value: form.value.name
    }
  ];
  console.log("Checking data is coming or not",attributeArray , typeof attributeArray);
    this.auth.register(email, password,attributeArray).subscribe(
      (data) => {        
        console.log("Success check email for verification link",data);
        this.success_registration=true;
        this.failed_registration=false;

        if(this.success_registration=true)
        {
        this.Registration_update(form,teacherid,schoolid); // inserting the data in teacher score table
        }
      },
      (err) => {
        console.log(err);
        this.failed_detail = err.message;
        // this.error = "Registration Error has occurred";
        this.failed_registration=true;
        this.success_registration=false;

      }
    );
  }

  //update the score in the Report card score table only for Student_performance_scores
  Registration_update(form: NgForm,teacherid,schoolid)
  {
        var Teacher_score = {"School_id" : schoolid, 
        "Teacher_id" : teacherid, 
        "Teacher_Name" : form.value.name,
        // "Grade_Allocated" : [
        //   {
        //     "grade": "grade-4"
        //   },
        //   {
        //     "grade": "grade-5"
        //   }
        // ],
        "Grade_Allocated" : [
                "UKG",
                "grade-1"
            ],
        "LessonPlan_scores" : [
          {
            "UKG": 0
          },
          {
            "grade-1": 0
          }
        ],
        "Question_paper_scores" : [
          {
            "UKG": 0
          },
          {
            "grade-1": 0
          }
        ],
        "Student_performance_scores" : [
          {
            "UKG": 0
          },
          {
            "grade-1": 0
          }
        ],
        "working_on" : "register"
      };
    console.log("data of params",Teacher_score);
    this.service.Teacher_LessonScore_Update(Teacher_score).subscribe(() => {
    });
  }


  // For play the video of how to Register and login Start

  View_Video(Viewvideo)
  {
    this.modalService.open(Viewvideo); 
  }

  // For play the video of how to Register and login End

}
