import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import component Start
import { LessonPlanningComponent } from './lesson-planning/lesson-planning.component';
import { HeroesComponent } from './heroes/heroes.component';
import { SubjectwiseactComponent } from './subjectwiseact/subjectwiseact.component';
import { ResourcelinkComponent } from './resourcelink/resourcelink.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { BaseComponent } from './base/base.component';
import { TqDashboardComponent } from './tq-dashboard/tq-dashboard.component';
import { NewreportcardComponent } from './newreportcard/newreportcard.component';
import { SupervisiordashboardComponent } from './supervisiordashboard/supervisiordashboard.component';
import { JumphatsComponent } from './jumphats/jumphats.component';
import { TeacherGradeAllotmentComponent } from './teacher-grade-allotment/teacher-grade-allotment.component';
import { CoursesComponent } from './video/courses/courses.component';
import { SidebarComponent } from './video/sidebar/sidebar.component';
//import component End 

const routes: Routes = [
  { path : '', component:LoginComponent},
  { path : 'login', component:LoginComponent},
  { path : 'base', component:BaseComponent},
  { path : 'dashboard', component:TqDashboardComponent},
  { path : 'register', component:RegisterComponent},
  { path : 'question-paper', component: HeroesComponent, },
  { path : 'lessonplanning', component: LessonPlanningComponent },
  { path : 'chapterwise', component: SubjectwiseactComponent },
  { path : 'teacher-rescource', component: ResourcelinkComponent},
  { path : 'teacher-reportcard', component: NewreportcardComponent},
  { path : 'supervisiour-dashboard', component: SupervisiordashboardComponent},
  { path : 'jumphats', component: JumphatsComponent},
  { path : 'gradeallotment', component: TeacherGradeAllotmentComponent},
  { path : 'courses', component: CoursesComponent},
  { path : 'coursesvideo', component: SidebarComponent}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
