import { Component, OnDestroy, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { VideosrcService} from '../../videosrc.service';
import {Video_list} from '../../video-database';
import { LpDataexService } from '../../lp-dataex.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
data = Video_list;
video_src = "";
video_details = "";
// val:number = -1;

constructor( private VIDEOSERVICE : VideosrcService,private dataService:LpDataexService) { }

ngOnInit(): void {
  this.VIDEOSERVICE.$video_src.subscribe(res =>{
    this.video_src = res;
    console.log("showing from video",this.video_src);
  })
  this.VIDEOSERVICE.$video_details.subscribe(res =>{
    this.video_details = res;
  })
}

vid(){
  console.log("ended");
  this.VIDEOSERVICE.onVideoComplete(this.video_details);
  // video completed pushed
}

}
