<div class="container-fluid">
    <div class="row style_height_100vh">
        <div class="col-lg-12 col-md-12 style_height_100vh">


            <div class="overlay">
                <div class="background_image">
                    <!--top left Elucidata polly logo -->
                    <div id="polly_logo_image">
                        <div><img src="../assets/LOGO.png" width="120"></div>
                    </div>
                    <!--center content Heading for base component-->
                    <div id="centercontent">
                        <div class="col-md-12">

                            <div id="base_component_heading" style="color: rgb(255, 255, 255);">Deliver Better Learning Outcomes in Classroom</div>
                        </div>

                        <!--exposing carousel component-->
                        <div class="row">
                            <div class="col-md-12">
                                <app-carousel></app-carousel>
                            </div>
                        </div>


                    </div>

                    <!--bottom content - images of supporting institutions and companies-->
                    <div class="row"><br></div>
                </div>
            </div>
        </div>
    </div>
</div>