import { Hero } from './hero';
import { qtype2 } from './hero';
import { qtype3 } from './hero';

export const HEROES: Hero[] = [
    { id: 11, question: 'The constitution of India.' , qtype : 1},
    { id: 12, question: 'Space research.' , qtype : 1},
    { id: 13, question: 'Space research 2.' , qtype : 1},
    { id: 14, question: 'Space research 3.' , qtype : 1},
    { id: 15, question: 'Space research 4.' , qtype : 1},
  ];

  export  const QuestionType2 : qtype2[] = [
    { id : 16 , question: "what is capital of India ?" , qtype : 2 , option1:"delhi" , option2: " Mumbai", option3:"Gurugram"},
    { id : 17 , question: "what is capital of India ?" , qtype : 2 , option1:"delhi" , option2: " Mumbai", option3:"Gurugram"},
    { id : 18 , question: "who is PM of India ?" , qtype : 2 , option1:"Modi ji" , option2: "Abj Abdul Kamal", option3:"none of thess"},
    
  ];

  export  const QuestionType3 : qtype3[] = [
    { id : 19 , question: "He traveled all ___ the world." , qtype : 3 },
    { id : 20, question: "The PM of India is __." , qtype : 3 },
    
  ];