 <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/dashboard"><img src= "https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/nnlogo.png" alt="Photo of a Nerdnerdy logo" width="150px"></a>
    <!-- <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngbCollapse]="!toggleNavbar"> -->
      <button class="navbar-toggler" (click)="toggleNavbar()"  type="button" aria-controls="theNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <div [ngbCollapse]="isNavbarCollapsed" id="theNavbar" class="navbar-collapse collapse navbar-toggleable">
      <ul class="navbar-nav">
        <li class="nav-item"></li>
        <li class="nav-item" *ngIf="superVisorFlag==1">
          <a class="nav-link" routerLink="/supervisiour-dashboard">Supervisor Dashboard</a>
        </li>
        
        <li class="nav-item" *ngIf="superVisorFlag!=1">
          
        </li>
        
        <li ngbDropdown class="nav-item">
            <a class="nav-link" id="navbarDropdownMenuLink" ngbDropdownToggle aria-expanded="false">
              Lesson Plans
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown">
              <a class="dropdown-item" routerLink="/chapterwise">Ready Made Lesson Plans</a>
            <a class="dropdown-item" routerLink="/lessonplanning">Customized Lesson Plans</a>
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/jumphats">Create Worksheets</a>
         </li> -->
         <li class="nav-item">
        <a class="nav-link" routerLink="/question-paper">Create Question Papers</a>
      </li>
        <li class="nav-item">
          <a class="nav-link" href="/courses">Teacher Training</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/teacher-reportcard">Class Performance Analysis</a>
        </li>
        <li class= "nav-item"></li>
        
        <li>
            <button class="nav-link btn btn-primary" (click)="SignOut()">Sign Out</button>
        </li>
      </ul>
    </div>
  </nav>