import {VIDEO}  from './video-data';

export const Video_list : VIDEO[] = [ 
    {id : 1, video_name : "Introduction and Model" , video_details : "Introduction and Model" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/demo-course/Intro+and+Model.mp4",video_course:"Course1"},
    {id : 2, video_name : "Middle Childhood" , video_details : "Middle Childhood" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/demo-course/Middle+Childhood.mp4",video_course:"Course1"},
    {id : 3, video_name : "Strategies and Checklist" , video_details : "Strategies and Checklist" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/demo-course/Strategies+and+Checklist.mp4",video_course:"Course1"},
    {id : 4, video_name : "Childhood and Resources" , video_details : "Childhood and Resources" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/demo-course/Childhood+and+Resources.mp4",video_course:"Course1"},
    {id : 5, video_name : "Adolescence and Strategies" , video_details : "Adolescence and Strategies" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/demo-course/Adolescence+and+Strategies.mp4",video_course:"Course1"},

    // {id : 6, video_name : "History of Geometry" , video_details : "History of Geometry" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/2d%263d_part1.mp4",video_course:"Course2"},
    {id : 7, video_name : "Introduction of Training Module with Adjective" , video_details : "Introduction of Training Module with Adjective" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part1.mp4",video_course:"Course3"},
    // {id : 8, video_name : "Definition History and Problems with Waste Segregation" , video_details : "Definition History and Problems with Waste Segregation" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part2.mp4",video_course:"Course3"},
    // {id : 9, video_name : "Educational Learning Resource and Activities" , video_details : "Educational Learning Resource and Activities" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part3.mp4",video_course:"Course3"},
    // {id : 10, video_name : "Research and Examples of reuse of Waste Material" , video_details : "Research and Examples of reuse of Waste Material" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part4.mp4",video_course:"Course3"},
    // {id : 11, video_name : "Interdisciplinary Aspect" , video_details : "Interdisciplinary Aspect" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part5.mp4",video_course:"Course3"},
    // {id : 12, video_name : "Research paper based on Classroom Experiment" , video_details : "Research paper based on Classroom Experiment" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part6.mp4",video_course:"Course3"},
    // {id : 13, video_name : "Vocabulary Web" , video_details : "Vocabulary Web" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/waste_segregation_part7.mp4",video_course:"Course3"},
    {id : 8, video_name : "Definition History and Problems with Waste Segregation" , video_details : "Definition History and Problems with Waste Segregation" , video_src:"no video",video_course:"Course3"},
    {id : 9, video_name : "Educational Learning Resource and Activities" , video_details : "Educational Learning Resource and Activities" , video_src:"no video",video_course:"Course3"},
    {id : 10, video_name : "Research and Examples of reuse of Waste Material" , video_details : "Research and Examples of reuse of Waste Material" , video_src:"no video",video_course:"Course3"},
    {id : 11, video_name : "Interdisciplinary Aspect" , video_details : "Interdisciplinary Aspect" , video_src:"no video",video_course:"Course3"},
    {id : 12, video_name : "Research paper based on Classroom Experiment" , video_details : "Research paper based on Classroom Experiment" , video_src:"no video",video_course:"Course3"},
    {id : 13, video_name : "Vocabulary Web" , video_details : "Vocabulary Web" , video_src:"no video",video_course:"Course3"},
    //2D & 3D Shapes database
    {id : 6, video_name : "History of Geometry" , video_details : "History of Geometry" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/Training+Courses+Videos/2d+and+3d+geometry/Part+1-+2d+and+3D+shapes.mp4",video_course:"Course2"},
    {id : 14, video_name : "2D & 3D concepts-Introduction" , video_details : "2D & 3D concepts-Introduction" , video_src:"no video",video_course:"Course2"},
    {id : 15, video_name : "2D & 3D classroom Activities" , video_details : "2D & 3D classroom activities" , video_src:"no video",video_course:"Course2"},
    {id : 16, video_name : "Interdisciplinary approach Activities" , video_details : "Interdisciplinary approach Activities" , video_src:"no video",video_course:"Course2"},
    {id : 17, video_name : "Research on Shapes concept" , video_details : "Research on Shapes concept" , video_src:"no video",video_course:"Course2"},
    {id : 18, video_name : "Divergent Thinking Questions" , video_details : "Divergent Thinking Questions" , video_src:"no video",video_course:"Course2"},

    //Financial Literacy database
    {id : 19, video_name : "Commodity and Metallic form of Money" , video_details : "Commodity and Metallic form of Money" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/Training+Courses+Videos/Financial+Literacy/Fiancial+Literacy+Part+1+%26+2.mp4",video_course:"Course4"},
    {id : 20, video_name : "Plastic form of Money" , video_details : "Plastic form of Money" , video_src:"no video",video_course:"Course4"},
    {id : 21, video_name : "Cryptocurrency" , video_details : "Cryptocurrency" , video_src:"no video",video_course:"Course4"},
    {id : 22, video_name : "Classroom Activities" , video_details : "Classroom Activities" , video_src:"no video",video_course:"Course4"},
    
    //Grammar for early learners database
    {id : 19, video_name : "History Of Grammar" , video_details : "History Of Grammar" , video_src:"https://jump-hats-test.s3.ap-south-1.amazonaws.com/Training+Courses+Videos/Grammar+for+early+learners/part1+english+grammar-+Veena.mp4",video_course:"Course5"},
    {id : 20, video_name : "Activities Part-1" , video_details : "Activities Part-1" , video_src:"no video",video_course:"Course5"},
    {id : 21, video_name : "Activities Part-2" , video_details : "Activities Part-2" , video_src:"no video",video_course:"Course5"},
    
];