<carousel>
    <slide>
        <div class="text-center py-5 text-white">
            <!-- images for carousel uploaded from S3 -->
            <img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Visual-insights.png" alt="first slide" class="slider_images">

            <h2>Customised Lesson Plans</h2>
            <div class="lead">
                <!-- paragraph content -->
                <p>Curate individualised lesson plans from </p>
                <p>the best suggested activities and ideas </p>
                <p> that address the individualizes learning </p>
                <p> needs of your students.</p>
                <br>
            </div>
        </div>
    </slide>
    <slide>
        <div class="text-center py-5 text-white">
            <!-- images for carousel uploaded from S3 -->
            <img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Tweakalgorithms.png" alt="first slide" class="slider_images">
            <h2> Teacher Progress Monitoring of </h2>
            <h2> Classroom instructional practices</h2>
            <div class="lead">
                <!-- paragraph content -->
                <p>Track teacher's progress at </p>
                <p>individual and peer level,to</p>
                <p>identify the need of instructional </p>
                <p>change in the classroom</p>
                <p>to prompt better learning</p>
                <br>
            </div>
        </div>
    </slide>
    <slide>
        <div class="text-center py-5  text-white">
            <!-- images for carousel uploaded from S3 -->
            <img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Reproducibleanalysis.png" alt="first slide" class="slider_images">
            <h2>Teacher Training Courses</h2>
            <!-- paragraph content -->
            <div class="lead">
                <p>Teachers to learn anytime anywhere</p>
                <p>at their own pace!!</p>
                <!-- <p>NEP Curriculum-based,</p>
                <p>Teacher Training Courses on different</p>
                <p>grade level topics help reskill</p>
                <p>the teachers thus improves engagment</p>
                <p>of students in classroom learning.</p>
                <p>Improves student engagement</p>
                <p>in the classroom</p> -->
                <br>
            </div>
        </div>
    </slide>
    <!-- <slide>
        <div class="text-center py-5 text-white"> -->
            <!-- images for carousel uploaded from S3 -->
            <!-- <img src="../https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Tweakalgorithms.png" alt="first slide" class="slider_images">
            <h2>Adaptive Faculty Management</h2>
            <div class="lead"> -->
                <!-- paragraph content -->
                <!-- <p>Track faculty progress on </p>
                <p>individual and peer faculty level,</p>
                <p>to have transparency in </p>
                <p>required Teaching improvement</p>
                <br>
            </div>
        </div>
    </slide> -->
    <!-- <slide>
        <div class="text-center py-5 text-white"> -->
            <!-- images for carousel uploaded from S3 -->
            <!-- <img src="../https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Customiseandcollaborate.png" alt="first slide" class="slider_images">
            <h2>Seamless Assessing system</h2> -->
            <!-- paragraph content -->
            <!-- <div class="lead">
                <p>Create Custom Assignments</p>
                <p>Create customized worksheets,</p>
                <p>test papers, assignments</p>
                <p>to enhance individualized and</p>
                <p>divergent thinking skill in students</p>
                <br>
            </div>
        </div>
    </slide> -->
    <slide>
        <div class="text-center py-5 text-white">
            <!-- images for carousel uploaded from S3 -->
            <img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/Reproducibleanalysis.png" alt="first slide" class="slider_images">
            <h2>Teacher Learning Resources</h2>
            <!-- paragraph content -->
            <div class="lead">
                <p>Unique, Research Backed Resources to</p>
                <p>empower teachers to impart</p>
                <p>better classroom learning</p>
                <p>experience to students</p>
                <br>
            </div>
        </div>
    </slide>
</carousel>