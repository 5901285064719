 <!-- trail for the ngx table with edit option in the row start -->
<div class="columns">
  <div class="column col-12">
    <ngx-table [configuration]="configuration" [data]="Planlesson" [columns]="columns"> </ngx-table>
  </div>
</div>
<ng-template #idTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.id }}</div>
  <div *ngIf="editRow === rowIndex">
    <input type="number" #id class="form-label" [value]="row.id" />
  </div>
</ng-template>
<ng-template #teacherTpl ngx-datatable-cell-template let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.teacher }}</div>
  <div *ngIf="editRow === rowIndex">
    <input type="text" #teacher class="form-label" [value]="row.teacher" />
  </div>
</ng-template>
<ng-template #categoryTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.category }} </div>
  <!-- <div *ngIf="editRow === rowIndex"> -->
    <div *ngIf="editRow === rowIndex">
      <input type="string" #category class="form-label" [value]="row.category" class="category"/>
    </div>
  <!-- </div> -->
</ng-template>
<ng-template #subcatTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.subcat }}</div>
  <div *ngIf="editRow === rowIndex">
    <input type="string" #subcat class="form-label" [value]="row.subcat" class="subcat"/>
  </div>
</ng-template>
<ng-template #educationTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.education }}</div>
  <div *ngIf="editRow === rowIndex">
    <input type="string" #education class="form-label" [value]="row.education"/>
  </div>
</ng-template>
<ng-template #activityTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex" class="searchtextarea">{{  row.activity }}</div>
  <div *ngIf="editRow === rowIndex">
    <textarea type="string" #activity class="form-label" [value]="row.activity" class="textarea"></textarea>
  </div>
</ng-template>
<ng-template #dateTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex">{{ row.date }}</div>
  <div *ngIf="editRow === rowIndex">
    <input type="text" #date class="form-label" [value]="row.date" class="date"/>
  </div>
</ng-template>
<ng-template #actionTpl let-row let-rowIndex="rowIndex">
  <div *ngIf="editRow !== rowIndex" class="btn btn-primary" (click)="editrow(rowIndex)">Edit</div>
  <div *ngIf="editRow === rowIndex" class="btn btn-success" (click)="updatengx()">Save</div>
</ng-template> 
<ng-template #deleteTpl let-row let-rowIndex="rowIndex">
  <!-- <div class="btn btn-danger" (click)="deleter(row)" >Delete</div> -->
  <div class="btn btn-danger" (click)="deleter(deletelessonplan)" >Delete</div>

<!-- For choosing the value from previous report card Start -->
<ng-template #deletelessonplan let-modal style="align-content: center;">   
  <div class="modal-content">
    <div class="modal-header"> 
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-danger"> 
      Close
  </button> 
    </div>
    <h1 class="modal-title w-100 text-center">Are you sure you want to delete this activity.</h1>
    <mat-card>
        <mat-card-actions>
          <button (click)="DeleteLessonPlan(row);modal.close('Yes click')" class="btn btn-success"><b>Yes</b></button>&nbsp;&nbsp;
          <button (click)="modal.dismiss('Cross click')" class="btn btn-danger"><b>No</b></button>
      </mat-card-actions>
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from previous report card Start -->

</ng-template>
<!-- trail for the ngx table with edit option in the row end -->



