import { Component, OnInit , TemplateRef, ViewChild } from '@angular/core';
import { QpDataService } from '../qp-data.service';
import { HttpParams } from "@angular/common/http";
import {Router} from '@angular/router'; 
import { LpDataexService } from '../lp-dataex.service';
import {AuthorizationService} from '../authorization.service';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tq-dashboard',
  templateUrl: './tq-dashboard.component.html',
  styleUrls: ['./tq-dashboard.component.css']
})
export class TqDashboardComponent implements OnInit {

  Allpaper=[];
  LessonPlan=[];
  Teacher_id = "";
  Teacher_Name = "";
  // Grade_Allocated = "";
  Grade_Allocated = [];
  Subject_Allocated = [];
  AllTeacherData: any;

// used in the grap for showing the data of student performance
  calculationVerySuprior: number = 0;
  calculationSuprior: number = 0;
  calculationAboveAverage: number = 0;
  calculationAverage: number = 0;
  calculationBelowAverage: number = 0;
  calculationSlow: number = 0;
  Reportcard = [];
  isPushed: boolean = false;

  Gradewise_Subject = [];

course_src="../../assets/course1.jpg";
course_completion;

show_alloted_grade:boolean = true;
show_gradewise_subject:boolean = false;
Selected_Grade_Lable = "";
School_id = "";

  constructor(private service:QpDataService,private router: Router,private DataService:LpDataexService, private auth:AuthorizationService,private modalService: NgbModal) { }

  @ViewChild('gradecontent', { static: true }) content: TemplateRef<any>;

  ngOnInit(): void {
    this.auth.getUserAttributes().subscribe(result => {        
        console.log("Success",result);
        if(result != "")
        {
          // this.Teacher_id = result ;
          this.Teacher_id = result["User"];
          this.School_id = result["school"];
          console.log("Success id",this.Teacher_id,this.School_id);
          
          let params = new HttpParams();
          params = params.append('School_id', this.School_id);
          params = params.append('Teacher_id', this.Teacher_id);
          this.service.getteacherdata(params).subscribe(response => {
          this.AllTeacherData = response["Item"];
          this.Teacher_Name = response["Item"]["Teacher_Name"];
          // this.Grade_Allocated = response["Item"]["Grade_Allocated"];
          this.Grade_Allocated = response["Item"]["Grade_Allocated"];
          this.Subject_Allocated = response["Item"]["Subject_Allocated"];
          console.log("object key of Subject allocated",this.Grade_Allocated,this.Subject_Allocated);
        //Step 1 :- The format of Subject_Allocated is :- {"grade-1":["English","Hindi"],"UKG":["EVS","English"]}
        // we get only subject of each grade which is present .

          console.log("all grade is coming", typeof  this.AllTeacherData,this.AllTeacherData,this.Teacher_Name,this.Grade_Allocated);
          });
          
        }
        (err) => {
          console.log(err);
        }
      });
      let storage_grade = localStorage.getItem("GradeSource");
      let storage_subject = localStorage.getItem("SubjectSource");
      console.log("store grade and subject",storage_grade,storage_subject);
      if(storage_grade == null && storage_subject == null)
      {
        this.modalService.open(this.content);
      }
      else if(storage_grade != null && storage_subject != null)
      {
        this.auth.getUserAttributes().subscribe(result => {        
          console.log("Success",result);
          if(result != "")
          {
            // this.Teacher_id = result ;
            this.Teacher_id = result["User"];
            console.log("Success teacher id",this.Teacher_id);
          // getting the Question bank data Start
          let params = new HttpParams();
          params = params.append('Teacher_id', this.Teacher_id);
          params = params.append('Question_paper_id', null);
          params = params.append('Grade', storage_grade);
          params = params.append('Subject', storage_subject);
          console.log("data format", params,typeof params);
          this.service.getQuestionBank(params).subscribe(response => { 
            console.log("checking the data",response);
            this.Allpaper = response["Items"];
            console.log(typeof response)
            console.log(typeof this.Allpaper,"paper getting", this.Allpaper);
          });
          // getting the Question bank data End
    
          // getting the lesson plan Start
          let paramslesson = new HttpParams();
          paramslesson = paramslesson.append('Teacher_id', this.Teacher_id);
          paramslesson = paramslesson.append('Grade', storage_grade);
          paramslesson = paramslesson.append('Subject', storage_subject);
          console.log("data format", paramslesson,typeof paramslesson);
          this.service.Lessonplanget(paramslesson).subscribe(response => {
          this.LessonPlan = response["Items"];
          });
          // getting the lesson plan End
    
          // getting the Student Performance Analysis data Start
            let Student_Per_Analysis = new HttpParams();
            Student_Per_Analysis = Student_Per_Analysis.append('Teacher_id', this.Teacher_id);
            Student_Per_Analysis = Student_Per_Analysis.append('Subject_id', null);
            Student_Per_Analysis = Student_Per_Analysis.append('Grade', storage_grade);
            Student_Per_Analysis = Student_Per_Analysis.append('Subject', storage_subject);
            console.log("data format", Student_Per_Analysis,typeof Student_Per_Analysis);
            this.service.StudentPerformanceget(Student_Per_Analysis).subscribe(response => { 
              console.log("checking the data",response);
              this.Reportcard = response["Items"];
              console.log(typeof this.Reportcard,"paper getting", this.Reportcard);
            });
            // getting the Student Performance Analysis data Start
          }
        });
      }
      // for videos
      this.course_src="../../assets/course1.jpg";
      this.service.Video_get().subscribe(response => {
        this.course_completion=response["Item"]["course_completed"];
        console.log(this.course_completion);

      });
  }
  type = 'bar';
  data = {
    labels: [" ","Very Superior","Above Average", "Below Average", "Slow Learner"],
    // labels: [" ","Superior","VerySuperior","Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          // label: "Student Based Class Performance Analysis",
          label: "Student",
          // data: [0, 0, 0, 0],
          // data: [" ",this.calculationVerySuprior,this.calculationBelowAverage, this.calculationSlow],
          data: [" ",this.calculationVerySuprior,this.calculationAverage,this.calculationBelowAverage, this.calculationSlow],
          backgroundColor: 'rgb(10, 14, 214)',
          borderColor: 'rgb(10, 14, 214)',
        }
      ],  
    };
  options = {
  responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero:true,
              max:30,
              stepSize: 3,
          },
          scaleLabel: {
            display: true,
            labelString: 'No. Of Students'
          }
      }],
    }
  };

  ClickImage(event: any, Question_paperid:any) 
  {
        console.log("id",Question_paperid);
        this.DataService.publishData(Question_paperid);
        // this.router.navigate(['/questionbank']);
  }

  ClickLesson(event: any, Lesson_plan_id:any) 
  {
        console.log("id",Lesson_plan_id);
        this.DataService.publishData(Lesson_plan_id);
        // this.router.navigate(['/questionbank']);
  }

  // Choosing the Result from dropdown of "Student Report Card" Start
  changeReportcard(Subject_id)
  {
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', Subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
          this.calculationAboveAverage= response["Item"]["Student_Result"][1]["AboveAverage"];
          this.calculationBelowAverage= response["Item"]["Student_Result"][1]["BelowAverage"];
          this.calculationVerySuprior= response["Item"]["Student_Result"][1]["VerySuprior"];
          this.calculationSuprior= response["Item"]["Student_Result"][1]["Suprior"];
          this.calculationAverage= response["Item"]["Student_Result"][1]["Average"];
          this.calculationSlow= response["Item"]["Student_Result"][1]["Slow"];
          this.data = {
            labels: [" ","Very Superior","Above Average", "Below Average", "Slow Learner"],
          datasets: [
            {
              label: "Student",
              data: [" ",this.calculationVerySuprior,this.calculationAverage,this.calculationBelowAverage, this.calculationSlow],
              backgroundColor: 'rgb(10, 14, 214)',
              borderColor: 'rgb(10, 14, 214)',
            }
          ],  
        };
          this.options;
    });
  }
  // Choosing the Result from dropdown of "Student Report Card" End

  AddTeacherData()
  {

  }

  //trail for changing the subject according to grade Start
  Change_Subject_Acc_Grade(alloted_grade:any)
  {
    this.Gradewise_Subject = [];
    this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
    console.log(this.Gradewise_Subject,"grade wise subject is coming");
    let grade = alloted_grade;
    this.Selected_Grade_Lable = alloted_grade ;
    localStorage.setItem('GradeSource', grade);
    console.log("set the data in local", grade,this.Selected_Grade_Lable);
    this.show_alloted_grade = !this.show_alloted_grade;
    this.show_gradewise_subject = true;
  }
  //trail for changing the subject according to grade End

  SelectingSubject(Subject: any)
  {
    let subject = Subject;
    localStorage.setItem('SubjectSource', subject);
    let storage_grade = localStorage.getItem("GradeSource");
    console.log("set the data in local", subject,storage_grade);
    if(storage_grade != null && subject != null)
    {
      // getting the Question bank data Start
      let gradevalue = localStorage.getItem("GradeSource");
      console.log("data of local", gradevalue);
      let params = new HttpParams();
      params = params.append('Teacher_id', this.Teacher_id);
      params = params.append('Question_paper_id', null);
      params = params.append('Grade', gradevalue);
      params = params.append('Subject', subject);
      console.log("data format", params,typeof params);
      this.service.getQuestionBank(params).subscribe(response => { 
        console.log("checking the data",response);
        this.Allpaper = response["Items"];
        console.log(typeof response)
        console.log(typeof this.Allpaper,"paper getting", this.Allpaper);
      });
      // getting the Question bank data End

      // getting the lesson plan Start
      let paramslesson = new HttpParams();
      paramslesson = paramslesson.append('Teacher_id', this.Teacher_id);
      paramslesson = paramslesson.append('Grade', gradevalue);
      paramslesson = paramslesson.append('Subject', subject);
      console.log("data format", paramslesson,typeof paramslesson);
      this.service.Lessonplanget(paramslesson).subscribe(response => {
      this.LessonPlan = response["Items"];
      });
      // getting the lesson plan End

      // getting the Student Performance Analysis data Start
        let Student_Per_Analysis = new HttpParams();
        Student_Per_Analysis = Student_Per_Analysis.append('Teacher_id', this.Teacher_id);
        Student_Per_Analysis = Student_Per_Analysis.append('Subject_id', null);
        Student_Per_Analysis = Student_Per_Analysis.append('Grade', gradevalue);
        Student_Per_Analysis = Student_Per_Analysis.append('Subject', subject);
        console.log("data format", Student_Per_Analysis,typeof Student_Per_Analysis);
        this.service.StudentPerformanceget(Student_Per_Analysis).subscribe(response => { 
          console.log("checking the data",response);
          this.Reportcard = response["Items"];
          console.log(typeof this.Reportcard,"paper getting", this.Reportcard);
        });
        // getting the Student Performance Analysis data Start
    }
  }

  //Back to grade
  Backto_Grade_Allocated()
  {
    this.show_alloted_grade = true;
    this.show_gradewise_subject = false;
  }

}
