export interface Gradewise {
    id:number;
    grade : string;
}

export interface Subjectwise {
    Sid:number;
    subject : string;
    gradeid:number;
}
export interface Supervisiordata {
    Supid:number;
    teacherid:string;
    teachername : string;
    classperformance : number;
    training: number;
    question: number;
    matrics: number;
    gradeid: number;
    Sid: number;
}

export const GRADE: Gradewise[] = [
     { id: 1, grade: 'Grade1'},
      { id: 2, grade: 'Grade2'},
      { id: 3, grade: 'Grade3'},
];

export const SUBJECT: Subjectwise[] = [
  { Sid: 1, subject : 'English' , gradeid : 1},
  { Sid: 2, subject : 'Math' , gradeid : 1},
  { Sid: 3, subject : 'English' , gradeid : 2},
  { Sid: 4, subject : 'EVS' , gradeid : 2},
  { Sid: 5, subject : 'English' , gradeid : 3},
  { Sid: 6, subject : 'Math' , gradeid : 3}, 
];

export const SUPERVISIOR: Supervisiordata[] = [
  { Supid: 1, teacherid : 'TI_001' ,teachername :'Anjali Verma',classperformance:55, training:35, question:+3, matrics:50, gradeid : 1,Sid :1},
    { Supid: 2, teacherid : 'TI_002' ,teachername :'Pooja',classperformance:65, training:50, question:+2.5, matrics:55, gradeid : 1,Sid :2},
    { Supid: 3, teacherid : 'TI_003' ,teachername :'Vinod',classperformance:90, training:90, question:+3.5, matrics:83.4, gradeid : 2,Sid :3},
    { Supid: 4, teacherid : 'TI_004' ,teachername :'Poonam',classperformance:75, training:45, question:+2, matrics:60, gradeid : 2,Sid :4},
    { Supid: 5, teacherid : 'TI_005' ,teachername :'Ajay',classperformance:45, training:40, question:+1.5, matrics:50, gradeid : 3,Sid :5},
    { Supid: 6, teacherid : 'TI_006' ,teachername :'Amit Kumar',classperformance:85, training:85, question:+4.5, matrics:87, gradeid : 3,Sid :6}, 
];


