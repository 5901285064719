import { Component, OnInit } from '@angular/core';
import { QpDataService } from '../../qp-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router'; 
import { LpDataexService } from '../../lp-dataex.service';
import { VideosrcService} from '../../videosrc.service';
import {Video_list} from '../../video-database';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  course_src="../../assets/course1.jpg";
  course_completion;
  hide_content_middle:boolean = true;
  show_courses:boolean = false;
  show_waste_segregation_courses:boolean = false;
  show_2d_3d_courses:boolean = false;
  show_financial_courses:boolean = false;
  show_grammar_courses:boolean = false;
  LocalStorage_Grade = "";
  LocalStorage_Subject = "";
  hideimage:boolean = true;
  SelectCourse: any;
  data = Video_list;

    constructor(private service:QpDataService, private http:HttpClient,private DataService: LpDataexService, private router: Router,private VIDEOSERVICE : VideosrcService,private modalService: NgbModal) { }
  
    ngOnInit(): void {
      this.LocalStorage_Grade = localStorage.getItem("GradeSource");
     this.LocalStorage_Subject = localStorage.getItem("SubjectSource");
    }

  SearchCourse()
  {
    console.log("value of dropdown",this.SelectCourse);
      if(this.SelectCourse == "Course1")
      {
        this.show_courses = true;
        this.show_waste_segregation_courses = false;
        this.show_2d_3d_courses = false;
        this.hide_content_middle = false;
        // extra courses added
        this.show_financial_courses = false;
        this.show_grammar_courses = false;

        this.course_src="../../assets/course1.jpg";
        this.service.Video_get().subscribe(response => {
          this.course_completion=response["Item"]["course_completed"];
          this.hideimage = false;
          console.log(this.course_completion);
        });
      }
     else if(this.SelectCourse == "Course2")
      {
        this.show_2d_3d_courses = true;
        this.show_waste_segregation_courses = false;
        this.show_courses = false;
        this.hide_content_middle = false;
        // extra courses added
        this.show_financial_courses = false;
        this.show_grammar_courses = false;
        this.course_src="../../assets/course1.jpg";
        this.service.Video_get().subscribe(response => {
          this.course_completion=response["Item"]["course_completed"];
          this.hideimage = false;
          console.log(this.course_completion);
        });
      }
      else if(this.SelectCourse == "Course3")
      {
        this.show_waste_segregation_courses = true;
        this.show_2d_3d_courses = false;
        this.show_courses = false;
        this.hide_content_middle = false;
        // extra courses added
        this.show_financial_courses = false;
        this.show_grammar_courses = false;
        this.course_src="../../assets/course1.jpg";
        this.service.Video_get().subscribe(response => {
          this.course_completion=response["Item"]["course_completed"];
          this.hideimage = false;
          console.log(this.course_completion);
        });
      }
      else if(this.SelectCourse == "Course4")
      {
        this.show_financial_courses = true;
        this.show_2d_3d_courses = false;
        this.show_courses = false;
        this.hide_content_middle = false;
        this.show_waste_segregation_courses = false;
        this.show_grammar_courses = false;
        this.course_src="../../assets/course1.jpg";
        this.service.Video_get().subscribe(response => {
          this.course_completion=response["Item"]["course_completed"];
          this.hideimage = false;
          console.log(this.course_completion);
        });
      }
      else if(this.SelectCourse == "Course5")
      {
        this.show_grammar_courses = true;
        this.show_2d_3d_courses = false;
        this.show_courses = false;
        this.hide_content_middle = false;
        this.show_waste_segregation_courses = false;
        this.show_financial_courses = false;
        this.course_src="../../assets/course1.jpg";
        this.service.Video_get().subscribe(response => {
          this.course_completion=response["Item"]["course_completed"];
          this.hideimage = false;
          console.log(this.course_completion);
        });
      }
  }

  //going to the course video page start
  SubmitCourse()
  {
    var selectcoursedata = {"course":this.SelectCourse};
    console.log("data is coming",selectcoursedata);
    var course = JSON.stringify(selectcoursedata);
    this.DataService.publishData(course);
    // addintion
    var Coursedataobj = JSON.parse(course);
    this.data = this.data.filter(course => course.video_course == Coursedataobj.course);
    this.VIDEOSERVICE.onSrcChange(this.data[0].video_src,this.data[0].video_details);
    // end of addition
    this.router.navigate(['/coursesvideo']);
  }
  //going to the course video page End

  // For play the video of how to use Teacher Training Courses Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Teacher Training Courses End

}
