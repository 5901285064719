declare const Buffer
import { Component, OnInit , TemplateRef, ViewChild ,ElementRef } from '@angular/core';
import {Hero} from '../hero';
import { HEROES } from '../mock-heroes';
import {qtype2} from '../hero';
import { QuestionType2 } from '../mock-heroes';
import {qtype3} from '../hero';
import { QuestionType3 } from '../mock-heroes';
import {jsPDF}  from 'jspdf';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap'; 
import {AuthorizationService} from '../authorization.service';
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun , Table } from "docx";
import { saveAs } from 'file-saver';
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import { LpDataexService } from 'src/app/lp-dataex.service';


@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.css']
})
export class HeroesComponent implements OnInit {
  opened: boolean = true;

  Header =true;
  EditingHeader = false;
  closeResult = '';
  SchoolName = "";
  Paper_H1 = "";
  date = "";
  Max_time = "";
  TestMarks = "";
  id = "123";
  Question_paperid = "";
  Paperid = "";
  clone_n = HEROES; //original
  n = this.clone_n.map(x=>Object.assign({},x)); //deep copy trial
  selectedHero: Hero;
  

  question_type2 = QuestionType2;
  type2 = this.question_type2.map(x=>Object.assign({},x));
  selectedQuestion : qtype2;

  question_type3 = QuestionType3;
  type3 = this.question_type3.map(x=>Object.assign({},x));
  selectedFillQuestion : qtype3;
  
  Question = [];

  
  TextareaShow:boolean = false;
  buttonName:any = 'Long & Short Question';
  optiontype1:boolean = false;
  optiontype2:boolean = false;
  optiontype3:boolean = false;
  buttonNameoption:any = 'Option Type Question';
  showtext:boolean = false;
  showOption:boolean = false;
  markoptain:boolean = false;
  updatebtn:boolean = false;
  type1question:boolean =true;
  type2question:boolean =true;
  type3question:boolean =true;

  editable1:boolean = false;
  editable2:boolean = false;
  editable3:boolean = false;

  saveMatchQtype = false;
  today: number = Date.now();
  //for getting all paper
  Allpaper=[];

  Teacher_id = "";
  Type1_id = "";
  editing_question = "";
  markedit = "";
  optiontype1dit = "";
  optiontype2dit = "";
  optiontype3dit = "";

  optionmatchop1 = "";
  optionmatchop2 = "";
  optionmatchop3 = "";
  optionmatchop4 = "";
  optionmatchop5 = "";
  optionmatchop6 = "";
  optionmatchop7 = "";
  optionmatchop8 = "";

  // Teacheridgetting = this.auth.getUserAttributes();

  // Variables for match the following
  matchop1=false; matchop2=false; matchop3=false; matchop4=false; matchop5=false; matchop6=false; matchop7=false; matchop8=false;
 // match the following
 smatchop1 = ""; smatchop2=""; smatchop3=""; smatchop4=""; smatchop5=""; smatchop6=""; smatchop7=""; smatchop8="";

 button_content:string = "< Less Options";

 Subject_Allocated = [];
 Gradewise_Subject = [];

 show_alloted_grade:boolean = true;
show_gradewise_subject:boolean = false;
Selected_Grade_Lable = "";
LocalStorage_Subject = "";
showsidenavbar = true;

  constructor(private modalService: NgbModal, private auth:AuthorizationService,private service:QpDataService, private http:HttpClient,private dataService:LpDataexService) { }

Teacher_Name = ""; // used in the modal (gradecontent)
Grade_Allocated = ""; // for getting the grade value in modal (gradecontent)
@ViewChild('gradecontent', { static: true }) content: TemplateRef<any>;

  ngOnInit() 
  {
    let gradevalue = localStorage.getItem("GradeSource"); // Storing the grade value from local storage.
    let subjectvalue = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Selected_Grade_Lable = localStorage.getItem("GradeSource");
    this.LocalStorage_Subject = localStorage.getItem("SubjectSource");
    console.log("Choosing grade is coming", gradevalue,subjectvalue);
    this.Paper_H1 = subjectvalue;
    if(gradevalue != undefined && subjectvalue != undefined)
    {
    this.auth.getUserAttributes().subscribe(result => {        
        console.log("Success",result);
        if(result != ""){
        this.Teacher_id = result["User"];
        this.SchoolName = result["school"];
        console.log("Success id",this.Teacher_id,this.SchoolName);

        let params = new HttpParams();
        params = params.append('Teacher_id', this.Teacher_id);
        params = params.append('Question_paper_id', this.Question_paperid);
        console.log("data format", params,typeof params);
        this.service.getQuestionBank(params).subscribe(response => {
          
          this.date = response["Item"]["Date"];
          this.Max_time = response["Item"]["Max_time"];
          this.TestMarks = response["Item"]["Max_mark"];
          this.Paper_H1 = response["Item"]["Subject"];
          this.SchoolName = response["Item"]["School_name"];
          this.Question=response["Item"]["Question_paper"];
          console.log("type of question",this.Question);
          console.log("Question Id",response["Item"]["Question_PaperId"]);

        });

        let params1 = new HttpParams();
        params1 = params1.append('Teacher_id', this.Teacher_id);
        params1 = params1.append('Question_paper_id', null);
        params1 = params1.append('Grade', gradevalue);
        params1 = params1.append('Subject', subjectvalue);
        console.log("data format", this.Teacher_id,gradevalue,typeof params1);
        this.service.getQuestionBank(params1).subscribe(response => { 
          console.log("checking the data",response);
          this.Allpaper = response["Items"];
          console.log(typeof response)
          console.log(typeof this.Allpaper,"paper getting", this.Allpaper);
        });

        // for changing the grade 
        let dropdowngrade = new HttpParams();
        dropdowngrade = dropdowngrade.append('School_id', this.SchoolName);
        dropdowngrade = dropdowngrade.append('Teacher_id', this.Teacher_id);
        this.service.getteacherdata(dropdowngrade).subscribe(response => {
        this.Grade_Allocated = response["Item"]["Grade_Allocated"];
        this.Subject_Allocated = response["Item"]["Subject_Allocated"];
        console.log("all grade is coming in dropdown", this.Grade_Allocated,this.Subject_Allocated);
        });
      }
      (err) => {
        console.log(err);
      }
    });
  }
  else if(gradevalue == null)
  {
    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != ""){
        this.Teacher_id = result["User"];
        console.log("result of user",this.Teacher_id);
        this.modalService.open(this.content);
        let params = new HttpParams();
        params = params.append('School_id', this.SchoolName);
        params = params.append('Teacher_id', this.Teacher_id);
        this.service.getteacherdata(params).subscribe(response => {
        // this.Teacher_Name = response["Item"]["Teacher_Name"];
        this.Teacher_Name = response["Item"]["Teacher_Name"];
        this.Grade_Allocated = response["Item"]["Grade_Allocated"];
        this.Subject_Allocated = response["Item"]["Subject_Allocated"];
        console.log("all grade is coming",this.Teacher_Name,this.Grade_Allocated,this.Subject_Allocated);
        });
      }
    });
  }
    
    this.dataService.$sharedValue.subscribe(result =>{
      console.log("Pushing activity working", result);
      if(result != "activity"){
      console.log("data is coming",result);
      this.Question_paperid = result;
      console.log("id",this.Question_paperid);
    }
    });
    
  }

  // it will side navbar Start
  checkSideNavOpen()
  {
    this.showsidenavbar = !this.showsidenavbar;
    // if(this.opened)
    // {
    //   this.button_content = "More Options>";
    // }
    // else
    // {
    //   this.button_content = "<Less Options";
    // }
  }
  // it will side navbar End

  // it was used to edit the header Start
  headerChange()
  {
    this.EditingHeader = true;
    this.Header =false;
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Paper_H1 = storage_grade;
  }
  // it was used to edit the header End

  // it was used to Update the header Start
  ChangedHeader()
  {
    this.EditingHeader = false;
    this.Header = true;
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Paper_H1 = storage_grade;
  }
  // it was used to Update the header End


  onSelect(hero: Hero): void 
  {
    for(var i=0;i<=this.clone_n.length;i++)
    {
      if(hero.id === this.n[i].id)
      {
        //pushed using deep copy
        this.Question.push(this.n[i]);
        // post udpatate  deep copy value changes hence new push results in deep copy 
      }
    }
  }
  
  onSelectQues(ques: qtype2): void
  {
    for(var i=0;i<=this.question_type2.length-1;i++){
      if(ques.id === this.type2[i].id){
        this.Question.push(this.type2[i]);
      }
    }
    this.selectedQuestion = ques;
  }

  onSelectFillQues(ques3: qtype3): void
  {
    for(var i=0;i<=this.question_type3.length-1;i++){
      if(ques3.id === this.type3[i].id){
        this.Question.push(this.type3[i]);
      }
    }
   this.selectedFillQuestion = ques3;
  }

  //Delete event is used in all type of question for Start
  deleter(ques:any)
  {
    for( var i=0;i<=this.Question.length-1;i++){
      if(this.Question[i].id === ques.id){
          this.Question.splice(i,1);
          break;
      }
    }
  }
  //Delete event is used in all type of question for End

  //edit question for all type Start
  edit(contenteidt,ques)
  {
    console.log(ques.question);
    this.editing_question = ques.question;
    this.Type1_id = ques.id;
    if(ques.qtype == 1)
    {
      this.modalService.open(contenteidt); 
      this.editing_question = ques.question;
      this.markedit = ques.mark;
    }
  }
  //edit question for all type End

  edit2(contenteidt2,ques)
  {
    console.log(ques.question);
    this.editing_question = ques.question;
    this.Type1_id = ques.id;
    if(ques.qtype == 2)
    {
      this.modalService.open(contenteidt2); 
      this.editing_question = ques.question;
      this.markedit = ques.mark;
      this.optiontype1dit = ques.option1;
      this.optiontype2dit = ques.option2;
      this.optiontype3dit = ques.option3;
    }
  }
  //edit question for all type End

  edit3(contenteidt3,ques)
  {
    console.log(ques.question);
    this.editing_question = ques.question;
    this.Type1_id = ques.id;
    if(ques.qtype == 3)
    {
      this.modalService.open(contenteidt3); 
      this.editing_question = ques.question;
      this.markedit = ques.mark;
      this.optionmatchop1 = ques.matchop1;
      this.optionmatchop2 = ques.matchop2;
      this.optionmatchop3 = ques.matchop3;
      this.optionmatchop4 = ques.matchop4;
      this.optionmatchop5 = ques.matchop5;
      this.optionmatchop6 = ques.matchop6;
      this.optionmatchop7 = ques.matchop7;
      this.optionmatchop8 = ques.matchop8;
      
    }
  }
  //edit question for all type End

  // update question for all type Start
  update()
  {
    for(var i=0;i<=this.Question.length -1;i++)
        {
          var id_get = this.Question[i].id.toString();
            if (this.Question[i].qtype == 1) 
            { 
                if(this.Type1_id == id_get)
                {
                  var textcontrol= ((document.getElementById("typequestion") as HTMLInputElement).value);
                  var marktype= ((document.getElementById("typemark") as HTMLInputElement).value);

                  this.Question[i].question = textcontrol;
                  this.Question[i].mark = marktype;
                }
            }
            else if (this.Question[i].qtype == 2) 
            { 
                if(this.Type1_id == id_get)
                {
                  var textcontrol= ((document.getElementById("typequestion") as HTMLInputElement).value);
                  var marktype= ((document.getElementById("typemark") as HTMLInputElement).value);
                  var newoption1= ((document.getElementById("option1") as HTMLInputElement).value);
                  var newoption2= ((document.getElementById("option2") as HTMLInputElement).value);
                  var newoption3= ((document.getElementById("option3") as HTMLInputElement).value);

                  this.Question[i].question = textcontrol;
                  this.Question[i].mark = marktype;
                  this.Question[i].option1 = newoption1;
                  this.Question[i].option2 = newoption2;
                  this.Question[i].option3 = newoption3;
                }
            }
            else if (this.Question[i].qtype == 3) 
            { 
                if(this.Type1_id == id_get)
                {
                  var textcontrol= ((document.getElementById("typequestion") as HTMLInputElement).value);
                  var marktype= ((document.getElementById("typemark") as HTMLInputElement).value);
                  var newoption1= ((document.getElementById("matchnewoption1") as HTMLInputElement).value);
                  var newoption2= ((document.getElementById("matchnewoption2") as HTMLInputElement).value);
                  var newoption3= ((document.getElementById("matchnewoption3") as HTMLInputElement).value);
                  var newoption4= ((document.getElementById("matchnewoption4") as HTMLInputElement).value);
                  var newoption5= ((document.getElementById("matchnewoption5") as HTMLInputElement).value);
                  var newoption6= ((document.getElementById("matchnewoption6") as HTMLInputElement).value);
                  var newoption7= ((document.getElementById("matchnewoption7") as HTMLInputElement).value);
                  var newoption8= ((document.getElementById("matchnewoption8") as HTMLInputElement).value);
                  

                  this.Question[i].question = textcontrol;
                  this.Question[i].mark = marktype;
                  this.Question[i].matchop1 = newoption1;
                  this.Question[i].matchop2 = newoption2;
                  this.Question[i].matchop3 = newoption3;
                  this.Question[i].matchop4 = newoption4;
                  this.Question[i].matchop5 = newoption5;
                  this.Question[i].matchop6 = newoption6;
                  this.Question[i].matchop7 = newoption7;
                  this.Question[i].matchop8 = newoption8;
                }
            }
        }
  }
// update question for all type End

// Adding Questions object Type 1 Start
  Create()
   {
      var textcontrol= ((document.getElementById("newquestion") as HTMLInputElement).value);
      var mark= ((document.getElementById("mark") as HTMLInputElement).value);
      var newQobj = {"question":textcontrol,"id":Math.floor( Math.random()*100 ),"qtype":1,"mark":mark};
      console.log(textcontrol);
      this.Question.push(newQobj);
  }
  // Adding Questions object Type 1 End

  // Adding Questions object Type 2 Start
  Createoption()
   {
      var textcontrol= ((document.getElementById("newquestion") as HTMLInputElement).value);
      var newoption1= ((document.getElementById("newoption1") as HTMLInputElement).value);
      var newoption2= ((document.getElementById("newoption2") as HTMLInputElement).value);
      var newoption3= ((document.getElementById("newoption3") as HTMLInputElement).value);
      var mark= ((document.getElementById("mark") as HTMLInputElement).value);
      var newQobj = {"question":textcontrol,"id":Math.floor( Math.random()*100 ),"qtype":2,"option1":newoption1,"option2":newoption2,"option3":newoption3,"mark":mark};
      console.log(textcontrol);
      this.Question.push(newQobj);
  }
  // Adding Questions object Type 2 End

  // Adding Questions object Type 3 Start
  CreateMatchQtype()
  {
      var textcontrol= ((document.getElementById("newquestion") as HTMLInputElement).value);
      var mark= ((document.getElementById("mark") as HTMLInputElement).value);
      var newQobj = {"question":textcontrol,"id":Math.floor( Math.random()*100),"qtype":3,"mark":mark,
                      "matchop1":this.smatchop1,"matchop2":this.smatchop2,"matchop3":this.smatchop3,"matchop4":this.smatchop4,
                      "matchop5":this.smatchop5,"matchop6":this.smatchop7,"matchop7":this.smatchop7,"matchop8":this.smatchop8};
      this.Question.push(newQobj);
      console.log(this.Question);
  }
  // Adding Questions object Type 3 End

  //Download Pdf from here Start
  public downloadPDF(): void 
  {  
    var newQobj = {"question":this.Question}; 
    
    const pdf = new jsPDF();
    
    // printing the headings
    pdf.setFontSize(28);
    pdf.setFont("arial","bold");
    pdf.text(this.SchoolName,60,10);
    pdf.setFontSize(20);
    pdf.text(this.Paper_H1,76,18);
    pdf.setFont("arial","normal");
    pdf.setFontSize(15);
    pdf.text("Date:" +this.date,65,25);
    pdf.setFontSize(15);
    pdf.text("Time:" +this.Max_time,10,32);
    pdf.text("Max Marks:" +this.TestMarks.toString(),170,32)
    var margins = {
      top: 25,
      bottom: 60,
      left: 20,
      width: 522
   };
   pdf.setFontSize(12);
  //  pdf.line(50, 50, 50, 20);    
  //  pdf.setLineWidth(1.5);
   // print the value of date and stuff
   var x = 45;
   
        for(var i=0; i<=this.Question.length-1;i++) // from here print of the question start for all type
        {
          var content1 = "Q)" + this.Question[i].question; //this will print the question 
          var Type = this.Question[i].qtype; //From here we will find the which type of question is. 
          var mark = "("+this.Question[i].mark.toString() + " " + "marks)" //this will print the question 
          if(Type == 1)
          {
            pdf.text(content1,10,x); //print the content according to the axis given in it .
            pdf.text(mark,180,x-5); //print the mark according to the axis given in it .
            x = x+20; // print the gap bwt content and mark acoording to the x axis.
          }
          //same will be follow for the type 2 and type 3 question 

        if(Type == 2)
            {
                var content2 ="a)"+ " " + this.Question[i].option1;
                var content3 ="b)"+ " " + this.Question[i].option2;
                var content4 ="c)"+ " " + this.Question[i].option3;
                var mark = "("+this.Question[i].mark.toString() + " " + "marks)";
                pdf.text(content1,15,x);
                pdf.text(mark,180,x-5);
                x = x+10;
                pdf.text(content2,18,x);
                x = x+10;
                pdf.text(content3,18,x);
                x = x+10;
                pdf.text(content4,18,x);
                x = x+20;
                
            }

            if(Type == 3)
              {
                
                  var mark = "("+this.Question[i].mark.toString() + " " + "marks)"
                  pdf.text(mark,180,x-5);
                  pdf.text(content1,10,x);
                  x = x+10;
                  pdf.text(this.Question[i].matchop1,25,x);
                  pdf.text(this.Question[i].matchop5,120,x);
                  x=x+10;
                  pdf.text(this.Question[i].matchop2,25,x);
                  pdf.text(this.Question[i].matchop6,120,x);
                  x=x+10
                  pdf.text(this.Question[i].matchop3,25,x);
                  pdf.text(this.Question[i].matchop7,120,x);
                  x=x+10;
                  pdf.text(this.Question[i].matchop4,25,x);
                  pdf.text(this.Question[i].matchop8,120,x);
                
                   x = x+20;
              }
        }
        // from here print of the question End for all type
    
    pdf.save("Question.pdf");
  }  

  //Download Pdf End Here

  open(content) 
  { 
      this.modalService.open(content); 
      this.showtext = false;
      this.showOption = false;
      this.TextareaShow = false;
      this.optiontype1 = false;
      this.optiontype2 = false;
      this.optiontype3 = false;
      this.markoptain = false;
  }
   
  QuestionTypeInput(input_type:number){
    //basic requirment for all fields
    this.TextareaShow = true;
    this.markoptain = true;
    // set all fields lower to false first
    //all option type hidden
    this.optiontype1 = false;
    this.optiontype2 = false;
    this.optiontype3 = false;
    // all match type options hidden
    this.matchop1 =  false; this.matchop5 = false;
    this.matchop2 = false; this.matchop6 = false;
    this.matchop3 = false; this.matchop7 = false;
    this.matchop4 =  false; this.matchop8 = false;
    //hide all save buttons
    this.saveMatchQtype = false;
    this.showtext = false;
    this.showOption = false;
    // for simple textual
    if(input_type==1){
      this.showtext=true;
    }
    // for option type
    if(input_type == 2){
      // show option type entery 
      this.optiontype1 = true;
      this.optiontype2 = true;
      this.optiontype3 = true;
      //show option type save button
      this.showOption = true;
    }
    // for match the following
    else if(input_type == 3){
      //match the following type input
      this.matchop1 = true; this.matchop5 = true;
      this.matchop2 = true; this.matchop6 = true;
      this.matchop3 = true; this.matchop7 = true;
      this.matchop4 = true; this.matchop8 = true;
      // show save type for match the following
      this.saveMatchQtype = true;
    }
  }

  //Doc file Start
  public downloadWord(): void 
  {  
    const document = new Document();

        document.addSection({  //this will print the content in doc
            children: [
                new Paragraph({
                    text: this.SchoolName,
                    heading: HeadingLevel.TITLE,
                    alignment: AlignmentType.CENTER,
                }),
                new Paragraph({
                  text: this.Paper_H1,
                  thematicBreak: true,
                  alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                text: this.date,
                alignment: AlignmentType.CENTER,
                
            }),
              new Paragraph({
                tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                    },
                ],
                children: [
                    new TextRun({
                        text: this.Max_time,
                        bold: true,
                    }),
                    
                    new TextRun({
                        text: `\t${"Max Marks:" + this.TestMarks.toString()}`,
                        bold: true,
                    }),
                ],
            }),
          ],
      });
        
        for(var i=0; i<=this.Question.length-1;i++)
        {
          var content1 = "Q)" + this.Question[i].question;
          var Type = this.Question[i].qtype;
          var mark = "("+this.Question[i].mark.toString() + " " + "marks)"
          if(Type == 1)
         {
          document.addSection({
            children: [
                new Paragraph({
                  tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                    },
                ],
                children: [
                    new TextRun({
                        text: content1,
                        bold: true,
                    }),
                    
                    new TextRun({
                        text: `\t${mark}`,
                        bold: true,
                    }),
                ],
              })
            ],
          });
        }
        if(Type == 2)
            {
            var content2 ="a)"+ " " + this.Question[i].option1;
            var content3 ="b)"+ " " + this.Question[i].option2;
            var content4 ="c)"+ " " + this.Question[i].option3;
            var mark = "("+this.Question[i].mark.toString() + " " + "marks)";
            document.addSection({
              children: [
                    new Paragraph({
                      tabStops: [
                        {
                            type: TabStopType.RIGHT,
                            position: TabStopPosition.MAX,
                        },
                    ],
                    children: [
                        new TextRun({
                            text: content1,
                            bold: true,
                        }),
                        
                        new TextRun({
                            text: `\t${mark}`,
                            bold: true,
                        }),
                    ],
                  }),
                  new Paragraph({
                    text: content2,
                    alignment: AlignmentType.LEFT,
                }),
                new Paragraph({
                    text: content3,
                    alignment: AlignmentType.LEFT,
                }),
                new Paragraph({
                  text: content4,
                  alignment: AlignmentType.LEFT,
              })
            ],
          });
            }
            if(Type == 3)
              {
                var mark = "("+this.Question[i].mark.toString() + " " + "marks)"
                document.addSection({
                  children: [
                      new Paragraph({
                        tabStops: [
                          {
                              type: TabStopType.RIGHT,
                              position: TabStopPosition.MAX,
                          },
                      ],
                      children: [
                          new TextRun({
                              text: content1,
                              bold: true,
                          }),
                          
                          new TextRun({
                              text: `\t${mark}`,
                              bold: true,
                          }),
                      ],
                    }),
                    new Paragraph({
                      tabStops: [
                        {
                            type: TabStopType.RIGHT,
                            position: TabStopPosition.MAX,
                        },
                    ],
                    children: [
                        new TextRun({
                            text: this.Question[i].matchop1,
                            bold: true,
                        }),
                        
                        new TextRun({
                            text: `\t${this.Question[i].matchop5}`,
                            bold: true,
                        }),
                    ],
                  }),
                  new Paragraph({
                    tabStops: [
                      {
                          type: TabStopType.RIGHT,
                          position: TabStopPosition.MAX,
                      },
                  ],
                  children: [
                      new TextRun({
                          text: this.Question[i].matchop2,
                          bold: true,
                      }),
                      
                      new TextRun({
                          text: `\t${this.Question[i].matchop6}`,
                          bold: true,
                      }),
                  ],
                }),
                new Paragraph({
                  tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                    },
                ],
                children: [
                    new TextRun({
                        text: this.Question[i].matchop3,
                        bold: true,
                    }),
                    
                    new TextRun({
                        text: `\t${this.Question[i].matchop7}`,
                        bold: true,
                    }),
                ],
              }),
              new Paragraph({
                tabStops: [
                  {
                      type: TabStopType.RIGHT,
                      position: TabStopPosition.MAX,
                  },
              ],
              children: [
                  new TextRun({
                      text: this.Question[i].matchop4,
                      bold: true,
                  }),
                  
                  new TextRun({
                      text: `\t${this.Question[i].matchop8}`,
                      bold: true,
                  }),
              ],
            })
          ],
        });
      }
    }
    Packer.toBlob(document).then(blob => {
      saveAs(blob, "Question.docx");
      console.log("Document created successfully");
    });
    
  }  

  //Doc file End

  //save a question paper Start
  Savepaper(paperchoose)
  {
    // console.log("id",this.Paperid);
    // // var id = Question_paper_id;
    if(this.Paperid != "")
    {
      this.modalService.open(paperchoose);
    }
    else if(this.Paperid == "")
    {
      let gradevalue = localStorage.getItem("GradeSource");
      var Question_paper_id = this.Teacher_id + "_" + this.today;
      if(this.date != "" && this.Max_time != "" && this.TestMarks != "" && this.Paper_H1 != "" && this.SchoolName != "")
      {
      // let post = {Date,Max_mark,Max_time,Question_paper,School_name,Subject,Teacher_id,Question_paper_id};
      var post = {"Date": this.date,"Max_mark":this.TestMarks,"Max_time":this.Max_time,"Question_paper": this.Question,
          "School_name":this.SchoolName,"Subject":this.Paper_H1,"Teacher_id": this.Teacher_id,"Question_paper_id":Question_paper_id,"Grade": gradevalue};
      console.log("question", post);
      // from here save of the question paper
      this.service.createQuestionBank(post).subscribe(() => {
        alert("Record has been added into your question paper.")
        });

        this.Questionpaper_score_update(); // updating the score of question paper in teacher score table
      }
      else
      {
        alert("Please fill all the details.");
      }
    }
  }
  //save a question paper End

  changePaper(Question_paper_id)
  {
    
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Question_paper_id', Question_paper_id);
    console.log("data format", params,typeof params);
    this.service.getQuestionBank(params).subscribe(response => {
    
      this.date = response["Item"]["Date"];
      this.Max_time = response["Item"]["Max_time"];
      this.TestMarks = response["Item"]["Max_mark"];
      this.Paper_H1 = response["Item"]["Subject"];
      this.SchoolName = response["Item"]["School_name"];
      this.Question=response["Item"]["Question_paper"];
      this.Paperid = response["Item"]["Question_paper_id"];
      console.log("type of question",this.Question);
      console.log("Question Id",response["Item"]["Question_PaperId"]);
      // alert("You can make changes for making the new question paper.");
    });
  }

  //Add a new question paper
  NewQuestionPaper()
  {
      this.date = "";
      this.Max_time = "";
      this.TestMarks = "";
      this.Paper_H1 = "";
      this.SchoolName = "";
      this.Question = [];
      this.Paperid = "";
  }

  SaveSamePaperData()
  {
    let gradevalue = localStorage.getItem("GradeSource");

    var post = {"Date": this.date,"Max_mark":this.TestMarks,"Max_time":this.Max_time,"Question_paper": this.Question,
        "School_name":this.SchoolName,"Subject":this.Paper_H1,"Teacher_id": this.Teacher_id,"Question_paper_id":this.Paperid,"Grade": gradevalue};
    console.log("question", post);
    // from here save of the question paper
    this.service.createQuestionBank(post).subscribe(() => {
      alert("Record has been added into your same question paper.")
      });
  }

  SaveNewPaperData()
  {
    var Question_paper_id = this.Teacher_id + "_" + this.today;
    let gradevalue = localStorage.getItem("GradeSource");
    if(this.date != "" && this.Max_time != "" && this.TestMarks != "" && this.Paper_H1 != "" && this.SchoolName != "")
      {
      var post = {"Date": this.date,"Max_mark":this.TestMarks,"Max_time":this.Max_time,"Question_paper": this.Question,
          "School_name":this.SchoolName,"Subject":this.Paper_H1,"Teacher_id": this.Teacher_id,"Question_paper_id":Question_paper_id,"Grade": gradevalue};
      console.log("question", post);
      // from here save of the question paper
      this.service.createQuestionBank(post).subscribe(() => {
        alert("Record has been added into your new question paper.")
        });

        this.Questionpaper_score_update(); // updating the score of question paper in teacher score table

      }
      else
      {
        alert("Please fill all the details.");
      }
  }

  Deletepaper(deletequestionpaper)
  {
    this.modalService.open(deletequestionpaper);
  }

  //deleting the Question Paper Start
  DeleteQuestionpaper()
  {
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Question_paper_id', this.Paperid);
    this.service.deleteQuestionBank(params).subscribe(() => {
      alert("Question-Paper has been Deleted.")
      this.date = "";
      this.Max_time = "";
      this.TestMarks = "";
      this.Paper_H1 = "";
      this.SchoolName = "";
      this.Question = [];
      this.Paperid = "";
      });
  }

  //deleting the Question Paper End

  //update the score in the teacher score table only for Questionpaper_score
  Questionpaper_score_update()
  {
    let Grade1 = localStorage.getItem("GradeSource");
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    let Grade = Grade1 +'_'+ storage_grade;
    console.log("Grade_Subject",Grade);

    var Teacher_score = {"School_id":this.SchoolName,"Teacher_id":this.Teacher_id,"working_on":"Question_paper_scores",
    "grade":Grade};
    console.log("data of params",Teacher_score);
    this.service.Teacher_LessonScore_Update(Teacher_score).subscribe(() => {
    });
  }

  //trail for changing the subject according to grade Start
  Change_Subject_Acc_Grade(alloted_grade:any)
  {
    this.Gradewise_Subject = [];  // null the value of subject when grade is change
    this.Allpaper=[]; // null the value of previous paper coming when grade is change
    this.Gradewise_Subject = Object.values(this.Subject_Allocated[alloted_grade]);
    console.log(this.Gradewise_Subject,"grade wise subject is coming");
    let grade = alloted_grade;
    this.Selected_Grade_Lable = alloted_grade ;
    this.LocalStorage_Subject = "";
    localStorage.setItem('GradeSource', grade);
    console.log("set the data in local", grade);
      this.date = "";
      this.Max_time = "";
      this.TestMarks = "";
      this.Paper_H1 = "";
      this.SchoolName = "";
      this.Question = [];
      this.Paperid = "";
      this.show_alloted_grade = !this.show_alloted_grade;
      this.show_gradewise_subject = true;
  }
  //trail for changing the subject according to grade End

  //Changing the Paper of previous in the dropdown Start
  SelectingSubject(Subject: any)
  {
    let subject = Subject;
    localStorage.setItem('SubjectSource', subject);
    let gradevalue = localStorage.getItem("GradeSource");
    this.LocalStorage_Subject = Subject ;
    console.log("set the data in local", subject ,gradevalue);
    if(gradevalue != null && subject != null )
    {
      // let gradevalue = localStorage.getItem("GradeSource");
      let subjectvalue = localStorage.getItem("SubjectSource");
      console.log("data of local", gradevalue,subjectvalue);
      let params = new HttpParams();
      params = params.append('Teacher_id', this.Teacher_id);
      params = params.append('Question_paper_id', null);
      params = params.append('Grade', gradevalue);
      params = params.append('Subject', subject);
      console.log("data format", params,typeof params);
      this.service.getQuestionBank(params).subscribe(response => { 
        console.log("checking the data",response);
        this.Allpaper = response["Items"];
        console.log(typeof response)
        console.log(typeof this.Allpaper,"paper getting", this.Allpaper);
      });
      this.date = "";
      this.Max_time = "";
      this.TestMarks = "";
      this.Paper_H1 = "";
      this.SchoolName = "";
      this.Question = [];
      this.Paperid = "";
    }
  }
  //Changing the Paper of previous in the dropdown End

  //Back to grade
  Backto_Grade_Allocated()
  {
    this.show_alloted_grade = true;
    this.show_gradewise_subject = false;
  }

  // it was used to closed the header Start
  CloseEditingHeader()
  {
    this.EditingHeader = false;
    this.Header = true;
    let storage_grade = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
    this.Paper_H1 = storage_grade;
  }
  // it was used to closed the header End

// For play the video of how to use Question paper Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Question paper End

}
