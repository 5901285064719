<div class="container-fluid" style="overflow: hidden;">
    <div class="row">
      <div class="col-md-6">
        <app-base></app-base>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8" style="margin-top: 23%;">
            <div class="text-center"><img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/nnlogo.png" height="45" width="auto"></div>
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
  
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" id="email" ngModel class="form-control" required>
          </div>
  
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" name="password" id="password" ngModel class="form-control" required>
          </div>
  
          <button type="submit" class="btn btn-primary">Login</button>
          
          <br>
          <div *ngIf="login_failed" style="color: red; padding-bottom: 15px;">Incorret Username or Password, please ensure you have clicked the verfication link recieved on email.</div>
    
          <div>Don't have an account? &nbsp;<a routerLink="/register">Register here</a></div>
        </form>
      </div>
      </div>
      </div>
    </div>
    <br />
  </div>

