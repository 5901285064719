<app-navbar></app-navbar>
<br>
<div class="container-fluid">
<div class="row">
  <div class="col-md-1"></div>
  <!-- <mat-card class="mat-container"> -->
    <mat-chip-list aria-label="Fish selection" class="chip-list">
      <!-- <label><B>Selected Grade & Subject :-</B></label>&nbsp;&nbsp; -->
    <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
    <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
  </mat-chip-list>
  <!-- </mat-card> -->
  <div class="col-md-5"></div>
  &nbsp;
<button  class="btn btn-lg btn-outline-primary button-size" 
(click)="open(content)"> 
Add Your Own Activity
</button> 
&nbsp;&nbsp;
<button class="btn btn-lg btn-outline-success button-size" 
(click)="open(gradecontent)"> 
Edit Grade And Subject
</button>&nbsp;
<!-- <div class="col-md-1"></div> -->
<!-- <mat-chip-list aria-label="Fish selection" class="chip-list">
  <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
  <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
</mat-chip-list> -->
</div>
<div class="content row">
  <div class="col-md-4"></div>
  <div class="col-md-5">
    <h1>Your Lesson Plan Table</h1>
  </div>
  <div class="col-md-2"></div>
  <!-- <div class="col-offset"></div> -->
<div class="col-md-12">
<!-- <mat-card class="content"> -->
  <disp-lessonplan></disp-lessonplan>
<!-- </mat-card> -->
</div>
</div>

<br>
<div class="row content-fixed">
  <div class="col-md-1"></div>
  <!-- <mat-card class="mat-container"> -->
    <mat-chip-list aria-label="Fish selection" class="chip-list">
      <!-- <label><B>Selected Grade & Subject :-</B></label>&nbsp;&nbsp; -->
    <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
    <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
  </mat-chip-list>
  <!-- </mat-card> -->
  <div class="col-md-5"></div>
  &nbsp;
<button  class="btn btn-lg btn-outline-primary button-size" 
(click)="open(content)"> 
Add Your Own Activity 
</button> 
&nbsp;&nbsp;
<button  class="btn btn-lg btn-outline-success button-size" 
(click)="open(gradecontent)"> 
Edit Grade And Subject
</button>&nbsp;
<!-- <div class="col-md-1"></div> -->
<!-- <mat-chip-list aria-label="Fish selection" class="chip-list">
  <mat-chip color="primary" selected>{{Selected_Grade_Lable}}</mat-chip>
  <mat-chip color="accent" selected>{{LocalStorage_Subject}}</mat-chip>
</mat-chip-list> -->
</div>
<hr style="margin-bottom: 0;">
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    NerdNerdy.com
  </div>
  <!-- Copyright -->

</footer>

<!-- For choosing the value from Grade Start -->
<ng-template #gradecontent let-modal class="modal">   
  <div class="modal-content">
    <div class="modal-header"> 
    <h1 class="modal-title w-100 text-center">Welcome back, {{Teacher_Name}}</h1>
      <button type="button" class="close"
      aria-label="Close" (click)= 
      "modal.dismiss('Cross click')" class="btn btn-info"> 
      Back
  </button> 
    </div>
    <!-- <h2 class="modal-sub-title text-center" style="color:black;">Select a grade and subject for creating respective QuestionBank , Lesson Plans, Courses and more !!</h2> -->
    <mat-card>
        <form> 
          <br>
        <div *ngIf="show_alloted_grade">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted grade/grades</h2>
          <mat-card>
            <div *ngFor="let alloted_grade of Grade_Allocated" class="text-center">
              <mat-card-actions>
                  <button (click)="Change_Subject_Acc_Grade(alloted_grade)" class="btn btn-warning"><b>{{alloted_grade}}</b></button>
              </mat-card-actions>
            </div>
          </mat-card>
        </div>
        <div *ngIf="show_gradewise_subject">
          <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted subject/subjects</h2>
          <mat-card>
            <mat-card-actions>
              <!-- <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button> -->
              <label><B>Allotted Grade :-</B></label>&nbsp;&nbsp;
              <button class="btn btn-warning" disabled style="align-content: right;">
                {{Selected_Grade_Lable}}</button>
            </mat-card-actions>
            
            <div *ngFor="let gradewise_subject of Gradewise_Subject">
              <mat-card-actions>
                <label><B>Allotted Subject :-</B></label>&nbsp;&nbsp;
                  <button (click)="SelectingSubject(gradewise_subject);modal.close('Subject click')" class="btn btn-success"><b>{{gradewise_subject}}</b></button>
                  <br>
                  <h4>Click above<img src="assets/arrow.png" height="45" width="auto"> to enter the lesson plan.</h4>
                </mat-card-actions>
            </div>
          </mat-card>
          <br>
          <button class="btn btn-primary" (click)="Backto_Grade_Allocated()" style="align-content: left;margin-right: 80px;">Back To Grade</button>
        </div>
          <br>
        </form> 
    </mat-card>
    </div> 
  </ng-template>
<!-- For choosing the value from grade Start -->

</div>
<!-- Modal for entry -->
<ng-template #content let-modal> 
  <div class="modal-content">
  <div class="modal-header"> 
      <button type="button" class="close"
              aria-label="Close" (click)= 
              "modal.dismiss('Cross click')" class="btn btn-danger"> 
              Close
      </button> 
  </div> 
  <h1 style="text-align: center;">Lesson Planning</h1>
  <mat-card style="height: 80%; width: 100%;">
    <mat-label>Enter Teacher Code</mat-label>
         <input class="form-control" [(ngModel)]="teachercode" type="text"/>  
  <mat-label> Topic </mat-label>
  <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="selectedcat" placeholder="Topic of your lesson Plan"/>
  <mat-label>Sub-Topic</mat-label>
  <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="selectedsubcat" placeholder="Add Sub-Topic for the lesson plan"/>
  <mat-label>Teaching Aid</mat-label>
  <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="selectededu" placeholder="describe the learning for the child"/>
  <mat-label>Topic based Classroom Activity</mat-label>
  <textarea id="typeahead-format" type="text" class="form-control" [(ngModel)]="selectedact" placeholder="describe the activities that will take place in the learning process"></textarea>
  <!-- <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="selectedact" placeholder="describe the activities that will take place in the learning process" /> -->
  <mat-form-field>
    <mat-label>Fill The date</mat-label>
    <mat-input-container >
      <input [(ngModel)]="selecteddate" matInput [matDatepicker]="picker" placeholder="MM/dd/yyyy"><br/><br/>
      <!-- <mat-datepicker-toggle mdSuffix [for]="picker"></mat-datepicker-toggle> -->
    </mat-input-container>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-card-actions>
    <button type="button" (click)="Save();modal.close('Save click')" class="btn btn-success">Save</button>
  </mat-card-actions>
  </mat-card>
</div>
</ng-template> 

