import { Component, OnInit ,ViewChild,Directive, Input, ElementRef ,TemplateRef,ChangeDetectionStrategy } from '@angular/core';
import { LpDataexService } from 'src/app/lp-dataex.service';
import { Columns, Config, DefaultConfig ,API, APIDefinition } from 'ngx-easy-table';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {NgbModal, ModalDismissReasons}  from '@ng-bootstrap/ng-bootstrap'; 
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';

@Component({
  selector: 'disp-lessonplan',
  templateUrl: './disp-lessonplan.component.html',
  styleUrls: ['./disp-lessonplan.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispLessonplanComponent implements OnInit {
  

  @ViewChild('idTpl', { static: true }) idTpl: TemplateRef<any>;
  @ViewChild('teacherTpl', { static: true }) teacherTpl: TemplateRef<any>;
  @ViewChild('categoryTpl', { static: true }) categoryTpl: TemplateRef<any>;
  @ViewChild('subcatTpl', { static: true }) subcatTpl: TemplateRef<any>;
  @ViewChild('educationTpl', { static: true }) educationTpl: TemplateRef<any>;
  @ViewChild('activityTpl', { static: true }) activityTpl: TemplateRef<any>;
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>;
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;
  @ViewChild('deleteTpl', { static: true }) deleteTpl: TemplateRef<any>;

  @ViewChild('id') id: ElementRef<any>;
  @ViewChild('teacher') teacher: ElementRef<any>;
  @ViewChild('category') category: ElementRef<any>;
  @ViewChild('subcat') subcat: ElementRef<any>;
  @ViewChild('education') education: ElementRef<any>;
  @ViewChild('activity') activity: ElementRef<any>;
  @ViewChild('date') date: ElementRef<any>;

  @ViewChild('table', { static: true }) table: APIDefinition;

  public Planlesson = [];
  Lessonplan = [];
  public columns: Columns[];
  public configuration: Config;
  editRow: number;
  editable:boolean = false;
  showtext:boolean = false;
  Lessonplan_id = "";
  date1 = "";
  Teacher_id = "";
  
  constructor(private dataService:LpDataexService ,private modalService: NgbModal,private service:QpDataService, private auth:AuthorizationService) { }

  ngOnInit(): void {
    //table defination
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    // this.configuration.resizeColumn = true;
    // this.configuration.fixedColumnWidth = false;
    // ... etc.
    this.columns = [
      { key: 'category', title: 'Topic' , cellTemplate: this.categoryTpl, width: '7%'  },
      { key: 'subcat', title: 'Resource' , cellTemplate: this.subcatTpl, width: '7%' },
      // { key: 'education', title: 'Education' , cellTemplate: this.educationTpl},
      { key: 'activity', title: 'Activity' , cellTemplate: this.activityTpl, width: '20%'  },
      { key: 'date', title: 'Date' , cellTemplate: this.dateTpl, width: '7%' },
      { key: 'action', title: 'Actions' , cellTemplate: this.actionTpl, width: '5%' },
      { key: 'delete', title: 'Delete' , cellTemplate: this.deleteTpl, width: '5%' },
    ];

    //for Pushing the Single Array.
    this.dataService.$sharedValue.subscribe(result =>{
      console.log("service working", result);
      if(result != "abc")
        {
        var LPobj = JSON.parse(result);
        console.log(LPobj); 
        this.Planlesson.push(LPobj);//pushing the single array in planlesson lesson 
        this.Planlesson = [...this.Planlesson];// For showing the data into ngx table 
        console.log("Value set",this.Planlesson,LPobj);
       }
      
    });

    //for get the Array of object in this.
    this.dataService.$sharedArrayValue.subscribe(result =>{
      console.log("service working", result);
      if(result != "abc")
        {
        var LPobj = JSON.parse(result);
        console.log(LPobj);
        this.Planlesson = LPobj; //Array of an object should be equal to planlesson lesson 
        this.Planlesson = [...this.Planlesson];// For showing the data into ngx table 
        console.log("Value set",this.Planlesson,LPobj);
       }
      
    });
    this.auth.getUserAttributes().subscribe(result => {        
    console.log("Success",result);
    if(result != "")
    {
      this.Teacher_id = result["User"];
    }
  });
  }

  deleter(deletelessonplan)
  {
    this.modalService.open(deletelessonplan); //show modal for delete the lesson plan.
  }
  
  DeleteLessonPlan(row)
  {
      const index = this.Planlesson.indexOf(row);
      console.log("Row index value is coming", index);
      this.Planlesson.splice(index, 1);
      this.Planlesson = [...this.Planlesson];
      this.Updaterow(); 
  }

//trail for Ngx edit button Start
editrow(rowIndex: number): void {
  this.editRow = rowIndex;
}

//trail for Ngx edit button End

//trail for Ngx Updatengx button Start
updatengx(): void 
{
  this.Planlesson = [
    ...this.Planlesson.map((obj, index) => 
    {
      if (index === this.editRow) 
      {
        return {
          category: this.category.nativeElement.value,
          subcat: this.subcat.nativeElement.value,
          // education: this.education.nativeElement.value,
          activity: this.activity.nativeElement.value,
          date: this.date.nativeElement.value,
          // id: this.id.nativeElement.value,
          // teacher: this.teacher.nativeElement.value,
        };
      }
      return obj;
    }),
  ];
  this.editRow = -1;
  this.Updaterow();
}
//trail for Ngx Updatengx button End

//update the data in dynamodb table used in save and delete button 
Updaterow()
{
  
  let Grade = localStorage.getItem("GradeSource");
  let Subject = localStorage.getItem("SubjectSource"); // Storing the subject value from local storage.
  console.log("disp-lessonplan grade and subject",Grade,Subject);
  let params = new HttpParams();
  params = params.append('Teacher_id', this.Teacher_id);
  params = params.append('Grade', Grade);
  params = params.append('Subject', Subject);
  console.log("data format", params,typeof params);
  this.service.Lessonplanget(params).subscribe(response => {
  this.Lessonplan_id = response["Items"][0]["Lesson_plan_id"];
  console.log("getting the id of:-", this.Lessonplan_id , this.Teacher_id);
    
  });
  var Lessonplan = this.Planlesson;
  var Lesson_plan_id = this.Lessonplan_id;
  var Teacher_id = this.Teacher_id;
  let post = {Teacher_id,Lesson_plan_id,Lessonplan,Grade,Subject};
  console.log("post of lesson plan",post);
  this.service.Lessonplancreate(post).subscribe(() => {
    alert("Activity has been Updated.")
    });
    // this.Planlesson = [...this.Planlesson];
}

//global search for ngx start
onChange(name: string): void {
  this.table.apiEvent({
    type: API.onGlobalSearch,
    value: name,
  });
}
//global search for ngx end

}
