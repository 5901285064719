import { Component, OnInit ,ViewChild, ElementRef ,TemplateRef,ChangeDetectionStrategy,NgModule, } from '@angular/core';
import { Columns, Config, DefaultConfig ,API, APIDefinition } from 'ngx-easy-table';
import {NgbModal, ModalDismissReasons ,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { Gradewise } from '../Supervisiordashboard';
import { GRADE } from '../Supervisiordashboard';
import { Subjectwise } from '../Supervisiordashboard';
import { SUBJECT } from '../Supervisiordashboard';
import { Supervisiordata } from '../Supervisiordashboard';
import { SUPERVISIOR } from '../Supervisiordashboard';
import { QpDataService } from '../qp-data.service';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import {AuthorizationService} from '../authorization.service';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { AnyAaaaRecord } from 'dns';
import {Router} from '@angular/router'; 

@Component({
  selector: 'app-supervisiordashboard',
  templateUrl: './supervisiordashboard.component.html',
  styleUrls: ['./supervisiordashboard.component.css']
})
export class SupervisiordashboardComponent implements OnInit {

  Sgrade = GRADE; 
  selectedgrade: Gradewise;

  Ssubject = SUBJECT; 
  selectedsubject: Subjectwise;

  Ssuper = SUPERVISIOR; 
  selectedsuper: Supervisiordata;

  //used for the ngx table
  @ViewChild('teacheridTpl', { static: true }) teacheridTpl: TemplateRef<any>;
  @ViewChild('teachernameTpl', { static: true }) teachernameTpl: TemplateRef<any>;
  @ViewChild('classperformanceTpl', { static: true }) classperformanceTpl: TemplateRef<any>;
  @ViewChild('trainingTpl', { static: true }) trainingTpl: TemplateRef<any>;
  @ViewChild('questionTpl', { static: true }) questionTpl: TemplateRef<any>;
  @ViewChild('matricsTpl', { static: true }) matricsTpl: TemplateRef<any>;
  @ViewChild('SuperinputTpl', { static: true }) SuperinputTpl: TemplateRef<any>;

  @ViewChild('teacherid') teacherid: ElementRef<any>;
  @ViewChild('teachername') teachername: ElementRef<any>;
  @ViewChild('classperformance') classperformance: ElementRef<any>;
  @ViewChild('training') training: ElementRef<any>;
  @ViewChild('question') question: ElementRef<any>;
  @ViewChild('matrics') matrics: ElementRef<any>;

  @ViewChild('table', { static: true }) table: APIDefinition;

  // public Supervisior = []; // for data
  public columns: Columns[];
  public configuration: Config;

  //Used in the Class Performance modal popup start
  showModal : boolean;
  calculationBelowAverage: number = 0;
  calculationAboveAverage: number = 0;
  calculationVerySuprior: number = 0;
  calculationSuprior: number = 0;
  calculationAverage: number = 0;
  calculationSlow: number = 0;
  Reportcard = [];
  //Used in the Class Performance modal popup End
  
  //Used in the Teacher Peer Contribution modal popup start
  PQuestion: number = 0;
  CQuestion: number = 0;
  TDevelopment: number = 0;
  Math: any = Math;
  showModal1 : boolean;
  //Used in the Teacher Peer Contribution modal popup End

  Supervisior = [];// 
  Subjecttopic:Subjectwise[];
  Superdata:Supervisiordata[]= [];

  AllGrade_Available = ""; // for getting the grade value in Dropdown
  Teacher_id = "";
  TeacherName = "";
  Gradechoose = "";
  subject_id = "";

  data_row:any;
  sys_gen_score:any =0;

  StudentName = [];
  StudentNameSuperior = [];
  StudentNameBA = [];
  StudentSlName = [];
  MarkObtained = [];

  Reportcard_Name= [];

  AllSubject_Available = [];
  Subject_Select:any;
  School_id="";

  type = 'bar';
  data = {
  // labels: ["VerySuperior", "Superior", "AboveAverage", "BelowAverage", "Average", "Slow"],
  labels: [" ","Very Superior","Above Average", "Below Average", "Slow Learner"],
    // labels: [" ","Superior","VerySuperior","Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          // label: "Student Based Class Performance Analysis",
          label: "Student",
          // data: [0, 0, 0, 0],
          // data: [" ",this.calculationVerySuprior,this.calculationBelowAverage, this.calculationSlow],
          data: [" ",this.calculationVerySuprior,this.calculationAverage,this.calculationBelowAverage, this.calculationSlow],
        backgroundColor: 'rgb(10, 14, 214)',
        borderColor: 'rgb(10, 14, 214)',
      }
    ],  
  };

  options = {
  responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero:true,
              // yLabel: 'Population (millions)',
              max:30,
              stepSize: 3,
          },
          scaleLabel: {
            display: true,
            labelString: 'No. Of Students'
          }
      }],
    }
  };

  constructor(private modalService: NgbModal,private service:QpDataService, private auth:AuthorizationService,private router: Router) { }

  ngOnInit(): void 
  {
    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.columns = [
      // { key: 'teacherid', title: 'Teacher-Id' , cellTemplate: this.teacheridTpl},
      { key: 'teachername', title: 'Teacher Name' , cellTemplate: this.teachernameTpl},
      { key: 'classperformance', title: 'Class Performance' , cellTemplate: this.classperformanceTpl},
      { key: 'training', title: 'Teacher Course Completion' , cellTemplate: this.trainingTpl},
      { key: 'question', title: "Teacher's as knowledge partner" , cellTemplate: this.questionTpl},
      { key: 'matrics', title: 'System Generated Teacher Performance Score' , cellTemplate: this.matricsTpl},
      { key: 'Superinput', title: "Supervisor Score Input On Teacher's Performance" , cellTemplate: this.SuperinputTpl},
    ];
    this.auth.getUserAttributes().subscribe(result => {        
      console.log("Success",result);
      if(result != "")
      {
        // this.Teacher_id = result ;
        this.Teacher_id = result["User"];
        this.School_id = result["school"];
        console.log("Success id",this.Teacher_id,this.School_id);
            let params = new HttpParams();
            params = params.append('School_id', this.School_id);
            this.service.Teacher_Adminget(params).subscribe(response => {
            this.AllGrade_Available = response["Items"]["0"]["Grade_Available"];
            this.AllSubject_Available = response["Items"]["0"]["Subject_Availble"];
            console.log("all grade is coming for superdashboard",this.AllGrade_Available);
            });  
      }
    });
  }

  // Choosing the grade from dropdown Start
  GradeValue(gradeAvailable)
  {
      this.Gradechoose = gradeAvailable; //finding the grade in this
  }
  // Choosing the grade from dropdown End

  
  //ALl subject are available in the dropdown Start
  SelectingSubject(subjectAvailable)
  {
    this.Subject_Select = subjectAvailable; //finding the subject in this
  }
  //ALl subject are available in the dropdown End

  // the search is used to push the data into Supervisior and show the data into table
  Search()
  {
       let params1 = new HttpParams();
        params1 = params1.append('School_id', this.School_id);
        params1 = params1.append('Grade', this.Gradechoose);//{{row.grade-4,row.selected_grade}}
        params1 = params1.append('Teacher_id', null);
        params1 = params1.append('Subject', this.Subject_Select);
        console.log("data format", this.Teacher_id,typeof params1);
        this.service.getteacherdata(params1).subscribe(response => { 
          console.log("checking the data",response);
          this.Supervisior = response["Items"]; // {grade-4 : 35} -> {selected_grade : 35} // {{row.LessonPlan.selcted_grade + row.Wustion_Bank.selected grade}}
          //   this.Supervisior = [...this.Supervisior];// For showing the data into ngx table 
           console.log("getting data of grade", this.Supervisior);
           this.reset_GradeObj_Keys();
        });
        // reset to selected grade variable
        
  }  

  //used data for the Class Performance modal popup Start
        elements: any = [
          {Percentage: '95+ Above', Name: 'Very Superior'},
          {Percentage: '85 - 95', Name: 'Superior'},
          {Percentage: '70 - 85', Name: 'Above Average'},
          {Percentage: '50 - 70', Name: 'Average'},
          {Percentage: '30 - 50', Name: 'Below Average'},
          {Percentage: '30+ Below', Name: 'Slow'},
        ];

        headElements = ['Classification Score in %', ' '];

  // this onclick event is used in th "Check Performance" for checking the class performance modal popup
onClick(event:any , row)
  {
    var tid =row.Teacher_id;
    console.log("Teacher id is coming",tid);
    this.showModal = true; // Show-Hide Modal Check
    console.log("grade is coming", this.Gradechoose);
    // getting the Student Performance Analysis data Start
    let Student_Per_Analysis = new HttpParams();
    Student_Per_Analysis = Student_Per_Analysis.append('Teacher_id', row.Teacher_id);
    Student_Per_Analysis = Student_Per_Analysis.append('Subject_id', null);
    Student_Per_Analysis = Student_Per_Analysis.append('Grade', this.Gradechoose);
    Student_Per_Analysis = Student_Per_Analysis.append('Subject', this.Subject_Select);
    console.log("data format", Student_Per_Analysis,typeof Student_Per_Analysis);
    this.service.StudentPerformanceget(Student_Per_Analysis).subscribe(response => { 
      console.log("checking the data",response);
      this.Reportcard = response["Items"];
      console.log(typeof this.Reportcard,"paper getting", this.Reportcard);
    });
      this.calculationAboveAverage = 0;
      this.calculationBelowAverage = 0;
      this.calculationVerySuprior = 0;
      this.calculationSuprior = 0;
      this.calculationAverage = 0;
      this.calculationSlow = 0;
      this.data = {
        // labels: ["VerySuperior", "Superior", "AboveAverage", "BelowAverage", "Average", "Slow Learner"],
        labels: [" ","Very Superior","Above Average", "Below Average", "Slow Learner"],
    // labels: [" ","Superior","VerySuperior","Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          // label: "Student Based Class Performance Analysis",
          label: "Student",
          // data: [0, 0, 0, 0],
          // data: [" ",this.calculationVerySuprior,this.calculationBelowAverage, this.calculationSlow],
          data: [" ",this.calculationVerySuprior,this.calculationAverage,this.calculationBelowAverage, this.calculationSlow],
          // data: [0,0,0],
          backgroundColor: 'rgb(10, 14, 214)',
          borderColor: 'rgb(10, 14, 214)',
        }
      ],  
    };
      this.options;
  }
  //Bootstrap Modal Close event
hide()
  {
    this.showModal = false;
  }
  //used data for the Class Performance modal popup End

  // Choosing the Result from dropdown of "Student Report Card" Start
  changeReportcard(event: any)
  {
    this.subject_id = event.target.value;
    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
          this.calculationAboveAverage= response["Item"]["Student_Result"][1]["AboveAverage"];
          this.calculationBelowAverage= response["Item"]["Student_Result"][1]["BelowAverage"];
          this.calculationVerySuprior= response["Item"]["Student_Result"][1]["VerySuprior"];
          this.calculationSuprior= response["Item"]["Student_Result"][1]["Suprior"];
          this.calculationAverage= response["Item"]["Student_Result"][1]["Average"];
          this.calculationSlow= response["Item"]["Student_Result"][1]["Slow"];

          this.data = {
            // labels: ["VerySuperior", "Superior", "AboveAverage", "BelowAverage", "Average", "Slow Learner"],
            labels: [" ","Very Superior","Above Average", "Below Average", "Slow Learner"],
    // labels: [" ","Superior","VerySuperior","Average", "BelowAverage", "Slow Learner"],
      datasets: [
        {
          // label: "Student Based Class Performance Analysis",
          label: "Student",
          // data: [0, 0, 0, 0],
          // data: [" ",this.calculationVerySuprior,this.calculationBelowAverage, this.calculationSlow],
          data: [" ",this.calculationVerySuprior,this.calculationAverage,this.calculationBelowAverage, this.calculationSlow],
              backgroundColor: 'rgb(10, 14, 214)',
              borderColor: 'rgb(10, 14, 214)',
            }
          ],  
        };
          this.options;
          
    });
  }
  // Choosing the Result from dropdown of "Student Report Card" End


  //used for the Teacher Peer Contribution modal popup Start

      // headTitle = ['Self Created Training Module', 'Teaching Aid Development','Shared Question Paper And Worksheet'];
      headTitle = ['Lesson Plan Contribution', 'Interaction Contribution','Question Paper Contribution'];

      //Hide Modal Check for the Teacher Peer Contribution start
      Questionhide()
      {
        this.showModal1 = false; 
      }
      //Hide Modal Check for the Teacher Peer Contribution End

      //Click Event for the Teacher Peer Contribution modal popup
      onClickQuestion(event , row)
      {
        this.showModal1 = true; // Show-Hide Modal Check
        console.log(row.LessonPlan_scores['selected_grade']);
        let Choose_grade_subject = this.Gradechoose + '_' + this.Subject_Select;
        console.log("grade and subject",Choose_grade_subject);
        this.PQuestion = 0;
        this.CQuestion = 0;
        this.TDevelopment = 0;
        // this.data_row = row.LessonPlan_scores['selcted_grade'];
        // this.PQuestion = row.LessonPlan_scores['selected_grade'];
        // if(Choose_grade_subject != undefined)
        // {
        for(var i=0;i <= row.LessonPlan_scores.length -1 ;i++){
          if(Object.keys(row.LessonPlan_scores[i]).values().next().value == Choose_grade_subject){
            this.PQuestion = row.LessonPlan_scores[i][Choose_grade_subject];
            console.log(row.LessonPlan_scores[i][Choose_grade_subject],this.PQuestion);
          }
        } 
        for(var i=0;i <= row.Question_paper_scores.length -1 ;i++){
          if(Object.keys(row.Question_paper_scores[i]).values().next().value == Choose_grade_subject){
            this.CQuestion = row.Question_paper_scores[i][Choose_grade_subject];
            console.log(row.Question_paper_scores[i][Choose_grade_subject],this.CQuestion);
          }
        }
         for(var i=0;i <= row.Student_performance_scores.length -1 ;i++){
          if(Object.keys(row.Student_performance_scores[i]).values().next().value == Choose_grade_subject){
            this.TDevelopment = row.Student_performance_scores[i][Choose_grade_subject];
            console.log(row.Student_performance_scores[i][Choose_grade_subject],this.TDevelopment);
          }
        }
      // }
        // this.CQuestion = row.LessonPlan_scores[this.Gradechoose];

      }
  //used for the Teacher Peer Contribution modal popup End
   
   reset_GradeObj_Keys(){
    //  this.Supervisior
    this.sys_gen_score =0;
    let Choose_grade_subject = this.Gradechoose + '_' + this.Subject_Select;
        console.log("grade and subject",Choose_grade_subject);
    for(var i= 0;i<=this.Supervisior.length -1; i++){ // [0:{tName,LessonPlan: [0:{grade-4:45},1:{grade-5:}]},1:{tName,LessonPlan: [0:{},1:{}]}]
      for(var j = 0; j<= this.Supervisior[i].LessonPlan_scores.length -1 ; j++){
        if(Object.keys(this.Supervisior[i].LessonPlan_scores[j]).values().next().value == Choose_grade_subject){
          console.log("Found the grade", Object.keys(this.Supervisior[i].LessonPlan_scores[j]));
          var grade_found =Object.keys(this.Supervisior[i].LessonPlan_scores[j]); // [0:"grade-4",1:"grade-5"] String Set
          let grd = grade_found.values().next().value;
          // replace Object Key wth string grade_selected
         console.log(this.Supervisior[i].LessonPlan_scores[j][grd],grd);

        // this.Supervisior[i].LessonPlan_scores['selected_grade']= this.Supervisior[i].LessonPlan_scores[j][grd];
        this.sys_gen_score = this.sys_gen_score + this.Supervisior[i].LessonPlan_scores[j][grd];
        }
        if(Object.keys(this.Supervisior[i].Question_paper_scores[j]).values().next().value == Choose_grade_subject){
          console.log("Found the grade", Object.keys(this.Supervisior[i].LessonPlan_scores[j]));
          var grade_found =Object.keys(this.Supervisior[i].Question_paper_scores[j]); // [0:"grade-4",1:"grade-5"] String Set
          let grd = grade_found.values().next().value;
          // replace Object Key wth string grade_selected
         console.log(this.Supervisior[i].LessonPlan_scores[j][grd],grd);

        // this.Supervisior[i].LessonPlan_scores['selected_grade']= this.Supervisior[i].LessonPlan_scores[j][grd];
        this.sys_gen_score = this.sys_gen_score + this.Supervisior[i].Question_paper_scores[j][grd];
          
        }
        if(Object.keys(this.Supervisior[i].Student_performance_scores[j]).values().next().value == Choose_grade_subject){
          console.log("Found the grade", Object.keys(this.Supervisior[i].LessonPlan_scores[j]));
          var grade_found =Object.keys(this.Supervisior[i].Student_performance_scores[j]); // [0:"grade-4",1:"grade-5"] String Set
          let grd = grade_found.values().next().value;
          // replace Object Key wth string grade_selected
         console.log(this.Supervisior[i].Student_performance_scores[j][grd],grd);

        // this.Supervisior[i].LessonPlan_scores['selected_grade']= this.Supervisior[i].LessonPlan_scores[j][grd];
        this.sys_gen_score = this.sys_gen_score + this.Supervisior[i].Student_performance_scores[j][grd];
          
        }
        
      }
      this.Supervisior[i]['matrics'] = this.sys_gen_score;
      console.log(this.Supervisior[i]);
      this.sys_gen_score=0;
    }
    
   }

   // Very Superior Student Name Modal Start
  StudentRName(StudentVS)
  {
    this.modalService.open(StudentVS);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
        this.StudentName = response["Item"]["Very_Superior"];
        console.log("Very Superior Student Name", this.StudentName);
      });
  }
  // Very Superior Student Name Modal End

// Superior Student Name Modal Start
  StudentSName(StudentS)
  {
    this.modalService.open(StudentS);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
        this.StudentNameSuperior = response["Item"]["Superior"];
        console.log("Superior Student Name", this.StudentNameSuperior);
              
      });
  }
  // Superior Student Name Modal End

  // Below Average Student Name Modal Start
  StudentBAName(StudentBA)
  {
    this.modalService.open(StudentBA);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
        this.StudentNameBA = response["Item"]["Below_Average"];
        console.log("Below Average Student Name", this.StudentNameBA);
      });
  }
  // Below Average Student Name Modal End

  // Slow Student Name Modal Start
  StudentSlowName(StudentSlow)
  {
    this.modalService.open(StudentSlow);

    let params = new HttpParams();
    params = params.append('Teacher_id', this.Teacher_id);
    params = params.append('Subject_id', this.subject_id)
    console.log("data format", params,typeof params);
    this.service.StudentPerformanceget(params).subscribe(response => 
      {
        this.StudentSlName = response["Item"]["Slow"];
        console.log("Slow Student Name", this.StudentSlName);
              
      });
  }
// Slow Student Name Modal End

GradeAllotment()
{
  this.router.navigate(['/gradeallotment']);
}

// Reference table modal Start
ReferenceTable(Referencetable)
{
  this.modalService.open(Referencetable);
}
// Reference table modal End

// For play the video of how to use Supervisiour dashboard Start

View_Video(Viewvideo)
{
  this.modalService.open(Viewvideo); 
}

// For play the video of how to use Supervisiour dashboard End


}


