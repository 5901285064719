<app-navbar></app-navbar>

<div class="container-fluid h-100" style="background-color: #fafafa;">
  <br>
  <br>
  <div class="row">
      <div class="col-md-1"></div>
      <!-- Question Bank Dashboard -->
      <div class="col-md-6">
          <mat-card>
              <b>Question Bank</b>
              <div class="row">
                  <!-- <div class="col-md-1"></div> -->
                  <div class="col-md-4 text-center" *ngFor="let paper of Allpaper">
                      <a routerLink="/question-paper" (click)="ClickImage($event,paper.Question_paper_id)">
                          &nbsp;<img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/folder_open-24px.svg" width="80px" height="auto">
                          <br>
                          <div>Name: {{paper.Subject}}</div> 
                          <div>Created_on: {{paper.Date}}</div>
                      </a>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                      <button routerLink="/question-paper" class="btn btn-primary">QuestionBank</button>
                  </div>
              </div>
          </mat-card>
      </div>
      <!-- end of question bank -->
      <!-- <div class="col-md -1"></div> -->
      <!-- Student performance overview -->
      <div class="col-md-4">
          <mat-card>
            <b>Student-Based Class Performance</b>
              <mat-form-field class="col-md-6">
                  <mat-label class="col-md-6">Student Exam Results</mat-label>
                  <mat-select>
                  <mat-option *ngFor="let card of Reportcard" style="align-content: center;" [value]="card.Subject_id" (click)="changeReportcard(card.Subject_id)">RP-: {{card.Date}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <br>
              <br>
              <chart [type]="type" [data]="data" [options]="options" class="barchart"></chart>
              <br>
          </mat-card>
      </div>
      <!-- End of student performancce overview -->
      <div class="col-md-1"></div>
  </div>
  <br>
  <!-- Adding next items -->
  <div class="row">
      <div class="col-md-1"></div>
      <!-- Leson plan overview -->
      <div class="col-md-6">
      
          <mat-card>
              <b>Lesson Plan</b>
              <!-- Create your Lesson Plan in browser save edit and export them , get researched backed advise for activities and rescources -->
              <br>
              <br>
              <div class="col-md-6 text-center" *ngFor="let lesson of LessonPlan">
                  <a routerLink="/lessonplanning" (click)="ClickLesson($event,lesson.Lesson_plan_id)">
                      &nbsp;<img src="https://jump-hats-test.s3.ap-south-1.amazonaws.com/teacherskilldev/assets/lesson-plans.png" width="112px" height="auto">
                      <div>Lesson Plan</div> 
                      <br>
                  </a>
              </div>
                <br>
                <button routerLink="/lessonplanning" class="btn btn-primary">Edit Lesson Plan Now</button>
          </mat-card>
      </div>
      <!-- End of lesson plan overview -->
      <!-- <div class="col-md-1"></div> -->
      <!-- adding teacher training overview -->
      <div class="col-md-4">
          <mat-card>
              <b>Teacher Training Module</b>
              <!-- Learn more from our teacher training modules -->
              <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-7">
                      <circle-progress
                      [percent]="course_completion"
                      [radius]="45"
                      [outerStrokeWidth]="7"
                      [innerStrokeWidth]="6"
                      [outerStrokeColor]="'#78C000'"
                      [innerStrokeColor]="'#C7E596'"
                      [animation]="true"
                      [animationDuration]="500"
                      ></circle-progress>
                  </div>
                  <div class="col-md-2"></div>
              </div>
              <div class="row">
                  <!-- <div class="col-md-6"></div> -->
                  <div class="offset-7">
                      <button class="btn btn-primary" routerLink="/courses">Teacher Training</button>
                      <!-- <button mat-raised-button color="primary">Teacher Training</button> -->
                  </div>
              </div>
  
          </mat-card>
          <br>
          <mat-card>
            <b>Curriculum Plan with Activities</b>
              <!-- Get Lesson based circulum plan with activities and video rescources teachers -->
              <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-7">
                      <circle-progress
                      [percent]="33"
                      [radius]="45"
                      [outerStrokeWidth]="7"
                      [innerStrokeWidth]="6"
                      [outerStrokeColor]="'#78C000'"
                      [innerStrokeColor]="'#C7E596'"
                      [animation]="true"
                      [animationDuration]="500"
                      ></circle-progress>
                  </div>
                  <div class="col-md-2"></div>
      </div>
      <div class="row">
          <!-- <div class="col-md-6"></div> -->
          <div class="offset-7">
              <button routerLink="/chapterwise" class="btn btn-primary">View Rescources</button>
              <!-- <button mat-raised-button color="primary">Teacher Training</button> -->
          </div>
      </div>
          </mat-card>
      </div>
      <!-- end of teacher training overview-->
      <div class="col-md-1"></div>
  </div>
  <footer class="page-footer font-small bg-dark-gray">

      <!-- Copyright -->
      <div class="footer-copyright text-center py-3">© 2020 Copyright:
        NerdNerdy.com
      </div>
      <!-- Copyright -->
    
    </footer>

      <!-- For choosing the value from Grade Start -->
<ng-template #gradecontent let-modal class="modal">   
    <div class="modal-content">
      <div class="modal-header"> 
      <h1 class="modal-title w-100 text-center">Welcome back, {{Teacher_Name}}</h1>
        <!-- <button type="button" class="close"
        aria-label="Close" (click)= 
        "modal.dismiss('Cross click')" class="btn btn-danger"> 
        Close
    </button>  -->
      </div>
      <!-- <h2 class="modal-sub-title text-center" style="color:black;">Select a grade and subject for creating respective QuestionBank , Lesson Plans, Courses and more !!</h2> -->
      <mat-card>
          <form> 
            <br>
          <div *ngIf="show_alloted_grade">
            <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted grade/grades</h2>
            <mat-card>
              <div *ngFor="let alloted_grade of Grade_Allocated" class="text-center">
                <mat-card-actions>
                    <button (click)="Change_Subject_Acc_Grade(alloted_grade)" class="btn btn-warning"><b>{{alloted_grade}}</b></button>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
          <div *ngIf="show_gradewise_subject" class="container">
            <h2 class="modal-sub-title text-center" style="color:black;">Your Allotted subject/subjects</h2>
            <mat-card>
              <mat-card-actions>
                <!-- <button class="btn btn-primary" (click)="Backto_Grade_Allocated()">Back To Grade</button>&nbsp;&nbsp; -->
                <label><B>Allotted Grade :-</B></label>&nbsp;&nbsp;
                <button class="btn btn-warning" disabled style="align-content: right;">
                  {{Selected_Grade_Lable}}</button>
              </mat-card-actions>
              
              <div *ngFor="let gradewise_subject of Gradewise_Subject">
                <mat-card-actions>
                    <label><B>Allotted Subject :-</B></label>&nbsp;&nbsp;
                    <button (click)="SelectingSubject(gradewise_subject);modal.close('Subject click')" class="btn btn-success"><b>{{gradewise_subject}}</b></button>
                    <br>
                    <h4>Click above<img src="assets/arrow.png" height="45" width="auto"> to enter the dashboard.</h4>
                </mat-card-actions>
              </div>
              
            </mat-card>
            <br>
            <button class="btn btn-primary" (click)="Backto_Grade_Allocated()">Back To Grade</button>&nbsp;&nbsp;
          </div>
          
            <br>
          </form> 
      </mat-card>
      </div> 
    </ng-template>
<!-- For choosing the value from grade Start -->

</div>


